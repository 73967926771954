import React, { useEffect, useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SliderSettings from "./SliderSettings";
import { flatList } from "../../utils";
import { Breadcrumbs, CircularProgress, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  discardChanges,
  getCustomSettings,
  publishSettings,
  setNodeId,
  setOpenPreview,
  setOpenSidebar,
} from "../../store/pageBuilder/actions";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { slideVariants } from "../../constants/constants";
import MenusManagement from "./MenusManagement";
import HomeScreen from "./HomeScreen";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 380,
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
  },
  title: {
    flexGrow: 1,
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontSize: 28,
    fontWeight: "bold",
  },
}));

const sectionList = [
  {
    id: 0,
    name: "Settings",
    parentId: null,
    list: [
      {
        id: "menus",
        name: "Menus",
        parentId: 0,
        component: MenusManagement,
        list: [],
      },
      {
        id: "slider-settings",
        parentId: 0,
        name: "Slider Settings",
        component: SliderSettings,
        list: [],
      },
      {
        id: "homeScreen-components",
        parentId: 0,
        name: "Home Screen",
        component: HomeScreen,
        list: [],
      },
    ],
  },
];

function ListDetails(props) {
  const classes = useStyles();
  const {
    context,
    publishSettings,
    isPublishing,
    dirty,
    discardChanges,
    setOpenPreview,
    preview,
    nodeId,
    setNodeId,
  } = props;

  const { name, parent, Component, list } = useMemo(() => {
    const flaten = flatList(sectionList);
    const active = flaten.find((x) => x.id === nodeId);
    const { name, component: Component, parentId, list } = active;
    const parent = flaten.find((x) => x.id === parentId);
    return {
      name,
      parent,
      Component,
      list,
    };
  }, [nodeId]);

  return (
    <div
      className={clsx(classes.list)}
      role="presentation"
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
    >
      <Box
        px={2}
        py={1}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          variant="h6"
          className={classes.title}
          component={NavLink}
          onClick={() => setNodeId(0)}
          to={"/"}
        >
          FREDDIE
        </Typography>
        <Button
          color={"primary"}
          variant={"contained"}
          disabled={isPublishing || !dirty}
          startIcon={
            isPublishing && <CircularProgress size={20} color={"#fff"} />
          }
          onClick={() => publishSettings({ context: context.PageBuilder })}
        >
          {dirty ? "Publish" : "Published"}
        </Button>
      </Box>
      <Divider />
      <Box px={2} py={1}>
        <Breadcrumbs aria-label="breadcrumb">
          {parent && (
            <Typography
              component={Link}
              style={{ cursor: "pointer" }}
              onClick={() => setNodeId(parent.id)}
              color="textPrimary"
            >
              {parent.name}
            </Typography>
          )}
          <Typography color="textPrimary">{name}</Typography>
        </Breadcrumbs>
      </Box>
      <div style={{ flex: 1, overflow: "auto" }}>
        {list[0] && (
          <motion.div
            style={{ flex: 1 }}
            className="wrapper"
            variants={slideVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <List>
              {list.map(({ name, id, list, component }, index) => (
                <ListItem
                  button
                  key={id}
                  onClick={() => setNodeId(id)}
                  divider
                  disabled={!list[0] && !component}
                >
                  <ListItemText primary={name} />
                </ListItem>
              ))}
            </List>
          </motion.div>
        )}
        <Box py={1}>
          {Component && (
            <motion.div
              style={{ flex: 1 }}
              className="wrapper"
              variants={slideVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <Component />
            </motion.div>
          )}
        </Box>
      </div>
      <Divider />
      <Box p={2} display={"flex"}>
        <Button
          color={"primary"}
          variant={"outlined"}
          style={{ flex: 1 }}
          disabled={!dirty}
          onClick={discardChanges}
        >
          Discard
        </Button>
        <Box ml={1} />
        <Button
          color={"secondary"}
          variant={preview ? "contained" : "outlined"}
          startIcon={!preview ? <VisibilityOff /> : <Visibility />}
          onClick={() => setOpenPreview(!preview)}
        >
          {preview ? "Live Preview ON" : "Live Preview Off"}
        </Button>
      </Box>
    </div>
  );
}

function PageCustomizer(props) {
  const { getCustomSettings } = props;
  const {
    context,
    publishSettings,
    setOpenSidebar,
    setNodeId,
    discardChanges,
    setOpenPreview,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const { publish, dirty, openSidebar, nodeId, preview } = context.PageBuilder;
  const { isLoading, error } = publish;

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: "error" });
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    getCustomSettings();
  }, [getCustomSettings]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenSidebar(open);
  };

  return (
    <div>
      <Drawer anchor={"left"} open={openSidebar} onClose={toggleDrawer(false)}>
        <ListDetails
          publishSettings={publishSettings}
          context={context}
          isPublishing={isLoading}
          nodeId={nodeId}
          setNodeId={setNodeId}
          dirty={dirty}
          discardChanges={discardChanges}
          setOpenPreview={setOpenPreview}
          preview={preview}
        />
      </Drawer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      PageBuilder: state.PageBuilder,
    },
  };
};
export default connect(mapStateToProps, {
  getCustomSettings,
  publishSettings,
  setOpenSidebar,
  setNodeId,
  discardChanges,
  setOpenPreview,
})(PageCustomizer);

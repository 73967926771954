import {entityType} from "./module";
import {createDeleteMethod, createGetAllMethod, createPostMethod, createPutMethod} from "reactcoregk/store/actions";
import {getDefaultHeaders} from "../../utils";
import ApiHandler from "reactcoregk/models/apiHandler";

const getDefaultApiHandler = () =>
    new ApiHandler(true, false, getDefaultHeaders());

export const getAllMenus = () => createGetAllMethod(entityType, "", getDefaultApiHandler())
export const createMenu = (payload) => createPostMethod(entityType, payload, getDefaultApiHandler())
export const updateMenu = (payload) => createPutMethod(entityType, payload, getDefaultApiHandler())
export const deleteMenu = (payload) => createDeleteMethod(entityType, payload, getDefaultApiHandler())


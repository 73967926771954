import { useContext, useEffect } from "react";
import BaseContent from "../components/Core/BaseContent";
import { getDefaultHeaders } from "../utils";
import { useFetch } from "reactcoregk/hooks";
import { Container } from "@material-ui/core";
import { ExtraTitle } from "../components/Layouts/DefaultLayout";
import { API_URL } from "../config";

const articleSchema = {};
export default function Page(props) {
  const slug = props.match.params.slug;

  const { setExtraTitle } = useContext(ExtraTitle);

  useEffect(() => {
    setExtraTitle(slug);
  }, [slug, setExtraTitle]);

  const [, page] = useFetch(
    `${API_URL}/pages/${slug}`,
    getDefaultHeaders(),
    articleSchema
  );

  return (
    <BaseContent title={slug || page?.title} subtitle={""}>
      <Container>
        <div dangerouslySetInnerHTML={getBody(page)} />
      </Container>
    </BaseContent>
  );
}
function getBody(story) {
  return { __html: story?.body };
}

import BaseContent from "../../components/Core/BaseContent";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

export default function Terms() {
  return (
    <BaseContent title={"TERMS AND CONDITIONS"}>
      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
        LEGAL NOTICE
      </Typography>
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Your use of the website, JoinFreddie.com (the “Site”)and the content
        contained herein is subject to all of the terms and conditions stated in
        these Terms of Use (this “Agreement”). By accessing thisSite and/or
        using such materials, you agree to be bound by each provision of this
        Agreement. No other agreement relating to this Site will be effective
        and any attempt by you to create such an agreement between us shall be
        invalid. This Agreement may be changed by us at any time without notice
        to you and, therefore, it is your responsibility to abide by this
        Agreement each time you access this Site.
      </Typography>
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        You understand and agree that by joining the Site by registering,
        accessing or using our services (described below),you are agreeing to
        enter into this Agreement with Freddie (even if you are using our
        Services on behalf of a company). If you do not agree to this Agreement
        do not join the Site and do not access or otherwise use any of our
        Services. If you wish to terminate this Agreement, at any time you can
        do so by closing your account and no longer accessing or using our
        Services.You further agree to be bound to our Community Guidelines and
        Privacy Policy.
      </Typography>
      <Box mt={4} />
      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
        SERVICES AND LIMITATIONS:
      </Typography>
      <ol>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            This Agreement applies to JoinFreddie.com, JoinFreddie apps(if any),
            and other JoinFreddie-related sites, including our predecessors,
            parents, affiliates and partners (“Freddie” “we” or “us”), and all
            communications, networks, and other services that are offered in
            connection therewith, whether explicitly stated in this Agreement or
            otherwise (collectively, the “Services”), including the offsite
            collection of data for those Services.
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            We reserve the right to limit your use of the Services, the right to
            restrict, suspend, or terminate your account if you breach this
            Agreement or the law or are misusing the Services (e.g., violating
            any of our community guidelines.)
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            THIRD PARTY SITES: This site may contain links to sites owned or
            operated by parties other than Freddie. Such links are provided for
            your reference only. Freddie does not control outside sites and is
            not responsible for their content or operation. The inclusion of
            links to an outside site does not imply any endorsement of the
            material on that site (unless expressly disclosed otherwise), any
            sponsorship, affiliation or association with its owner, operator or
            sponsor, nor does the inclusion of such links imply that Freddie is
            authorized to use any trade name, trademark, logo, legal or official
            seal or copyrighted symbol that may be reflected in the linked site.
            You are prohibited from linking any other web site or other
            electronic medium to this site or any other site owned or operated
            by Freddie without our express written permission.
          </Typography>
        </li>
      </ol>
      <Box mt={4} />
      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
        INTELLECTUAL PROPERTY RIGHTS:
      </Typography>
      <ol>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            The content of the Site, including all text, photographs and
            graphics, and its compilation are the property of Freddie, its
            affiliates, parents, subsidiaries, or associates (collectively
            “Freddie”) or its content suppliers, are protected by U.S.and
            international copyright laws and may not be used except as set forth
            herein. Any designs featured on any products are the sole and
            exclusive property of their respective copyright holders and/or are
            subject to copyright protection. You do not have the right, and
            agree not, to copy, reproduce, modify, adapt or otherwise use any
            designs in any manner, except asa purchaser and user of their
            respective products and then only in accordance with applicable law.
            All software used on this site is the property of Freddie or its
            software providers.
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            OINFREDDIE ™ are trademarks of Freddie and may not be used in any
            way without our prior written permission. We vigorously enforce our
            rights in our trademarks and other intellectual property to the
            fullest extent permitted by law. All other trademarks not owned by
            Freddie which appear on this site are the property of their
            respective owners.
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            Nothing in this Agreement or your use of the Site or the Services
            shall be deemed a license, right to use, or other consent for your
            use of any property of Freddie, its partners, affiliates, heirs or
            assigns.
          </Typography>
        </li>
      </ol>
      <Box mt={4} />
      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
        PRIVACY:
      </Typography>
      <Typography style={{ marginBottom: 8 }} variant={"body1"} >
        Your use of this web site is also subject to our Privacy Policy which is
        set forth on the Site, and which is incorporated herein by reference.
        Please review the Privacy Policy thoroughly, as it describes the use of
        your Personal Information, communications, and other data. Your use of
        the Services and acceptance of this Agreement is express consent to the
        Privacy Policy.
      </Typography>
      <Box mt={4} />
      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
        LICENSE OF PERSONAL INFORMATION:
      </Typography>
      <Typography style={{ marginBottom: 8 }} variant={"body1"} >
        You own the content and information that you submit or post to the Site
        and used with the Services, however, you agree to grant to Freddie and
        our affiliates and partners, a perpetual, non-exclusive, royalty-free,
        worldwide, transferable and sublicensable license to use, copy, modify,
        distribute, publish and process, information and content that you
        provide through our Services and the services of others, without any
        further consent, notice and/or compensation to you or others, including
        the sale or sharing of your data, information, pictures or otherwise.
        These rights are limited in the following ways:
      </Typography>
      <ol>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            You can end this license for specific content by deleting such
            content from the Services, or generally by closing your account,
            except (a) to the extent you shared it with others as part of the
            Services and they copied, re-shared it or stored it and (b) for the
            reasonable time it takes to remove from backup and other systems.
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            We can include your content in advertisements for the products and
            services of third parties to others without your separate consent
            (including sponsored content). However, we have the right, without
            payment to you or others, to serve ads near your content and
            information, and your Personal Information may be visible and
            included with ads, as noted in the Privacy Policy.
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            We will get your consent if we want to give others the right to
            publish your content beyond the Services.
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            Because you own your content and information and we only have
            non-exclusive rights to it, you may choose to make it available to
            others.
          </Typography>
        </li>
      </ol>
      <Typography style={{ marginBottom: 8 }} variant={"body1"} >
        You and Freddie agree that if content you provide includes Personal
        Information , it is subject to our Privacy Policy.You agree that we may
        access, store, process and use any information and personal data that
        you provide in accordance with, the terms of the Privacy Policy and your
        choices (including settings).
      </Typography>
      <Box mt={4} />
      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
        TERMINATION:
      </Typography>
      <ol>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            Both you and Freddie may terminate this Contract at any time with
            notice to the other. On termination, you lose the right to access or
            use the Services.
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            The following shall survive termination:
          </Typography>
          <ol>
            <li>
              <Typography style={{ marginBottom: 8 }} variant={"body1"} >
                Our rights to use and disclose your feedback;
              </Typography>
            </li>
            <li>
              <Typography style={{ marginBottom: 8 }} variant={"body1"} >
                Members and/or Visitors’ rights to further re-share content and
                information you shared through the Services;
              </Typography>
            </li>
            <li>
              <Typography style={{ marginBottom: 8 }} variant={"body1"} >
                The terms of paragraphs 3, 6, 7, 8, and 9;
              </Typography>
            </li>
            <li>
              <Typography style={{ marginBottom: 8 }} variant={"body1"} >
                Any amounts owed by either party prior to termination remain
                owed after termination.
              </Typography>
            </li>
          </ol>
        </li>
      </ol>

      <Box mt={4} />
      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
        DISCLAIMER AND LIMITATION OF LIABILITY:
      </Typography>
      <ol>
        <li>
          <Typography style={{ marginBottom: 8 }} variant={"body1"} >
            No Warranty: ALL MATERIAL, INCLUDING THE TEXT, PICTURES AND STORE
            REFERENCES, ON THIS SITE IS PROVIDED “AS IS.” TO THE FULLEST EXTENT
            PERMITTED BY LAW, WE DISCLAIM ALL REPRESENTATIONS AND WARRANTIES,
            EXPRESS OR IMPLIED, WITH RESPECT TO SUCH MATERIAL, INCLUDING BUT NOT
            LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT AND FREEDOM FROM
            COMPUTER VIRUS, AND ALL WARRANTIES WHICH MAY BE IMPLIED FROM COURSE
            OF CONDUCT OR COURSE OF DEALING. WE DO NOT REPRESENT THAT THE
            INFORMATION CONTAINED ON THIS SITE IS ACCURATE, COMPLETE OR CURRENT.
            ALL PRICES AND PRODUCTS ARE SUBJECT TO CHANGE. BECAUSE SOME
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, THE
            ABOVE LIMITATIONS MAY NOT APPLY TO YOU.b.Limitation of LiabilityWE
            SHALL HAVE NO LIABILITY FOR ANY DAMAGES DUE TO ERRORS OR OMISSIONS
            IN THE CONTENT OF THIS SITE OR ANY DELAY OR INABILITY TO USE THIS
            SITE EXPERIENCED BY YOU, OR DAMAGE TO YOUR COMPUTER OR DATA OR ANY
            OTHER DAMAGES YOU MAY INCUR IN CONNECTION WITH YOUR ACCESS TO AND/OR
            USE OF THIS SITE. IN NO EVENT SHALL WE BE LIABLE FOR ANY INDIRECT,
            PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING IN
            ANY WAY OUT OF YOUR USE OF THIS SITE OR THE MATERIALS CONTAINED
            HEREIN, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO
            EVENT SHALL WE BE LIABLE FOR DAMAGES DUE TO CIRCUMSTANCES BEYOND OUR
            REASONABLE CONTROL.USE OF THE SITE SHALL CONSTITUTE YOUR EXPRESS
            CONSENT TO THIS LIMITATION OF LIABILITY.SOME STATES DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
            THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
          </Typography>
        </li>
      </ol>

      <Box mt={4} />
      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
        INDEMNIFICATION:
      </Typography>
      <Typography style={{ marginBottom: 8 }} variant={"body1"} >
        You agree to hold harmless and indemnify Freddie and its representatives
        and affiliates, at your own expense, from any and all claims and actions
        brought against us or any third-party, and anything libelous or
        defamatory, or that otherwise causes damage toFreddie or a third-party,
        whether it be sole or in concert with others, which results from your
        use of the Site, the Services, or any violation of any of the terms of
        this Agreement, including without limitation, any violations of any
        intellectual property rights of others. We reserve the right to handle
        our own defense if we see fit to do so, including the selection of our
        own counsel, with our attorneys fees being paid by you.
      </Typography>
      <Box mt={4} />
      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
        MISCELLANEOUS:
      </Typography>
      <Typography style={{ marginBottom: 8 }} variant={"body1"} >
        The Agreement between us as set forth in this Agreement shall be
        governed by and construed in accordance with the laws of the State of
        New York without regard to conflicts of law principles thereof. Any
        dispute between us arising out of or relating to your use of the
        Services, including your purchase of any of our products, shall be
        exclusively subject to the jurisdiction of the New York State and
        Federal courts sitting in New York County, New York, and by using this
        Site you hereby consent to such jurisdiction. Severability. The
        determination that any provision of this Agreement is invalid or
        unenforceable shall not invalidate this Agreement, all of said
        provisions being inserted conditionally on their being considered
        legally valid and this Agreement shall be construed and performed in all
        respects as if any invalid or unenforceable provisions are omitted
        provided the primary purpose of this Agreement is not thereby
        impeded.Non-waiver. Failure or neglect of either Party hereto in any
        instance to exercise any right, power or privilege hereunder or under
        law shall not constitute a waiver of any other right, power or privilege
        or of the same right, power or privilege in any other instance.All
        waivers by either Party hereto must be contained in a written instrument
        signed by the Party to be charged.
      </Typography>
    </BaseContent>
  );
}

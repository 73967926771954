import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import { KeyboardArrowLeft } from "@material-ui/icons";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    color: "#3A5273",
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 0,
    backgroundColor: "#FF2E2E",
    color: "white",
    padding: "0 4px",
  },
}))(Badge);

const PopoverTItle = withStyles(styles)((props) => {
  const {
    children,
    classes,
    handleClose,
    badge,
    renderView,
    onBack,
    ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {onBack && (
          <IconButton
            onClick={onBack}
            style={{ width: 24, height: 24, marginRight: 8 }}
          >
            {" "}
            <KeyboardArrowLeft color={"primary"} fontSize={"small"} />
          </IconButton>
        )}
        {children}
        <StyledBadge badgeContent={badge} />
      </Typography>
      <div>{renderView && renderView()}</div>
    </MuiDialogTitle>
  );
});

export default function PopoverContainerBase(props) {
  const {
    title,
    autoDismiss,
    handleClose,
    badge,
    onBack,
  } = props;
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Paper
        style={{
          minWidth: 300,
          // backgroundColor: "#252425",
          borderRadius: 6,
          marginTop: 8,
          paddingBottom: 8,
        }}
      >
        <PopoverTItle handleClose={handleClose} badge={badge} onBack={onBack}>
          {title}
        </PopoverTItle>
        <Divider />
        <div
          style={{
            maxHeight: "calc(100vh - 60px)",
            overflow: "auto",
            position: "relative",
          }}
          onClick={() => {
            if (autoDismiss) {
              handleClose();
            }
          }}
        >
          {props.children}
        </div>
      </Paper>
    </ClickAwayListener>
  );
}

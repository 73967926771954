import Card from "@material-ui/core/Card";
import { CardActionArea } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import {useImage} from "../../hooks";

const useStyles = makeStyles({
  media: {
    height: 220,
    paddingTop: "56.25%", // 16:9
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    marginTop: -50,
    width: 100,
    height: 100,
  },
});

export default function CategoryCard({ busy, style, handleClick }) {
  const classes = useStyles();
  const [file, isLoading] = useImage(style?.imageId, null)

  return (
    <Card variant={"outlined"}>
      <CardActionArea onClick={() => handleClick(style.id)}>
        {isLoading || !file? (
          <Skeleton variant="rect" className={classes.media} />
        ) : (
          <CardMedia
            className={classes.media}
            image={file}
            title={style?.name}
          />
        )}
        <CardContent className={classes.container}>
          {busy ? (
            <Skeleton variant="text" width={200} height={30} />
          ) : (
            <Typography variant="h6" component="h4">
              {style.name}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

import BaseContent from "../../../components/Core/BaseContent";
import { Box, Grid } from "@material-ui/core";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ExtraTitle } from "../../../components/Layouts/DefaultLayout";
import { getUserId } from "../../../utils";
import DropDialog from "../../../components/DropDialog";
import ContactDesignerDialog from "../ContactDesignerDialog";
import { useDesigner } from "./hooks";
import Portfolio from "./Portfolio";
import FormViewWrapper from "./FormViewWrapper";
import Header from "./Header";
import { makeStyles } from "@material-ui/styles";
import Footer from "../../../components/Footer";
import {Prompt} from "react-router";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: "100%"
  },
  stickyItem: {
    position: "sticky",
    top: 60,
  },
});

function DesignerDetails(props) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [openFeatureCrop, setOpenFeatureCrop] = useState(false);
  const [openProfileCrop, setOpenProfileCrop] = useState(false);
  const [openPortfolioCrop, setOpenPortfolioCrop] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  const { setExtraTitle } = useContext(ExtraTitle);

  const designerIdOrSlug = props.match.params.idOrSlug;

  const {
    designerEdit,
    designerView,
    isLoadingEdit,
    isLoading,
    errorMessage,
    handleRollBack,
    handleSubmit,
    successMessage,
    saving,
    acceptProfileImage,
    newProfileImage,
    removeImage,
    removeAddedImage,
    loadingFeatureImage,
    featureImage,
    loadingProfile,
    acceptFeatureImage,
    profileImage,
    newFeatureImage,
    savingPublic,
    isPublic,
    toggleProfile,
    portfolioImages,
    acceptPortfolioImage,
  } = useDesigner(designerIdOrSlug, setIsEditing);

  const hasEditPermission = designerView.id === getUserId();

  useEffect(() => {
    setExtraTitle(designerIdOrSlug);
  }, [designerIdOrSlug, setExtraTitle]);

  const handleEditToggle = useCallback(() => {
    if (isEditing) {
      handleRollBack();
    }
    setIsEditing(!isEditing);
  }, [handleRollBack, isEditing]);

  const portfolioImageIds = isEditing
    ? designerEdit.portfolioImageIds
    : designerView.portfolioImageIds;

  const shouldCollapsePortfolio = useMemo(() => {
    return !isLoading && portfolioImageIds.length === 0 && !isEditing;
  }, [isLoading, portfolioImageIds.length, isEditing]);

  return (
    <div className={classes.root}>
      <Prompt
          when={isEditing}
          message="You have unsaved changes, are you sure you want to leave?"
      />
      <BaseContent errorMessage={errorMessage} successMessage={successMessage}>
        <Header
          isLoading={isLoading}
          hasEditPermission={hasEditPermission}
          handleEditToggle={handleEditToggle}
          savingPublic={savingPublic}
          toggleProfile={toggleProfile}
          isPublic={isPublic}
          loadingFeatureImage={loadingFeatureImage}
          isEditing={isEditing}
          setOpenFeatureCrop={setOpenFeatureCrop}
          featureImage={featureImage}
          newFeatureImage={newFeatureImage}
          loadingProfile={loadingProfile}
          newProfileImage={newProfileImage}
          profileImage={profileImage}
          setOpenProfileCrop={setOpenProfileCrop}
        />
        <Box mt={4} />
        <Grid container spacing={4} alignItems={"flex-start"}>
          <Grid item md={6} sm={12} xs={12}>
            <Portfolio
              profileImage={profileImage}
              shouldCollapsePortfolio={shouldCollapsePortfolio}
              isEditing={isEditing}
              isLoading={isLoading}
              designerView={designerView}
              portfolioImageIds={portfolioImageIds}
              portfolioImages={portfolioImages}
              removeImage={removeImage}
              removeAddedImage={removeAddedImage}
              setOpenPortfolioCrop={setOpenPortfolioCrop}
              hasEditPermission={hasEditPermission}
            />
          </Grid>
          <Grid
            item
            md={shouldCollapsePortfolio ? 12 : 6}
            sm={12}
            className={classes.stickyItem}
          >
            <FormViewWrapper
              isEditing={isEditing}
              isLoading={isLoading}
              isLoadingEdit={isLoadingEdit}
              saving={saving}
              shouldCollapsePortfolio={shouldCollapsePortfolio}
              designerIdOrSlug={designerIdOrSlug}
              designerView={designerView}
              designerEdit={designerEdit}
              setOpenContact={setOpenContact}
              handleSubmit={handleSubmit}
            />
          </Grid>
        </Grid>
        <DropDialog
          open={openFeatureCrop}
          handleClose={() => setOpenFeatureCrop(false)}
          setImage={acceptFeatureImage}
          aspect={16 / 9}
          resolution={[1280, 720]}
          title={"Crop Feature Image"}
        />
        <DropDialog
          open={openProfileCrop}
          handleClose={() => setOpenProfileCrop(false)}
          setImage={acceptProfileImage}
          aspect={1}
          cropShape={"round"}
          resolution={[480, 480]}
          title={"Crop Profile Picture"}
        />
        <DropDialog
          open={openPortfolioCrop}
          handleClose={() => setOpenPortfolioCrop(false)}
          setImage={acceptPortfolioImage}
          autoAspect
          title={"Crop Portfolio Image"}
        />
        <ContactDesignerDialog
          open={openContact}
          designer={designerView}
          handleClose={() => setOpenContact(false)}
        />
      </BaseContent>
      <Footer/>
    </div>
  );
}

export default DesignerDetails;

import {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {
  deleteData,
  fetchData,
  getCredentials,
  getFileUrl,
  getParameterByName, isAdmin,
  updateData,
  updateURLParameter,
} from "./utils";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import {compareProp, postData} from "reactcoregk/utils";
import { ApiEndpoint } from "./store/@core/endpoint";
import { EntityType } from "./store/@core/entityType";
import { Favorite, FavoriteBorderOutlined } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { isUserAuthenticated } from "./store/auth/login/api";
import { useSnackbar } from "notistack";
import {API_URL} from "./config";
import {publicRoutes} from "./routes";

const useDefaultTable = (array, sortHandler, identifier = "id") => {
  const [selected, setSelected] = useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = array.map((n) => n[identifier]);
      setSelected(newSelectedIds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const numSelected = selected.length;
  const rowCount = array.length;

  const renderHeadCell = (headCell) => {
    return (
      <TableCell
        sortDirection={"asc"}
        key={headCell.id}
        align={headCell.numeric ? "right" : "left"}
        padding={headCell.disablePadding ? "none" : "default"}
      >
        {headCell.isSortable && sortHandler ? (
          <TableSortLabel
            active={sortHandler.sort.by === headCell.id}
            direction={sortHandler.sort.direction}
            onClick={() => sortHandler.handleSort(headCell.id)}
          >
            {headCell.label}
          </TableSortLabel>
        ) : (
          headCell.label
        )}
      </TableCell>
    );
  };

  const renderBodyCell = (label, stylesOverride = {}) => {
    return (
      <TableCell align="left">
        <Typography
          style={{ fontSize: 12, ...stylesOverride }}
          color={"textSecondary"}
        >
          {label}
        </Typography>
      </TableCell>
    );
  };

  const renderCheckAllCell = () => {
    return (
      <TableCell padding="checkbox">
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={handleSelectAllClick}
          inputProps={{ "aria-label": "select all desserts" }}
        />
      </TableCell>
    );
  };

  const renderCheckRowCell = (isItemSelected, labelId, onClick) => {
    return (
      <TableCell padding="checkbox" onClick={onClick}>
        <Checkbox
          checked={isItemSelected}
          inputProps={{ "aria-labelledby": labelId }}
        />
      </TableCell>
    );
  };

  const renderDefaultBody = (
    array,
    headerColumns,
    selectableRows,
    onClick,
    styleCell
  ) => {
    return array.map((row, index) => {
      const isItemSelected = isSelected(row.id);
      const labelId = `enhanced-table-checkbox-${index}`;
      return (
        <TableRow
          hover
          onClick={(event) => {
            if (onClick) {
              onClick(row);
            } else {
              if (selectableRows) {
                handleClick(event, row.id);
              }
            }
          }}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
        >
          {selectableRows && renderCheckRowCell(isItemSelected, labelId)}
          {headerColumns.map((column) => {
            const styles = styleCell && styleCell(column.id, row[column.id]);
            return renderBodyCell(row[column.id], styles);
          })}
        </TableRow>
      );
    });
  };

  return {
    handleClick,
    handleSelectAllClick,
    isSelected,
    setSelected,
    renderHeadCell,
    renderBodyCell,
    renderCheckAllCell,
    renderCheckRowCell,
    renderDefaultBody,
    numSelected,
    rowCount,
    selected,
  };
};

const useInstagram = ({size}) => {
  const fields = "media_url,media_type,permalink";
  const url = `${API_URL}/instagram?fields=${fields}&limit=${size}`;
  const [instaPosts, setInstaPosts] = useState([]);

  useEffect(() => {
    fetchData(url)
      .then((data) => {
        setInstaPosts(data.data);
      })
      .catch((ex) => console.log(ex));
  }, [url]);

  return instaPosts;
};

const useAuthValidation = (loginSuccess, logoutUser) => {
  const [initializing, setInitizalizing] = useState(true);

  const validateUser = useCallback(async () => {
    const authUser = getCredentials();
    if (authUser) {
      try {
        const profile = await fetchData(ApiEndpoint[EntityType.Account]);
        loginSuccess(profile);
        setInitizalizing(false);
      } catch (e) {
        logoutUser();
        setInitizalizing(false);
      }
    } else {
      setInitizalizing(false);
    }
  }, [loginSuccess, logoutUser]);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return initializing;
};

const useSubmit = (url, success) => {
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (values, shouldUpdate, callback) => {
    setBusy(true);
    setErrorMessage(null);
    const save = shouldUpdate ? updateData : postData;
    save(url, values)
      .then(() => {
        setBusy(false);
        setSuccessMessage(success);
        callback && callback(values);
      })
      .catch((ex) => {
        setBusy(false);
        setErrorMessage(ex.message);
      });
  };

  return { busy, errorMessage, successMessage, handleSubmit };
};

const useAlert = (message, variant) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, { variant });
    }
  }, [enqueueSnackbar, message, variant]);
};

const useImage = (id, defaultImage, type) => {
  const [file, setFile] = useState(defaultImage);
  const [busy] = useState(false);

  useEffect(() => {
    if (id) {
      setFile(getFileUrl(id, type));
    }
    return () => {
      setFile(defaultImage);
    };
  }, [busy, defaultImage, id, type]);

  return [file, busy, setFile];
};

const useCurrentPageParam = (location, history, match) => {
  const currentPage = getParameterByName("page");
  const setCurrentPage = (newPage) => {
    const search = updateURLParameter(location.search, "page", newPage);
    history.push({
      pathname: match.path,
      search: search,
    });
  };
  const safePage = parseInt(currentPage) || 1;
  return [safePage, setCurrentPage];
};

const useFavorite = (props, entity, entityType, identifier = "id") => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [middleware] = useAuthMiddleware(props);
  const { enqueueSnackbar } = useSnackbar();

  const toggleFavorite = () => {
    const save = isFavorite ? deleteData : postData;
    const url = `${ApiEndpoint[entityType]}/favorites/${entity[identifier]}`;
    const oldState = isFavorite;
    setIsFavorite(!isFavorite);
    save(url).catch((ex) => {
      setIsFavorite(oldState);
      enqueueSnackbar(ex.message, { variant: "error" });
    });
  };

  useEffect(() => {
    if (entity) {
      setIsFavorite(entity.favorite);
    }
  }, [entity]);

  const renderButton = () => (
    <IconButton
      aria-label="add to favorites"
      onClick={() => middleware(toggleFavorite)}
    >
      {isFavorite ? (
        <Favorite style={{ color: "red" }} />
      ) : (
        <FavoriteBorderOutlined />
      )}
    </IconButton>
  );

  return {
    toggleFavorite,
    isFavorite,
    renderButton,
  };
};

const useAuthMiddleware = (props) => {
  const { history, location } = props;
  const handleAction = useCallback(
    (callback) => {
      if (isUserAuthenticated()) {
        return callback();
      }
      history.push(`/auth?redirect=${location.pathname + location.search}`);
    },
    [history, location.pathname, location.search]
  );

  return [handleAction];
};

function getProp(type) {
  if (type === "footer") return "footerMenuId"
  return "headerMenuId"
}

const useMenuLinks = (context, type) => {
  const { preview, menus, menuSettings, menuTriggerCount } = context.PageBuilder;
  const [currentMenuLinks, setCurrentMenuLinks] = useState([])

  const filteredMenus = useMemo(() => {
    if (isAdmin() && preview) {
      return currentMenuLinks;
    }
    return menus.temp[type];
  }, [currentMenuLinks, menus.temp, preview, type]);

  useEffect(() => {
    if (isAdmin() && preview && menuSettings.temp[getProp(type)]) {
      const url = `${API_URL}/manage/menus/${menuSettings.temp[getProp(type)]}/entries`;
      fetchData(url).then(res => setCurrentMenuLinks(res)).catch(ex => console.log(ex))
    } else {
      setCurrentMenuLinks([])
    }
  }, [menuSettings.temp, preview, type, menuTriggerCount])

  return filteredMenus
}

const useAppLinks = (context) => {
  return useMemo(() => {
    const staticRoutes = publicRoutes
        .filter((x) => !x.path.includes(":"))
        .map((x) => ({ name: x.title, id: x.path }))
    const dynamicRoutes = context.Page.getAllPageable.result.content.map(page => ({name: page.title, id: `/pages/${page.slug}`}))

    return [
        ...staticRoutes,
        ...dynamicRoutes
    ].sort((a, b) => compareProp(a, b, "name"));

  }, [context.Page.getAllPageable.result.content]);
}


const isBrowser = typeof window !== `undefined`

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
      ? { x: window.scrollX, y: window.scrollY }
      : { x: position.left, y: position.top }
}

function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }))

  let throttleTimeout = useRef(null)

  const callBack = useCallback(() => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout.current = null
  }, [effect, element, useWindow])

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout.current === null) {
          throttleTimeout.current = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [callBack, wait])
}

export {
  useInstagram,
  useDefaultTable,
  useAuthValidation,
  useSubmit,
  useImage,
  useCurrentPageParam,
  useFavorite,
  useAuthMiddleware,
  useAlert,
  useMenuLinks,
  useAppLinks,
  useScrollPosition
};

import { useImage } from "../../hooks";
import { Box, Button, CardActionArea, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import ZoomImage from "../ZoomImage";
import Placeholder from "../Placeholder";
import Typography from "@material-ui/core/Typography";
import { combineStrings } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  featureImage: {
    width: "100%",
    height: 400,
    objectFit: "cover",
  },
  linkButton: {
    textTransform: "capitalize",
  },
});
function FeaturedDesigner({ designer, isLoading }) {
  const classes = useStyles();
  const [featureImage, loadingFeatureImage] = useImage(
    designer?.featureImageId
  );

  return (
    <Grid>
      <CardActionArea
        disabled={isLoading}
        component={Link}
        style={{ overflow: "hidden" }}
        to={`/designer/${designer?.slug}`}
      >
        {isLoading || loadingFeatureImage ? (
          <Skeleton variant="rect" className={classes.featureImage} />
        ) : featureImage ? (
          <ZoomImage
            src={featureImage}
            classNames={classes.featureImage}
            alt={"insta post"}
          />
        ) : (
          <Box height={400}>
            <Placeholder type={"Designer"} />
          </Box>
        )}
      </CardActionArea>
      <Box
        mt={2}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          {isLoading ? (
            <Skeleton variant="text" width={80} height={30} />
          ) : (
            <Typography
              variant={"h6"}
              style={{ textAlign: "center", fontWeight: "bold", fontSize: 18 }}
            >{`${designer.firstName} ${designer.lastName}`}</Typography>
          )}
          {isLoading ? (
            <Skeleton variant="text" width={100} height={30} />
          ) : (
            <Typography
              variant={"body1"}
              style={{ textAlign: "center", fontSize: 18 }}
            >
              {combineStrings(" | ", designer.state, designer.city)}
            </Typography>
          )}
        </Box>
        {isLoading ? (
          <Skeleton variant={"rect"} width={100} height={30} />
        ) : (
          <Button
            classes={{ label: classes.linkButton }}
            size={"small"}
            variant={"contained"}
            component={Link}
            to={`/designer/${designer?.id}`}
            color={"primary"}
          >
            See Profile
          </Button>
        )}
      </Box>
    </Grid>
  );
}

export default FeaturedDesigner;

FeaturedDesigner.propTypes = {
  designer: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

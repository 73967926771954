import React, { useContext, useEffect, useState } from "react";
import BaseContent from "../../components/Core/BaseContent";
import {fetchData, getDefaultHeaders} from "../../utils";
import { useFetch } from "reactcoregk/hooks";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";
import { Box, Container, Grid, MuiThemeProvider } from "@material-ui/core";
import { ExtraTitle } from "../../components/Layouts/DefaultLayout";
import { Skeleton } from "@material-ui/lab";
import { Editor, initState } from "gk-page-builder";
import { useAppTheme } from "../../constants/theme";
import { Paging } from "reactcoregk/models";
import Title from "../../components/Core/Title";
import StoryCard from "../../components/StoryCard/StoryCard";
import {Helmet} from "react-helmet";
import {API_URL} from "../../config";

function loadState(blockString) {
  try {
    const stored = JSON.parse(blockString);
    return initState(stored);
  } catch (e) {
    return [];
  }
}

function ArticleSkeleton() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display={"flex"} justifyContent={"center"}>
          <Skeleton variant={"rect"} height={50} width={400} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant={"rect"} width={"100%"} height={400} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant={"rect"} width={"100%"} height={400} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant={"rect"} height={40} width={200} />
        <Box mt={2} />
        <Skeleton variant={"rect"} height={200} width={"100%"} />
        <Box mt={2} />
        <Skeleton variant={"rect"} height={60} width={100} />
      </Grid>
    </Grid>
  );
}

const storiesUrl = `${ApiEndpoint[EntityType.Article]}?items=${5}`;
export default function StoryDetails(props) {
  const slug = props.match.params.slug;
  const [state, setState] = useState([]);
  const theme = useAppTheme();
  const [loading, setLoading] = useState(false)
  const [story, setStory] = useState({})
  const [loadingStories, stories] = useFetch(
    storiesUrl,
    getDefaultHeaders(),
    new Paging()
  );
  const { setExtraTitle } = useContext(ExtraTitle);

  useEffect(() => {
    setExtraTitle(slug);
  }, [slug, setExtraTitle]);

  useEffect(() => {
    setLoading(true)
    fetchData(`${ApiEndpoint[EntityType.Article]}/${slug}`)
        .then(data => {
          setLoading(false)
          setStory(data)
            console.log(data)
        }).catch(ex => {
          setLoading(false)
    })
  }, [slug])

  useEffect(() => {
    const newState = loadState(story?.body);
    setState(newState);
  }, [story?.body]);

  return (
      <>
          {!loading && story.title &&
          <Helmet meta={[
            { property: 'og:title', content: story.title },
            { property: 'og:site_name', content: "Freddie" },
            { property: 'og:type', content: "article" },
            { property: 'og:url', content: window.location },
            { property: 'og:description', content: story.title },
            { property: 'og:image', content: `${API_URL}/files/${story.imageId}` },
            { property: 'og:site_name', content: "FREDDIE" }]}
          />
          }
    <BaseContent title={!loading && story?.title} subtitle={""}>
      <Container>
        {loading && <ArticleSkeleton />}
        {!loading && <MuiThemeProvider theme={theme}>
          <Editor isEditing={false} state={state} setState={setState}/>
        </MuiThemeProvider>}
        <Title>
          <span style={{ fontSize: 24 }}>Additional Stories</span>
        </Title>
        <Grid container spacing={4}>
          {loadingStories || loading
            ? [...Array(4)].map((x, i) => (
                <Grid item sm={6} md={3} xs={12} key={i}>
                  <StoryCard height={200} isLoading={true} key={i} />
                </Grid>
              ))
            : stories.content
                .filter((x) => x.slug !== slug)
                .slice(0, 4)
                .map((story, i) => (
                  <Grid item sm={6} md={3} xs={12} key={i}>
                    <StoryCard height={200} story={story} key={i} />
                  </Grid>
                ))}
        </Grid>
      </Container>
    </BaseContent>
      </>
  );
}

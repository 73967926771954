import BaseContent from "../../components/Core/BaseContent";
import { Grid } from "@material-ui/core";
import { fetchData } from "../../utils";
import { API_URL } from "../../config";
import { Paging } from "reactcoregk/models";
import { useCurrentPageParam } from "../../hooks";
import { Pagination } from "@material-ui/lab";
import { useEffect, useState } from "react";
import StoryCard from "../../components/StoryCard/StoryCard";

const url = `${API_URL}/articles`;

export default function Stories(props) {
  const { location, history, match } = props;
  const rootParams = props.location.search;

  const [currentPage, setCurrentPage] = useCurrentPageParam(
    location,
    history,
    match
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(new Paging());
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetchData(url + rootParams)
      .then((stories) => {
        setData(stories);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        setErrorMessage(ex.message);
      });
  }, [rootParams]);

  return (
    <BaseContent title={"STORIES"} errorMessage={errorMessage}>
      <Grid container spacing={6}>
        {loading
          ? [...Array(10)].map((x, i) => (
              <Grid item sm={6} xs={12} key={i}>
                <StoryCard isLoading={true} key={i} />
              </Grid>
            ))
          : data.content?.map((story) => (
              <Grid item sm={6} xs={12} key={story.id}>
                <StoryCard story={story} key={story.id} />
              </Grid>
            ))}
        <Grid item xs={12}>
          <Pagination
            onChange={(e, newPage) => setCurrentPage(newPage)}
            page={currentPage}
            count={data.totalPages}
            showFirstButton
            showLastButton
            size={"large"}
          />
        </Grid>
      </Grid>
    </BaseContent>
  );
}

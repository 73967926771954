import {Container, Grid} from "@material-ui/core";
import BaseContent from "../../components/Core/BaseContent";
import * as Yup from "yup";
import {Formik} from "formik";
import {forgotPassword} from "../../constants/forms";
import {API_URL} from "../../config";
import {useSubmit} from "../../hooks";
import FormContainer from "../../components/FormContainer";

const url = `${API_URL}/account/forgot-password`;
const success = "Check your email for the confirmation link.";
const sub =
  "Enter your email address and we will send you an email with a link to reset your password.";

export default function ForgotPassword() {
  const { successMessage, errorMessage, busy, handleSubmit } = useSubmit(
    url,
    success
  );

  return (
    <BaseContent
      title={"Forgot your password?"}
      subtitle={sub}
      successMessage={successMessage}
      errorMessage={errorMessage}
    >
      <Container maxWidth={"sm"}>
        <Grid
          container
          direction={"row"}
          spacing={2}
          justify={"center"}
          alignItems={"center"}
        >
          <Formik
            initialValues={{}}
            onSubmit={values => handleSubmit(values, false)}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email")
                .required("This field is required"),
            })}
          >
            {(formik) => (
              <FormContainer
                form={forgotPassword}
                busy={busy}
                submitLabel={"Send Email"}
              />
            )}
          </Formik>
        </Grid>
      </Container>
    </BaseContent>
  );
}

import { API_URL } from "../../config";
import { EntityType } from './entityType'

export const ApiEndpoint = {
    [EntityType.User]: API_URL + "/api/users",
    [EntityType.RegisterList]: API_URL + "/account/register",
    [EntityType.Designer]: API_URL + "/designers",
    [EntityType.Article]: API_URL + "/articles",
    [EntityType.Account]: API_URL + "/account",
    [EntityType.Glossary]: API_URL + "/glossary",
    [EntityType.Menu]: API_URL + "/manage/menus",
    [EntityType.Page]: API_URL + "/manage/pages",
}

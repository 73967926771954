import FormikTextField from "./Core/FormikTextField";
import FormikSelect from "./Core/FormikSelect";
import FormikCheckbox from "./Core/FormikCheckbox";
import FormikPhoneNumber from "./Core/FormikPhoneNumber";
import FormikPlaces from "./Core/FormikPlaces";
const checkDisabled =(id,disableFields) =>{
  switch(id){
    case 'city':
      if (disableFields.city === true)
        return true
      else
        return false
    case 'stateId':
      if (disableFields.stateId === true)
        return true
      else
        return false
    case 'zipCode':
      if (disableFields.zipCode === true)
        return true
      else
        return false
    default:
      return false
  }
}
export function getFormView(prop, index, busy, onClick,disableFields,setDisableFields) {
  switch (prop.type) {
    case "select":
      return (
        <FormikSelect
          variant={prop.variant || "outlined"}
          placeholder={prop.placeholder}
          margin="none"
          required={prop.required}
          label={prop.label}
          id={prop.id}
          name={prop.id}
          options={prop.options}
          autoFocus={index === 0}
          disabled={busy || (checkDisabled(prop.id,disableFields))}
        />
      );
    case "textarea":
      return (
        <FormikTextField
          variant={prop.variant || "outlined"}
          margin="none"
          multiline
          rows={6}
          placeholder={prop.placeholder}
          fullWidth
          required={prop.required}
          type={prop.type || "text"}
          id={prop.id}
          name={prop.id}
          onClick={onClick}
          label={prop.label}
          autoFocus={index === 0}
          disabled={busy}
        />
      );
    case "checkbox":
      return (
        <FormikCheckbox
          label={prop.label}
          id={prop.id}
          name={prop.id}
          disabled={busy}
        />
      );
    case "phoneNumber":
      return (
        <FormikPhoneNumber
          variant={prop.variant || "outlined"}
          margin="none"
          placeholder={prop.placeholder}
          fullWidth
          required={prop.required}
          type={"text"}
          showRequirements={prop.showRequirements}
          onClick={onClick}
          id={prop.id}
          name={prop.id}
          label={prop.label}
          autoFocus={index === 0}
          disabled={busy}
        />
      );
    case "placesAutocomplete":
      return (
          <FormikPlaces
              variant={prop.variant || "outlined"}
              margin="none"
              placeholder={prop.placeholder}
              fullWidth
              disableFields={disableFields}
              setDisableFields = {setDisableFields}
              required={prop.required}
              type={"text"}
              showRequirements={prop.showRequirements}
              onClick={onClick}
              id={prop.id}
              name={prop.id}
              label={prop.label}
              autoFocus={index === 0}
              disabled={busy}
          />
      );
    case "custom":
      return prop.element;
    default:
      return (
        <FormikTextField
          variant={prop.variant || "outlined"}
          margin="none"
          placeholder={prop.placeholder}
          fullWidth
          required={prop.required}
          type={prop.type || "text"}
          showRequirements={prop.showRequirements}
          onClick={onClick}
          id={prop.id}
          name={prop.id}
          label={prop.label}
          autoFocus={index === 0}
          disabled={busy || (checkDisabled(prop.id,disableFields))}
        />
      );
  }
}

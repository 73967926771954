import {useField} from "formik";
import {Checkbox, FormControlLabel} from "@material-ui/core";

const FormikCheckbox = (props) => {
  const { required = false} = props;
  const [field, meta] = useField(props.name);
  return (
      <FormControlLabel
          control={
              <Checkbox
                  color="primary"
                  {...field}
                  {...props}
                  {...props.rest}
                  checked={field.value}
                  error={meta.error && meta.touched}
                  helperText={meta.error}
                  required={required}
              />
          }
          label={props.label}
      />
  );
};

export default FormikCheckbox;

import {Fragment, useState} from 'react';
import { Grid, IconButton } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import StackGrid, { transitions } from "react-stack-grid";
import { getFileUrl } from "../../../utils";
import { Editable, PortfolioAdd } from "./views";
import React from "react";
import { useStyles } from "./styles";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ProfileLightbox from '../../../components/ProfileLightbox'

const { scaleDown } = transitions;

const FlexGrid = ({
  portfolioImageIds,
  removeImage,
  isEditing,
  portfolioImages,
  removeAddedImage,
  setOpenPortfolioCrop,
  hasEditPermission,
  designerView,
  profileImage
}) => {

  const [openLightBox,setOpenLightbox] = useState(false)
  const [lightBoxData,setLightBoxData] = useState({image:null,profile:{}})

  const handleLightBox = (uuid) =>{
    setLightBoxData({image:uuid,profile:{firstName:designerView.firstName,lastName:designerView.lastName,profileImage:profileImage}})
    setOpenLightbox(true)
  }

 return <>
   <StackGrid
    gutterWidth={20}
    gutterHeight={20}
    duration={500}
    appear={scaleDown.appear}
    appeared={scaleDown.appeared}
    enter={scaleDown.enter}
    monitorImagesLoaded={true}
    entered={scaleDown.entered}
    leaved={scaleDown.leaved}
    columnWidth={portfolioImageIds.length === 1 ? "100%" : "50%"}
  >
    {portfolioImageIds.map((uuid, index) => (
      <div
        style={{minHeight: "max-content", position: "relative"}}
        key={index}
      >
        <img style={{maxWidth: "100%",cursor:'pointer'}} src={getFileUrl(uuid)} alt={uuid} onClick={() => handleLightBox(uuid)}/>
        <Editable isEditing={isEditing}>
          <IconButton
            style={{color: "#fff"}}
            onClick={() => removeImage(uuid)}
          >
            <DeleteOutlineIcon fontSize={"large"}/>
          </IconButton>
        </Editable>
      </div>
    ))}
    {portfolioImages.map((file, index) => (
      <div
        style={{minHeight: "max-content", position: "relative"}}
        key={index}
      >
        <img style={{maxWidth: "100%"}} src={file.preview} alt={file.name}/>
        <Editable isEditing={isEditing}>
          <IconButton
            style={{color: "#fff"}}
            onClick={() => removeAddedImage(index)}
          >
            <DeleteOutlineIcon fontSize={"large"}/>
          </IconButton>
        </Editable>
      </div>
    ))}
    {isEditing &&
    (portfolioImageIds.length !== 0 || portfolioImages.length !== 0) && (
      <PortfolioAdd openCrop={() => setOpenPortfolioCrop(true)}/>
    )}
    {hasEditPermission &&
    portfolioImageIds.length === 0 &&
    portfolioImages.length === 0 &&
    [...Array(4)].map((k, i) => (
      <PortfolioAdd openCrop={() => setOpenPortfolioCrop(true)}/>
    ))}
  </StackGrid>
   <ProfileLightbox image={lightBoxData.image} profile={lightBoxData.profile} open={openLightBox} handleClose={()=> {
     setOpenLightbox(false)
   }}/>
   </>
}

const Portfolio = (props) => {
  const classes = useStyles();

  const {
    shouldCollapsePortfolio,
    isEditing,
    isLoading,
    portfolioImageIds,
    portfolioImages,
    removeImage,
    removeAddedImage,
    setOpenPortfolioCrop,
    hasEditPermission,
    designerView,
    profileImage
  } = props;

  if (shouldCollapsePortfolio) return null;
  return (
    <Fragment>
      <div className={classes.root} style={{ height: "100%" }}>
        {isLoading ? (
          <Grid container spacing={4}>
            {[...Array(5)].map((k, index) => (
              <Grid item sm={index === 0 ? 12 : 6} xs={12}>
                <Skeleton variant="rect" height={400} width={"100%"} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid xs={12}>
            <DeviceOrientation>
              <Orientation orientation="landscape" alwaysRender={false}>
                <div>
                  <FlexGrid
                    portfolioImageIds={portfolioImageIds}
                    portfolioImages={portfolioImages}
                    isEditing={isEditing}
                    removeImage={removeImage}
                    removeAddedImage={removeAddedImage}
                    setOpenPortfolioCrop={setOpenPortfolioCrop}
                    hasEditPermission={hasEditPermission}
                    isLoading={isLoading}
                    designerView={designerView}
                    profileImage={profileImage}
                  />
                </div>
              </Orientation>
              <Orientation orientation="portrait" alwaysRender={false}>
                <div>
                  <FlexGrid
                    portfolioImageIds={portfolioImageIds}
                    portfolioImages={portfolioImages}
                    isEditing={isEditing}
                    removeImage={removeImage}
                    removeAddedImage={removeAddedImage}
                    setOpenPortfolioCrop={setOpenPortfolioCrop}
                    hasEditPermission={hasEditPermission}
                    isLoading={isLoading}
                    designerView={designerView}
                    profileImage={profileImage}
                  />
                </div>
              </Orientation>
            </DeviceOrientation>
          </Grid>
        )}
      </div>
    </Fragment>
  );
};

export default Portfolio;

import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { motion } from "framer-motion";
import {
  containerVariants,
  containerVariants2,
  containerVariants3,
  useStyles,
} from "./styles";
import { Editable } from "./views";
import {
  Avatar,
  Box,
  Fab,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import Placeholder from "../../../components/Placeholder";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
// import BusyIndicator from "../../../components/Core/BusyIndicator";
// import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import VisibilityIcon from "@material-ui/icons/Visibility";

function Header(props) {
  const classes = useStyles();
  const {
    isLoading,
    hasEditPermission,
    handleEditToggle,
    // savingPublic,
    // toggleProfile,
    // isPublic,
    loadingFeatureImage,
    isEditing,
    setOpenFeatureCrop,
    featureImage,
    newFeatureImage,
    loadingProfile,
    newProfileImage,
    profileImage,
    setOpenProfileCrop,
  } = props;

  return (
    <div className={classes.banner}>
      {isLoading || loadingFeatureImage ? (
        <Skeleton variant="rect" className={classes.feature} />
      ) : (
        <motion.div
          style={{ flex: 1 }}
          className="wrapper"
          variants={containerVariants3}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <>
            <Editable isEditing={isEditing}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Typography style={{ color: "#fff" }}>Upload Image</Typography>
                <IconButton onClick={() => setOpenFeatureCrop(true)}>
                  <PhotoLibraryIcon
                    style={{ color: "#fff" }}
                    fontSize={"large"}
                  />
                </IconButton>
              </Box>
            </Editable>
            {!featureImage && !newFeatureImage?.preview ? (
              <Placeholder type={"Designer"} />
            ) : (
              <img
                src={newFeatureImage?.preview || featureImage}
                alt={"Feature"}
                className={classes.feature}
              />
            )}
          </>
        </motion.div>
      )}
      <motion.div
        className={classes.avatarWrapper}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {isLoading || loadingProfile || !profileImage ? (
          <Skeleton variant="circle" className={classes.avatar} />
        ) : (
          <div>
            <div>
              <Avatar
                alt={"Profile"}
                src={newProfileImage?.preview || profileImage}
                className={classes.avatar}
              />
            </div>
            <Editable isEditing={isEditing} style={{ borderRadius: 50 }}>
              <IconButton onClick={() => setOpenProfileCrop(true)}>
                <PhotoLibraryIcon
                  style={{ color: "#fff" }}
                  fontSize={"large"}
                />
              </IconButton>
            </Editable>
          </div>
        )}
      </motion.div>
      {hasEditPermission && (
        <motion.div
          className={classes.actionsWrapper}
          variants={containerVariants2}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Tooltip title={"Edit profile"}>
            <Fab onClick={handleEditToggle}>
              {isEditing ? <CloseIcon /> : <EditIcon />}
            </Fab>
          </Tooltip>
          {/*<Box mt={2} />*/}
          {/*<Tooltip title={isPublic ? "Hide my profile" : "Publish my profile"}>*/}
          {/*  <Fab onClick={toggleProfile}>*/}
          {/*    <BusyIndicator busy={savingPublic} />*/}
          {/*    {!isPublic ? <VisibilityOffIcon /> : <VisibilityIcon />}*/}
          {/*  </Fab>*/}
          {/*</Tooltip>*/}
        </motion.div>
      )}
    </div>
  );
}

export default Header;

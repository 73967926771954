import { all } from "redux-saga/effects";
//public
import RegisterListSaga from "./registerList/saga";
import AuthSaga from "./auth/login/saga";
import GlossarySaga from "./glossary/saga";
import PageBuilderSaga from "./pageBuilder/saga";
import MenuSaga from "./menu/saga";
import PageSaga from "./page/saga";

export default function* rootSaga() {
  yield all([
    RegisterListSaga(),
    AuthSaga(),
    GlossarySaga(),
    PageBuilderSaga(),
    MenuSaga(),
    PageSaga(),
  ]);
}

import BaseContent from "../../components/Core/BaseContent";
import { Box, Chip, Grid } from "@material-ui/core";
import Title from "../../components/Core/Title";
import BaseSelect from "../../components/Core/BaseSelect";
import { useFetch } from "reactcoregk/hooks";
import DesignerCard from "./DesignerCard";
import CategoryCard from "./StyleCategoryCard";
import {
  fetchData,
  getDefaultHeaders,
  getParameterByName,
  updateURLParameter,
} from "../../utils";
import { Paging } from "reactcoregk/models";
import { Pagination } from "@material-ui/lab";
import { useCurrentPageParam } from "../../hooks";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";
import { useCallback, useEffect, useMemo, useState } from "react";
import ContactDesignerDialog from "./ContactDesignerDialog";

const filterState = {
  states: [],
  designStyles: [],
  consultations: [],
  sortBy: [],
};

function addAll(array, label) {
  return [
    {
      id: 0,
      name: label,
    },
    ...array,
  ];
}

const sortByOptions = addAll(
  [
    {
      id: "&sort=title&order=acs",
      name: "Designer Name (A-Z)",
    },
    {
      id: "&sort=title&order=desc",
      name: "Designer Name (Z-A)",
    },
    // {
    //   id: "&sort=date&order=desc",
    //   name: "Newest Designer",
    // },
    // {
    //   id: "&sort=date&order=acs",
    //   name: "Oldest Designer",
    // },
  ],
  "Sort By"
);

const filtersUrl = `${ApiEndpoint[EntityType.Designer]}/filters`;

function Designers(props) {
  const rootParams = props.location.search.replaceAll("?", "&");
  const [sortBy, setSortBy] = useState(0);
  const [, filterOptions] = useFetch(
    filtersUrl,
    getDefaultHeaders(),
    filterState
  );
  const [selectedDesigner, setSelectedDesigner] = useState({});
  const [openContact, setOpenContact] = useState(false);
  const [data, setData] = useState(new Paging());
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { location, history, match } = props;

  useEffect(() => {
    setIsLoading(true);
    let params = rootParams;
    if (sortBy) {
      const sortSelected = sortByOptions.find((x) => x.id === sortBy);
      params += sortSelected.id;
    }
    fetchData(`${ApiEndpoint[EntityType.Designer]}/?items=20${params}`)
      .then((res) => {
        setData(res);
        setErrorMessage(false);
        setIsLoading(false);
      })
      .catch((ex) => {
        setErrorMessage(ex.message);
        setIsLoading(false);
      });
  }, [rootParams, sortBy]);

  const handleChange = useCallback(
    (prop) => (e) => {
      let value = e.target.value || "";
      if (value === "0") value = "";
      const search = updateURLParameter(location.search, prop, value);
      const search2 = updateURLParameter(search, "page", 1);
      history.push({
        pathname: match.path,
        search: search2,
      });
    },
    [history, location.search, match.path]
  );

  const handleDelete = useCallback(
    (prop) => {
      const search = updateURLParameter(location.search, prop, "");
      history.push({
        pathname: match.path,
        search: search,
      });
    },
    [history, location.search, match.path]
  );

  const handleStyleCardSelect = useCallback(
    (id) => {
      const search = updateURLParameter(location.search, "design-style", id);
      const search2 = updateURLParameter(search, "page", 1);
      history.push({
        pathname: match.path,
        search: search2,
      });
    },
    [history, location.search, match.path]
  );

  const [currentPage, setCurrentPage] = useCurrentPageParam(
    location,
    history,
    match
  );

  const handleGetInTouch = (designer) => {
    setSelectedDesigner(designer);
    setOpenContact(true);
  };

  const states = useMemo(() => addAll(filterOptions.states, "States"), [
    filterOptions.states,
  ]);
  const styles = useMemo(() => addAll(filterOptions.designStyles, "Styles"), [
    filterOptions.designStyles,
  ]);
  const consultations = useMemo(
    () => addAll(filterOptions.consultations, "Consultations"),
    [filterOptions.consultations]
  );

  const extraParams = useMemo(() => {
    const searchParams = new URLSearchParams(props.location.search);
    const filtered = [];
    let list;
    searchParams.forEach(function (value, key) {
      if (value && value !== "0") {
        if (key === "state") list = states;
        else if (key === "design-style") list = styles;
        else if (key === "consultation") list = consultations;
        else return;
        const joinedOption = list.find((x) => x.id.toString() === value);
        filtered.push({
          key,
          value: joinedOption?.name,
        });
      }
    });
    return filtered;
  }, [consultations, props.location.search, states, styles]);

  return (
    <BaseContent title={"DESIGNER DIRECTORY"} errorMessage={errorMessage}>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xl={2} md={3} sm={4} xs={12}>
          <BaseSelect
            variant={"standard"}
            margin={"dense"}
            value={getParameterByName("state") || 0}
            onChange={handleChange("state")}
            options={states}
            controlId={"state"}
          />
        </Grid>
        <Grid item xl={2} md={3} sm={4} xs={12}>
          <BaseSelect
            variant={"standard"}
            margin={"dense"}
            value={getParameterByName("design-style") || 0}
            onChange={handleChange("design-style")}
            options={styles}
            controlId={"design-style"}
          />
        </Grid>
        <Grid item xl={2} md={3} sm={4} xs={12}>
          <BaseSelect
            variant={"standard"}
            margin={"dense"}
            value={getParameterByName("consultation") || 0}
            onChange={handleChange("consultation")}
            options={consultations}
            controlId={"consultation"}
          />
        </Grid>
        <Grid item xl={10} md={9} sm={8} xs={12}>
          <Grid container spacing={1}>
            {extraParams.map((param) => (
              <Grid item>
                <Chip
                  color={"secondary"}
                  label={`${param.key}: ${param.value || "loading.."}`}
                  onDelete={() => handleDelete(param.key)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xl={2} md={3} sm={4} xs={12}>
          <BaseSelect
            onChange={(e) => setSortBy(e.target.value)}
            value={sortBy}
            variant={"standard"}
            margin={"none"}
            // label={"SORT BY"}
            options={sortByOptions}
            controlId={"sort"}
          />
        </Grid>
      </Grid>
      <Box mt={4} />
      <Grid container spacing={2}>
        {isLoading
          ? [...Array(8)].map((el, i) => (
              <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
                <DesignerCard busy={isLoading} />
              </Grid>
            ))
          : data.content.map((designer, i) => (
              <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
                <DesignerCard
                  designer={designer}
                  handleGetInTouch={handleGetInTouch}
                />
              </Grid>
            ))}
        <Grid item xs={12}>
          <Pagination
            onChange={(e, newPage) => setCurrentPage(newPage)}
            page={currentPage}
            count={data.totalPages}
            showFirstButton
            showLastButton
            size={"large"}
          />
        </Grid>
      </Grid>
      <Box mt={8} />
      <Title>DESIGNERS BY STYLE</Title>
      <Box mt={4} />
      <Grid container spacing={2}>
        {isLoading
          ? [...Array(8)].map((el, i) => (
              <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
                <CategoryCard busy={isLoading} />
              </Grid>
            ))
          : filterOptions.designStyles.map((style, i) => (
              <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
                <CategoryCard
                  style={style}
                  handleClick={handleStyleCardSelect}
                />
              </Grid>
            ))}
      </Grid>
      <ContactDesignerDialog
        open={openContact}
        designer={selectedDesigner}
        handleClose={() => setOpenContact(false)}
      />
    </BaseContent>
  );
}

export default Designers;

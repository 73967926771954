import { Fade, Popper, Typography } from "@material-ui/core";
import PopoverContainerBase from "./Core/PopoverContainerBase";
import React from "react";
import Box from "@material-ui/core/Box";
import {
  passwordHasLowerCase,
  passwordHasNumber,
  passwordHasUpperCase,
} from "../utils";
import {Cancel, CheckCircle} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles({
  text: {
    fontSize: 14,
    marginLeft: 8,
    fontWeight: 500
  },
});

function Requirement({ title, valid }) {
  const classes = useStyles();

  return (
    <Box display={"flex"}>
      {valid ? (
        <CheckCircle color={"primary"} style={{color: "#81c784", fontSize: 18}}/>
      ) : (
        <Cancel color={"error"} style={{fontSize: 18}}/>
      )}
      <Typography className={classes.text} color={"secondary"}>{title}</Typography>
    </Box>
  );
}


function PasswordRequirements(props) {
  const { anchorEl, open, id, setAnchorEl, password } = props;

  const hasSize = password?.length >= 8;
  const hasUpper = passwordHasUpperCase(password);
  const hasLower = passwordHasLowerCase(password);
  const hasNumber = passwordHasNumber(password);

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      transition
      disableEnforceFocus
      disableAutoFocus
      placement="bottom-end"
      disablePortal={true}
      style={{zIndex: 2}}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <div>
            <PopoverContainerBase
              title={"Password Requirements"}
              clickAway={false}
              handleClose={() => setAnchorEl(null)}
              open={Boolean(anchorEl)}
            >
              <Box p={2}>
                <Typography color={"secondary"} style={{fontSize: 14, fontWeight: 500}}>
                  Password must have the following
                </Typography>
                <Box mt={1}/>
                <Requirement title={"8 Characters minimum"} valid={hasSize}/>
                <Requirement title={"1 lowercase letter"} valid={hasLower}/>
                <Requirement title={"1 uppercase letter"} valid={hasUpper}/>
                <Requirement title={"1 number"} valid={hasNumber}/>
              </Box>
            </PopoverContainerBase>
          </div>
        </Fade>
      )}
    </Popper>
  );
}

export default PasswordRequirements;

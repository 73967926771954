import Navigation from "../Core/Navigation";
import Footer from "../Footer";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import {createContext, useState} from "react";

const containerVariants = {
  hidden: {
    opacity: 0,
    transform: "scale(0.95)",
  },
  visible: {
    opacity: 1,
    transform: "scale(1)",
    transition: "opacity 100ms, transform 100ms",
  },
  exit: {
    opacity: 1,
    transform: "scale(0.95)",
  },
};

export const ExtraTitle = createContext(null);

function renderTitle(title) {
  if (title) return `FREDDIE | ${title}`
  return "FREDDIE"
}

function DefaultLayout(props) {
  const [extraTitle, setExtraTitle] = useState("");
  const {title, applyTransparency} = props

  return (
    <>
      <ExtraTitle.Provider value={{ extraTitle, setExtraTitle }}>
        <Helmet>
          <title>{`${renderTitle(title)} ${extraTitle}`}</title>
        </Helmet>
        <Navigation applyTransparency={applyTransparency}/>
        <motion.div
          style={{ flex: 1 }}
          className="wrapper"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {props.children}
        </motion.div>
        {!props.hideFooter && <Footer/>}
      </ExtraTitle.Provider>
    </>
  );
}

export default DefaultLayout;

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Avatar, Box, CardActionArea, CardActions } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { useImage } from "../../hooks";
import profileDefault from "../../assets/images/profile-filler.png";
import IconButton from "@material-ui/core/IconButton";
import { EmailOutlined } from "@material-ui/icons";
import Placeholder from "../../components/Placeholder";
import React from "react";
import {combineStrings} from "../../utils";

const useStyles = makeStyles({
  media: {
    height: 220,
    paddingTop: "56.25%", // 16:9
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    marginTop: -50,
    width: 100,
    height: 100,
  },
  text: {
    width: "100%",
    textAlign: "center",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "space-around",
  },
});

function DesignerCard(props) {
  const { busy, designer, handleGetInTouch } = props;
  const classes = useStyles();
  const [profileImage, loadingProfile] = useImage(
    designer?.profileImageId,
    profileDefault
  );
  const [featureImage, loadingFeatureImage] = useImage(
    designer?.featureImageId
  );
  // const { renderButton } = useFavorite(props, designer, EntityType.Designer);

  const handleContact = () => {
    handleGetInTouch(designer);
  };
  return (
    <Card variant={"outlined"}>
      <CardActionArea
        disabled={busy}
        component={Link}
        to={`/designer/${designer?.slug}`}
      >
        {busy || loadingFeatureImage ? (
          <Skeleton variant="rect" className={classes.media} />
        ) : featureImage ? (
          <CardMedia
            className={classes.media}
            image={featureImage}
            title={designer.firstName}
          />
        ) : (
          <Box height={220}>
            <Placeholder type={"Designer"} />
          </Box>
        )}
        <Box style={{ display: "flex", justifyContent: "center" }}>
          {busy || loadingProfile ? (
            <Skeleton variant="circle" className={classes.avatar} />
          ) : (
            <Avatar
              alt={designer.firstName}
              src={profileImage}
              className={classes.avatar}
            />
          )}
        </Box>
        <CardContent
          className={classes.container}
          style={{ paddingBottom: 32 }}
        >
          {busy ? (
            <>
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={150} height={20} />
              <Skeleton variant="text" width={100} height={20} />
            </>
          ) : (
            <>
              <Typography
                variant="h6"
                component="h4"
                noWrap
                className={classes.text}
              >
                {`${designer.firstName} ${designer.lastName}`}
              </Typography>
              <Typography noWrap className={classes.text}>
                {designer.businessName}
              </Typography>
              <Typography noWrap className={classes.text}>
                {combineStrings(", ", designer.city, designer.state)}
              </Typography>
            </>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing className={classes.btnGroup}>
        {/*{busy ? (*/}
        {/*  <Skeleton variant="circle" width={40} height={40} />*/}
        {/*) : (*/}
        {/*  <IconButton>*/}
        {/*    <Info />*/}
        {/*  </IconButton>*/}
        {/*)}*/}
        {busy ? (
          <Skeleton variant="circle" width={40} height={40} />
        ) : (
          <IconButton aria-label="contact" onClick={handleContact}>
            <EmailOutlined />
          </IconButton>
        )}
        {/*{busy ? (*/}
        {/*  <Skeleton variant="circle" width={40} height={40} />*/}
        {/*) : (*/}
        {/*  renderButton()*/}
        {/*)}*/}
      </CardActions>
    </Card>
  );
}
export default withRouter(DesignerCard);

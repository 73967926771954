import {getFileUrl} from '../utils';
import Dialog from '@material-ui/core/Dialog';
import {Avatar, Box, Grid, IconButton, Typography} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// import {Add, FavoriteBorder, PersonOutline, Share} from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const ProfileLightbox = (props) => {
  const {image, profile, open, handleClose} = props
  const mobile = useMediaQuery('(max-width:500px)');

  return <Dialog
    onClose={handleClose}
    maxWidth={'md'}
    fullScreen={mobile}
    fullWidth
    style={{height: 'calc(100vh +200px)'}}
    aria-labelledby="customized-dialog-title"
    open={open}
  >
    <Grid container style={{padding: mobile ? 0 : 20, overflow: mobile ? 'hidden' : ''}} direction={'column'}
          justify={'center'} alignItems={'center'}>
      <Grid item style={mobile ? {marginLeft: 'auto', position: 'absolute', top: 10, right: 10} : {marginLeft: 'auto'}}>
        <IconButton onClick={handleClose} style={mobile?{backgroundColor:'#fff',color:'black'}:{}}>
          <CloseIcon fontSize={'large'}/>
        </IconButton>
      </Grid>
      <Grid item container alignItems={'center'} justify={'center'} direction={'column'}>
        <Grid item style={mobile ? {height: 'calc(100vh - 340px)', minHeight: '100px', width: '100%'} : {}}>
          {mobile ?
            <Box width={'100%'} height={'100%'} style={{
              background: `url(${getFileUrl(image)}) no-repeat`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}/> :
            <img src={getFileUrl(image)} alt={image} style={
              {
                height: '100%',
                width: '100%',
                maxHeight: 'calc(100vh - 340px)',
               maxWidth: '100vh',
              objectFit: 'contain'
              }}/>}
        </Grid>
        <Grid item style={{marginTop: mobile ? -70 : -55}}>
          <Avatar
            alt={'Profile'}
            style={{
              width: mobile ? 120 : 80,
              height: mobile ? 120 : 80,
            }}
            src={profile.profileImage}
          />
        </Grid>
      </Grid>
      <Grid item style={{marginTop: 10}}>
        <Typography variant={'h5'} style={{fontSize: 14, fontWeight: 500}}>DESIGNED BY</Typography>
      </Grid>
      <Grid item style={{marginTop: 10}}>
        <Typography variant={'h4'}
                    style={{fontSize: 32, fontWeight: 500}}>{profile.firstName} {profile.lastName}</Typography>
      </Grid>
      {/*<Grid item style={{marginTop: 10}} container direction={'row'} spacing={2} justify={'center'}*/}
      {/*      alignItems={'center'}>*/}
      {/*  <Grid item>*/}
      {/*    <IconButton style={{backgroundColor: '#334866', color: 'white'}} color={'inherit'}>*/}
      {/*      <Add/>*/}
      {/*    </IconButton>*/}
      {/*  </Grid>*/}
      {/*  <Grid item>*/}
      {/*    <IconButton style={{backgroundColor: '#334866', color: 'white'}} color={'inherit'}>*/}
      {/*      <FavoriteBorder/>*/}
      {/*    </IconButton>*/}
      {/*  </Grid>*/}
      {/*  <Grid item>*/}
      {/*    <IconButton style={{backgroundColor: '#334866', color: 'white'}} color={'inherit'}>*/}
      {/*      <Share/>*/}
      {/*    </IconButton>*/}
      {/*  </Grid>*/}
      {/*  <Grid item>*/}
      {/*    <IconButton style={{backgroundColor: '#334866', color: 'white'}} color={'inherit'}>*/}
      {/*      <PersonOutline/>*/}
      {/*    </IconButton>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
    </Grid>
  </Dialog>
}

export default ProfileLightbox

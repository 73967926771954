import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import SectionTitle from "../../../components/SectionTitle";
import { useMemo, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { getIgUrl, getUserId } from "../../../utils";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  listTitle: {
    color: "#3A5273",
    fontSize: 18,
    fontWeight: "bold",
  },
  listItem: {
    marginBottom: 16,
    fontFamily: "'Avenir', sans-serif",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const featuresLoaderData = [
  {
    label: "Specialty",
    value: ["", "", ""],
  },
  {
    label: "Style Design",
    value: ["", "", ""],
  },
  {
    label: "Can Advise On",
    value: ["", "", ""],
  },
];

function Feature({ feature, notLast, isLoading }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component={"h4"} className={classes.listTitle}>
            {isLoading ? (
              <Skeleton variant="text" width={120} height={30} />
            ) : (
              feature.label
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
          {feature.value.map((item) => (
            <Typography className={classes.listItem}>
              {isLoading ? (
                <Skeleton variant="text" width={230} height={25} />
              ) : (
                item
              )}
            </Typography>
          ))}
        </AccordionDetails>
      </Accordion>
      {notLast && <Divider />}
    </>
  );
}

function ProfileInfo(props) {
  const { isLoading, designer /*...rest*/ } = props;
  // const { renderButton } = useFavorite(rest, designer, EntityType.Designer);
  if (!designer.id && !isLoading) return null;
  return (
    <>
      {isLoading ? (
        <Skeleton variant="text" width={200} height={35} />
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{ fontSize: 24, fontWeight: "bold", marginRight: 8 }}
            component={"h4"}
            variant={"h4"}
          >
            {`${designer.firstName} ${designer.lastName}`}
          </Typography>
          {/*{renderButton()}*/}
        </div>
      )}
      {isLoading ? (
        <Skeleton variant="text" width={330} height={20} />
      ) : (
        <Typography component={"p"} variant={"body1"}>
          Designer Consultations: {designer.consultation}
        </Typography>
      )}
      <Box mt={2} />
      {isLoading ? (
        <Skeleton variant="text" width={300} height={20} />
      ) : (
        Boolean(designer.businessWebSite) && (
          <Typography style={{ fontSize: 14 }}>
            <span style={{ color: "#3A5273" }}>web</span>:{" "}
            <a
              href={designer.businessWebSite}
              target={"_blank"}
              className={"hyperlink"}
              rel="noreferrer"
            >
              {designer.businessWebSite.replace(
                /^(?:https?:\/\/)?(?:www\.)?/i,
                ""
              ) || "N/A"}
            </a>
          </Typography>
        )
      )}
      {isLoading ? (
        <Skeleton variant="text" width={280} height={20} />
      ) : (
        Boolean(designer.instagram) && (
          <Typography style={{ fontSize: 14 }} color={"textPrimary"}>
            <span style={{ color: "#3A5273" }}>instagram</span>:{" "}
            <a
              href={getIgUrl(designer.instagram)}
              target={"_blank"}
              className={"hyperlink"}
              rel="noreferrer"
            >
              {`@${designer.instagram}` || "N/A"}
            </a>
          </Typography>
        )
      )}
      {!isLoading && <Box mt={2} />}
      {isLoading ? (
        <Skeleton variant="text" width={"100%"} height={300} />
      ) : (
        <Typography style={{whiteSpace: 'pre-line'}} component={"p"}>{designer.bio}</Typography>
      )}
    </>
  );
}

function DesignerSpecialties({ isLoading, designer }) {
  const classes = useStyles();
  const features = useMemo(() => {
    return [
      {
        label: "Specialty",
        value: designer?.specialties,
      },
      {
        label: "Style Design",
        value: designer?.designStyles,
      },
      {
        label: "Can Advise On",
        value: designer?.adviceOffers,
      },
    ];
  }, [designer]);

  if (!designer.id && !isLoading) return null;

  return (
    <>
      {!isLoading && <Box mt={4} />}
      {designer.since && (
        <SectionTitle
          style={{ fontSize: 18, marginTop: -30 }}
          title={`Practicing Professional Since: ${designer.since}`}
        />
      )}
      <div style={{ marginBottom: -20 }} />
      <div className={classes.root}>
        {isLoading &&
          featuresLoaderData.map((f, i) => (
            <Feature
              feature={f}
              isLoading
              key={i}
              notLast={i < features.length - 1}
            />
          ))}
        {!isLoading &&
          features.map(
            (feature, index) =>
              feature?.value[0] && (
                <Feature
                  feature={feature}
                  notLast={index < features.length - 1}
                />
              )
          )}
      </div>
    </>
  );
}

function ContactButton({ isLoading, designer, direction, openContact }) {
  const matches = useMediaQuery("(min-width:720px)");

  return (
    !isLoading &&
    designer.email && (
      <Grid container justify={direction} component={Box} mt={2}>
        <Button
          color={"primary"}
          variant={"outlined"}
          size={"large"}
          style={{ minWidth: 300, flex: !matches ? 1 : "initial" }}
          onClick={openContact}
        >
          Get in Touch
        </Button>
      </Grid>
    )
  );
}

function DesignerView(props) {
  const { designer, isLoading, shouldCollapsePortfolio } = props;
  const isMe = designer.id === getUserId();
  return (
    <Grid container spacing={4}>
      <Grid item sm={shouldCollapsePortfolio ? 6 : 12} xs={12}>
        <ProfileInfo
          isLoading={isLoading}
          designer={designer}
          history={props.history}
          location={props.location}
        />
        {!isMe && shouldCollapsePortfolio && (
          <ContactButton direction={"flex-start"} {...props} />
        )}
      </Grid>
      <Grid item sm={shouldCollapsePortfolio ? 6 : 12} xs={12}>
        <DesignerSpecialties designer={designer} isLoading={isLoading} />
        {!isMe && !shouldCollapsePortfolio && (
          <ContactButton direction={"flex-end"} {...props} />
        )}
      </Grid>
    </Grid>
  );
}

export default withRouter(DesignerView);

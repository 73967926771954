import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    overflow: "hidden",
  },
  featureImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "transform .5s",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
});

function ZoomImage(props) {
  const classes = useStyles();
  const { height, width, src, alt, classNames } = props;
  return (
    <div style={{ height, width }} className={[classes.root, classNames].join(" ")}>
      <img className={classes.featureImage} src={src} alt={alt} />
    </div>
  );
}

export default ZoomImage;

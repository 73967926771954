import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Link as MaterialLink,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import InstagramIcon from "@material-ui/icons/Instagram";
import {useAlert, useMenuLinks, useSubmit} from "../hooks";
import { API_URL } from "../config";
import FormikTextField from "./Core/FormikTextField";
import { Form, Formik } from "formik";
import { Facebook, Pinterest } from "@material-ui/icons";
import {connect} from "react-redux";

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "block",
    color: "#fff",
    textTransform: "uppercase",
    textDecorationColor: "#fff",
    padding: 0,
    marginBottom: 8,
    textDecoration: "underline",
  },
  link: {
    color: "grey",
    fontSize: 10,
    textTransform: "uppercase",
    textDecoration: "none",
  },
  underline: {
    color: "white",
  },
}));


const url = `${API_URL}/subscribers`;
const success = "Thank you for joining Freddie";

function Footer({context}) {
  const classes = useStyles();

  const { errorMessage, successMessage, busy, handleSubmit } = useSubmit(
    url,
    success
  );

  useAlert(successMessage, "success");
  useAlert(errorMessage, "error");

  const links = useMenuLinks(context, "footer")

  return (
    <Box mt={4} style={{ backgroundColor: "#3A5273" }} color={"#fff"}>
      <Divider />
      <Container>
        <Box pt={8} pb={2}>
          <Grid container justify={"space-between"}>
            <Grid item>
              <Typography
                style={{ textDecoration: "none" }}
                className={classes.listItem}
              >
                FREDDIE
              </Typography>
              <ul style={{ paddingLeft: 0 }}>
                {links.map((link, index) => (
                  <li key={index} className={classes.listItem}>
                    <Button
                      component={Link}
                      to={link.url}
                      className={classes.listItem}
                    >
                      {link.name}
                    </Button>
                  </li>
                ))}
              </ul>
              <IconButton
                component={MaterialLink}
                target={"_blank"}
                href={"https://www.facebook.com/joinfreddie/"}
              >
                <Facebook style={{ color: "white" }} />
              </IconButton>
              <IconButton
                component={MaterialLink}
                target={"_blank"}
                href={"https://www.instagram.com/joinfreddie"}
              >
                <InstagramIcon style={{ color: "white" }} />
              </IconButton>
              <IconButton
                component={MaterialLink}
                target={"_blank"}
                href={"https://www.pinterest.com/JoinFreddie/"}
              >
                <Pinterest style={{ color: "white" }} />
              </IconButton>
            </Grid>
            <Grid item>
              <Formik initialValues={{}} onSubmit={(values, {setFieldValue}) => {
                handleSubmit(values, false, () => setFieldValue("email", ""))
              }}>
                <Form id={"newsletter"}>
                  <Grid container direction={"column"} spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant={"h3"} style={{fontSize:20}}>
                        SUBSCRIBE TO OUR NEWSLETTER
                      </Typography>
                    </Grid>
                    <Grid item className={"whiteInputs"}>
                      <FormikTextField
                        variant={"outlined"}
                        margin="dense"
                        placeholder={"Enter email"}
                        fullWidth
                        required={true}
                        type={"text"}
                        id={"newsletterEmail"}
                        name={"email"}
                        // label={"EMAIL ADDRESS"}
                        disabled={busy}
                        style={{ backgroundColor: "#fff",fontSize:13}}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        type={"submit"}
                        color={"primary"}
                        disabled={busy}
                        size={"large"}
                        variant={"contained"}
                        style={{ width: "100%" ,height:'35px',fontSize:14}}
                      >
                        SUBMIT
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </Formik>
            </Grid>
          </Grid>
          <Box my={4} style={{ display: "flex", justifyContent: "center" }}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator="|"
              style={{ color: "grey", fontSize: 10 }}
            >
              <Typography
                component={Link}
                to={"/terms-and-conditions"}
                color="textPrimary"
                className={classes.link}
              >
                TERMS & CONDITIONS
              </Typography>
              <Typography
                component={Link}
                to={"/privacy-policy"}
                color="textPrimary"
                className={classes.link}
              >
                DO NOT SELL MY PERSONAL INFORMATION
              </Typography>
              <Typography
                component={Link}
                to={"/privacy-policy"}
                color="textPrimary"
                className={classes.link}
              >
                PRIVACY POLICY
              </Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      PageBuilder: state.PageBuilder
    }
  };
};
export default connect(mapStateToProps, {})(Footer)

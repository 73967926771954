export const EntityType = {
  User: "User",
  RegisterList: "RegisterList",
  Account: "Account",
  Designer: "Designer",
  Article: "Article",
  Glossary: "Glossary",
  PageBuilder: "PageBuilder",
  Menu: "Menu",
  Page: "Page",
};

export const designerStepOne = [
    {
        id: "firstName",
        label: "First Name",
        type: "text",
        required: true,
        col: 4,
    },
    {
        id: "lastName",
        label: "Last Name",
        type: "text",
        required: true,
        col: 4,
    },
    {
        id: "email",
        label: "Email",
        type: "email",
        required: true,
        col: 4,
    },
    {
        id: "password",
        label: "Password",
        type: "password",
        showRequirements: true,
        required: true,
        col: 4,
    },
    {
        id: "since",
        label: "Practicing Professional Since (e.g. 1982)",
        type: "number",
        required: false,
        col: 4,
    },
    {
        id: "professionalCategoryId",
        label: "Professional Category",
        type: "select",
        options: "professionalCategories",
        required: true,
        col: 4,
    },
    {
        id: "instagram",
        label: "Instagram",
        placeholder: "ex: joinfreddie",
        type: "text",
        required: false,
        col: 4,
    },
    {
        id: "bio",
        label: "Bio of Experience, Featured on your Freddie Member page",
        type: "textarea",
        required: false,
        col: 8,
    },
];

export const designerStepTwo = [
    {
        id: "businessName",
        label: "Business Name",
        type: "text",
        required: true,
        col: 4,
    },
    {
        id: "businessEstablished",
        label: "Year Established",
        placeholder: "Enter year of establishment",
        type: "number",
        required: false,
        col: 4,
    },
    {
        id: "businessWebSite",
        label: "Business Website",
        type: "text",
        required: false,
        col: 4,
    },
    {
        id: "businessTypeId",
        label: "Business Type",
        type: "select",
        required: false,
        options: "businessTypes",
        col: 4,
    },
    {
        id: "employeesRangeId",
        label: "Business Employees",
        type: "select",
        required: false,
        options: "employeeRanges",
        col: 4,
    },
    {
        id: "phoneNumber",
        label: "Phone Number",
        type: "phoneNumber",
        required: false,
        col: 4,
    },
    {
        id: "streetAddress",
        label: "Street Address",
        type: "placesAutocomplete",
        required: true,
        col: 8,
    },
    {
        id: "streetAddress2",
        label: "Street Address 2",
        type: "text",
        required: false,
        col: 4,
    },
    {
        id: "city",
        label: "City",
        type: "text",
        required: true,
        col: 4,
    },
    {
        id: "stateId",
        label: "State",
        type: "select",
        required: true,
        options: "states",
        col: 4,
    },
    {
        id: "zipCode",
        label: "Zip",
        type: "text",
        required: true,
        col: 4,
    },
];

export const designerStepFour = [
    {
        id: "associations",
        label: "Existing Professional Associations or Accreditations",
        type: "text",
        required: false,
        col: 6,
    },
    {
        id: "consultationId",
        label: "Designer Consultations",
        type: "select",
        required: true,
        options: "consultations",
        col: 6,
    },
    {
        id: "minimumProjectSpendId",
        label: "What is the minimum spend on a project?",
        type: "select",
        required: false,
        options: "minimumProjectSpends",
        col: 6,
    },
    {
        id: "averageFurnishingsSpendId",
        label: "Annual average spend on furnishings?",
        type: "select",
        required: false,
        options: "averageFurnishingsSpends",
        col: 6,
    },
    {
        id: "averageFabricWallpaperSpendId",
        label: "Average spend per project on fabric and wallpaper?",
        type: "select",
        required: false,
        options: "averageFabricWallpaperSpends",
        col: 6,
    },
    {
        id: "averageCarpetsRugsSpendId",
        label: "Average spend per project on carpets and rugs?",
        type: "select",
        required: false,
        options: "averageCarpetsRugsSpends",
        col: 6,
    },
];

export const enthusiastForm = [
    {
        id: "firstName",
        label: "First Name",
        type: "text",
        required: true,
        col: 6,
    },
    {
        id: "lastName",
        label: "Last Name",
        type: "text",
        required: true,
        col: 6,
    },
    {
        id: "email",
        label: "Email",
        type: "email",
        required: true,
        col: 12,
    },
    {
        id: "password",
        label: "Password",
        type: "password",
        showRequirements: true,
        required: true,
        col: 12,
    },
    {
        id: "optInNewsletter",
        label: "Opt-In to Freddie Newsletter",
        type: "checkbox",
        required: false,
        col: 12,
    },
];
export const changePasswordForm = [
    {
        id: "oldPassword",
        label: "Old Password",
        type: "password",
        required: true,
        col: 12,
    },
    {
        id: "newPassword",
        label: "New Password",
        type: "password",
        showRequirements: true,
        required: true,
        col: 12,
    },
];
export const profileForm = [
    {
        id: "firstName",
        label: "First Name",
        type: "text",
        required: true,
        col: 12,
    },
    {
        id: "lastName",
        label: "Last Name",
        type: "text",
        required: true,
        col: 12,
    },
];
export const loginForm = [
    {
        id: "email",
        label: "Email",
        type: "email",
        required: true,
        col: 12,
    },
    {
        id: "password",
        label: "Password",
        type: "password",
        required: true,
        col: 12,
    },
    {
        id: "rememberMe",
        label: "Remember me",
        type: "checkbox",
        required: false,
        col: 12,
    },
];

export const editDesignerForm = [
    {
        id: "firstName",
        label: "First Name",
        type: "text",
        required: true,
        col: 6,
    },
    {
        id: "lastName",
        label: "Last Name",
        type: "text",
        required: true,
        col: 6,
    },
    {
        id: "instagram",
        label: "@Instagram",
        type: "text",
        placeholder: "ex: @joinfreddie",
        required: false,
        col: 12,
    },
    {
        id: "professionalCategoryId",
        label: "Professional Category",
        type: "select",
        options: "professionalCategories",
        required: true,
        col: 12,
    },
    {
        id: "bio",
        label: "Bio of Experience, Featured on your Freddie Member page",
        type: "textarea",
        required: false,
        col: 12,
    },
    {
        id: "since",
        label: "Practicing Professional Since (e.g. 1982)",
        type: "number",
        required: false,
        col: 12,
    },
    {
        id: "businessName",
        label: "Business Name",
        type: "text",
        required: true,
        col: 12,
    },
    {
        id: "businessWebSite",
        label: "Business Website",
        type: "text",
        required: false,
        col: 12,
    },
    {
        id: "businessTypeId",
        label: "Business Type",
        type: "select",
        required: false,
        options: "businessTypes",
        col: 12,
    },
    {
        id: "employeesRangeId",
        label: "Business Employees",
        type: "select",
        required: false,
        options: "employeeRanges",
        col: 12,
    },
    {
        id: "phoneNumber",
        label: "Phone Number",
        type: "phoneNumber",
        required: false,
        col: 12,
    },
    {
        id: "businessEstablished",
        label: "Year Established",
        placeholder: "Enter year of establishment",
        type: "number",
        required: false,
        col: 12,
    },
    {
        id: "streetAddress",
        label: "Street Address",
        type: "text",
        required: true,
        col: 12,
    },
    {
        id: "streetAddress2",
        label: "Street Address 2",
        type: "text",
        required: false,
        col: 12,
    },
    {
        id: "city",
        label: "City",
        type: "text",
        required: true,
        col: 4,
    },
    {
        id: "stateId",
        label: "State",
        type: "select",
        required: true,
        options: "states",
        col: 4,
    },
    {
        id: "zipCode",
        label: "Zip",
        type: "text",
        required: true,
        col: 4,
    },
    {
        id: "associations",
        label: "Existing Professional Associations or Accreditations",
        type: "text",
        required: false,
        col: 12,
    },
    {
        id: "consultationId",
        label: "Designer Consultations",
        type: "select",
        required: true,
        options: "consultations",
        col: 12,
    },
    {
        id: "minimumProjectSpendId",
        label: "What is the minimum spend you need to take on a project?",
        type: "select",
        required: false,
        options: "minimumProjectSpends",
        col: 12,
    },
    {
        id: "averageFurnishingsSpendId",
        label: "Annual average spend on furnishings?",
        type: "select",
        required: false,
        options: "averageFurnishingsSpends",
        col: 12,
    },
    {
        id: "averageFabricWallpaperSpendId",
        label: "Average spend per project on fabric and wallpaper?",
        type: "select",
        required: false,
        options: "averageFabricWallpaperSpends",
        col: 12,
    },
    {
        id: "averageCarpetsRugsSpendId",
        label: "Average spend per project on carpets and rugs?",
        type: "select",
        required: false,
        options: "averageCarpetsRugsSpends",
        col: 12,
    },
    {
        id: "optInNewsletter",
        label: "Opt-In to Freddie Newsletter",
        type: "checkbox",
        required: false,
        col: 12,
    },
    {
        id: "optInDirectMail",
        label:
            "Opt-In for direct mail and receive a complimentary subscription to Frederic",
        type: "checkbox",
        required: true,
        col: 12,
    },
];

export const forgotPassword = [
    {
        id: "email",
        label: "Email",
        type: "email",
        required: true,
        col: 12,
    },
];

export const resetPassword = [
    {
        id: "password",
        label: "Password",
        type: "password",
        showRequirements: true,
        required: true,
        col: 12,
    },
];

export const pressAndPartnershipForm = [
    {
        id: "firstName",
        label: "First Name",
        type: "text",
        required: true,
        col: 6,
    },
    {
        id: "lastName",
        label: "Last Name",
        type: "text",
        required: true,
        col: 6,
    },
    {
        id: "email",
        label: "Email",
        type: "email",
        required: true,
        col: 12,
    },
    {
        id: "inquiryType",
        label: "Inquiry Type",
        type: "select",
        required: true,
        col: 12,
        options: [
            {
                id: "Press",
                name: "Press"
            },
            {
                id: "Partnership",
                name: "Partnership"
            },
        ]
    },
    {
        id: "message",
        label: "Message",
        type: "textarea",
        required: true,
        col: 12,
    },
];

export const contactForm = [
    {
        id: "firstName",
        label: "First Name",
        type: "text",
        required: true,
        col: 6,
    },
    {
        id: "lastName",
        label: "Last Name",
        type: "text",
        required: true,
        col: 6,
    },
    {
        id: "inquiryType",
        label: "Inquiry Type",
        type: "select",
        required: true,
        col: 12,
        options: [
            {
                id: "General",
                name: "General"
            },
            {
                id: "Press",
                name: "Press"
            },
            {
                id: "Partnership",
                name: "Partnership"
            },
        ]
    },
    {
        id: "email",
        label: "Email",
        type: "email",
        required: true,
        col: 12,
    },
    {
        id: "message",
        label: "Message",
        type: "textarea",
        required: true,
        col: 12,
    },
];

export const subscribeForm = [
    {
        id: "firstName",
        label: "First Name",
        type: "text",
        required: true,
        col: 6,
    },
    {
        id: "lastName",
        label: "Last Name",
        type: "text",
        required: true,
        col: 6,
    },
    {
        id: "email",
        label: "Email",
        type: "email",
        required: true,
        col: 12,
    },
];


export const getInTouchForm = [
    {
        id: "firstName",
        label: "First Name",
        type: "text",
        required: true,
        col: 6,
    },
    {
        id: "lastName",
        label: "Last Name",
        type: "text",
        required: true,
        col: 6,
    },
    {
        id: "email",
        label: "Email",
        type: "email",
        required: true,
        col: 12,
    },
    {
        id: "subject",
        label: "Subject",
        type: "text",
        required: true,
        col: 12,
    },
    {
        id: "body",
        label: "Message",
        type: "textarea",
        required: true,
        col: 12,
    },
];

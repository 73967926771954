import BaseDialog from "./Core/BaseDialog";
import FormContainer from "./FormContainer";
import {pressAndPartnershipForm} from "../constants/forms";
import { Grid } from "@material-ui/core";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { API_URL, CAPTCHA_KEY } from "../config";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useSubmit } from "../hooks";
import { useSnackbar } from "notistack";
import Box from "@material-ui/core/Box";

const contactUrl = `${API_URL}/contact`;
const contactSuccess = "Your Message has been sent";

function ContactDialog(props) {
  const { open, handleClose, entity } = props;
  const [captcha, setCaptcha] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const {
    errorMessage: errorContact,
    busy: busyContact,
    handleSubmit: submitContact,
  } = useSubmit(contactUrl, contactSuccess);

  useEffect(() => {
    if (entity) {
      setInitialValues(entity);
    }
  }, [entity]);

  const handleContactSuccess = () => {
    enqueueSnackbar(contactSuccess, { variant: "success" });
    handleClose();
  };

  return (
    <BaseDialog
      open={open}
      title={"Contact"}
      errorMessage={errorContact}
      onClose={handleClose}
      showProgress
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          if (!captcha) return alert("Please complete hCaptcha verification");
          submitContact(
            { ...values, passcode: captcha.token },
            null,
            handleContactSuccess
          );
        }}
      >
        {(formik) => (
          <FormContainer
            submitLabel={"Send"}
            submitVariant={"contained"}
            form={pressAndPartnershipForm
              .filter((x) => x.type)
              .map((x) => ({ ...x, variant: "standard" }))}
            busy={busyContact}
            submitDirection={"flex-start"}
          >
            <Grid item xs={12}>
              <HCaptcha
                sitekey={CAPTCHA_KEY}
                onVerify={(token, ekey) => setCaptcha({ token, ekey })}
              />
            </Grid>
          </FormContainer>
        )}
      </Formik>
      <Box mt={2}/>
    </BaseDialog>
  );
}

export default ContactDialog;

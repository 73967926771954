import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import BaseContent from "../../components/Core/BaseContent";
import * as forms from "../../constants/forms";
import { Prompt } from "react-router";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import { getFormView } from "../../components/form-views";
import { motion } from "framer-motion";
import FormikCheckbox from "../../components/Core/FormikCheckbox";
import { connect } from "react-redux";
import { fetchData, mapFormOptions, postData } from "../../utils";
import { API_URL } from "../../config";
import { useAppTheme } from "../../constants/theme";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import BaseDialog from "../../components/Core/BaseDialog";
import DropDialog from "../../components/DropDialog";
import { formatBytes } from "reactcoregk/utils";
import DesignerPreviewCard from "./DesignerPreviewCard";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { passwordValidation, slideVariants } from "../../constants/constants";
import * as colors from "../../constants/colors";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const customOverrides = () => {
  return {
    MuiFormLabel: {
      root: {
        color: "#fff",
        "&$focused": {
          color: "#fff",
        },
        "&:hover": {
          color: "lightgrey",
        },
      },
    },
    MuiCheckbox: {
      root: {
        icon: {
          fill: "#fff",
        },
        color: "#fff",
        fill: "#fff",
        "&$checked": {
          color: "#fff",
          fill: "#fff",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        margin: 0,
        "&$error": {
          backgroundColor: colors.errorColor.main,
          color: "white",
          margin: 0,
          padding: "2px 8px",
        },
      },
      error: {},
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        color: "#fff",
        "&$focused $notchedOutline": {
          borderColor: "#fff",
          borderWidth: 1,
        },
        "&:hover $notchedOutline": {
          borderColor: "lightgrey",
          borderWidth: 1,
        },
      },
      notchedOutline: {
        borderColor: "#fff",
      },
    },
    MuiSelect: {
      icon: {
        color: "#fff",
      },
      notchedOutline: {
        borderColor: "#fff",
      },
    },
  };
};

const useStyles = makeStyles({
  checkboxContainer: {
    maxHeight: 300,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
  step: {
    flex: 1,
    height: 4,
  },
  chooseCard: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "space-between",
  },
  chooseButton: {
    // marginTop: "auto",
    // minWidth: 200,
  },
  portfolioRow: {
    display: "flex",
    alignItems: "center",
    padding: 8,
    marginBottom: 8,
  },
  portfolioImage: {
    width: 100,
    height: 80,
    objectFit: "contain",
    marginRight: 8,
  },
  cardTitle: {
    textAlign: "center",
    fontWeight: "bold",
    // marginBottom: 16,
  },
  cardSubTitle: {
    textAlign: "center",
    fontSize: 14,
    // marginBottom: 8,
  },
});

function PortfolioImageRow({ image, index, remove, reposition }) {
  const classes = useStyles();

  return (
    <Card variant={"outlined"} className={classes.portfolioRow}>
      <Box display={"flex"} flexDirection={"column"} mr={1}>
        <IconButton size={"small"} onClick={() => reposition(index, -1)}>
          <ExpandLess />
        </IconButton>
        <Box mt={1} />
        <IconButton size={"small"} onClick={() => reposition(index, +1)}>
          <ExpandMore />
        </IconButton>
      </Box>
      <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
        <img
            src={image.preview}
            className={classes.portfolioImage}
            alt={"portfolio"}
        />
        <Typography variant={"body1"}>{image.name}</Typography>
      </Box>
      <IconButton style={{ marginLeft: "auto" }} onClick={() => remove(index)}>
        <RemoveCircleOutlineIcon />
      </IconButton>
    </Card>
  );
}

function Step3({
  featureImage,
  openFeatureCrop,
  openProfileCrop,
  profileImage,
  portfolioImages,
  removePortfolioImage,
  openPortfolioCrop,
  repositionPortfolioImage,
  tempUser,
}) {
  const classes = useStyles();
  return (
    <>
      <Grid item md={3} sm={6} xs={12}>
        <DesignerPreviewCard
          featureImage={featureImage}
          profileImage={profileImage}
          designer={tempUser}
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12} style={{ display: "flex" }}>
        <Grid container direction={"column"} spacing={2} style={{ flex: 1 }}>
          <Grid item style={{ flex: 1, display: "flex" }}>
            <Card style={{ flex: 1, display: "flex" }}>
              <CardContent className={classes.chooseCard}>
                <Typography variant={"h5"} className={classes.cardTitle}>
                  Header Image
                </Typography>
                <Box
                  flex={1}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography variant={"h5"} className={classes.cardSubTitle}>
                    135 x 135px min
                  </Typography>
                </Box>
                <Button
                  className={classes.chooseButton}
                  variant={"contained"}
                  color={"secondary"}
                  onClick={openFeatureCrop}
                >
                  Choose File
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item style={{ flex: 1, display: "flex" }}>
            <Card style={{ flex: 1, display: "flex" }}>
              <CardContent className={classes.chooseCard}>
                <Typography variant={"h5"} className={classes.cardTitle}>
                  Profile Image
                </Typography>
                <Box
                  flex={1}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography variant={"h5"} className={classes.cardSubTitle}>
                    1830 x 750px min
                  </Typography>
                </Box>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  className={classes.chooseButton}
                  onClick={openProfileCrop}
                >
                  Choose File
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sm={12} xs={12} style={{ display: "flex" }}>
        <Card style={{ flex: 1, display: "flex" }}>
          <CardContent className={classes.chooseCard}>
            <Typography variant={"h5"} className={classes.cardTitle}>
              Portfolio Images
            </Typography>
            {portfolioImages.length > 0 && (
              <Card
                variant={"outlined"}
                style={{ padding: 8, maxHeight: 292, overflow: "auto" }}
              >
                {portfolioImages.map((image, index) => (
                  <PortfolioImageRow
                    image={image}
                    index={index}
                    key={index}
                    reposition={repositionPortfolioImage}
                    remove={removePortfolioImage}
                  />
                ))}
              </Card>
            )}
            <Button
              style={{ marginTop: "auto" }}
              variant={"contained"}
              color={"secondary"}
              onClick={openPortfolioCrop}
            >
              Choose File
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export function Step4({ context }) {
  const lists = context.RegisterList.get.result;
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Typography style={{ marginBottom: 8 }}>
            <b>Speciality*</b>
          </Typography>
          <Box className={classes.checkboxContainer}>
            {lists.specialties?.map((opt) => (
              <FormikCheckbox
                label={opt.name}
                id={`specialties_${opt.id}`}
                name={`specialties_${opt.id}`}
              />
            ))}
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography style={{ marginBottom: 8 }}>
            <b>What is your style design aestetic?*</b>
          </Typography>
          <Box className={classes.checkboxContainer}>
            {lists.designStyles?.map((opt) => (
              <FormikCheckbox
                label={opt.name}
                id={`designStyles_${opt.id}`}
                name={`designStyles_${opt.id}`}
              />
            ))}
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography style={{ marginBottom: 8 }}>
            <b>Can advise on</b>
          </Typography>
          <Box className={classes.checkboxContainer}>
            {lists.adviceOffers?.map((opt) => (
              <FormikCheckbox
                label={opt.name}
                id={`adviceOffers_${opt.id}`}
                name={`adviceOffers_${opt.id}`}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

const Wizard = ({
  children,
  initialValues,
  onSubmit,
  stepNumber,
  setStepNumber,
  busy,
}) => {
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);
  const theme = useAppTheme(customOverrides());
  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values, bag) => {
    if (isLastStep) {
      return onSubmit(values, bag);
    }
    if (step.props.onSubmit) {
      const res = await step.props.onSubmit(values, bag);
      if (res === true) next(values);
      else return false;
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  const renderStepper = () => (
    <>
      <p>
        Step {stepNumber + 1} of {totalSteps}
      </p>
      <div style={{ display: "flex", width: "100%" }}>
        {[...Array(5)].map((x, i) => (
          <div
            style={{
              flex: 1,
              height: 8,
              marginRight: 8,
              backgroundColor: i <= stepNumber ? "#EBCAC0" : "white",
            }}
          />
        ))}
      </div>
    </>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stepNumber]);

  return (
    <Formik
      initialValues={snapshot}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {(formik) => (
        <Form style={{ width: "100%" }}>
          <ThemeProvider theme={theme}>
            <Box style={{ minHeight: 350 }}>{step}</Box>
            <Box mt={1} />
            <Grid container alignItems={"flex-end"} spacing={2}>
              <Grid item md={8} xs={12}>
                {renderStepper()}
              </Grid>
              <Grid item md={4} xs={12}>
                <Grid container spacing={2} justify={"flex-end"}>
                  {stepNumber > 0 && (
                    <Grid item>
                      <Button
                        variant={"outlined"}
                        color={"primary"}
                        style={{
                          minWidth: 150,
                          color: "white",
                          borderColor: "white",
                        }}
                        size={"large"}
                        onClick={() => previous(formik.values)}
                        type="button"
                      >
                        BACK
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      style={{ minWidth: 150 }}
                      size={"large"}
                      disabled={formik.isSubmitting || busy}
                      type="submit"
                    >
                      {stepNumber === 4 ? "Join now" : "Next"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Form>
      )}
    </Formik>
  );
};

const WizardStep = ({ children }) => children;

const renderTitle = (step) => {
  switch (step) {
    case 0:
      return "Account Information";
    case 1:
      return "Business Information";
    case 2:
      return "Images";
    case 3:
      return "Style";
    case 4:
      return "Project Details";
    default:
      return null;
  }
};

const subtitle = () => (
  <>
    as a <b>Designer</b>
  </>
);

export function buildUserDetails(values) {
  const payload = {};
  const specialties = [];
  const designStyles = [];
  const adviceOffers = [];

  for (let key in values) {
    const part = key.split("_")[0];
    const id = key.split("_")[1];
    const checked = values[key] === true;
    if (part === "specialties" && id && checked) {
      specialties.push(id);
    } else if (part === "designStyles" && id && checked) {
      designStyles.push(id);
    } else if (part === "adviceOffers" && id && checked) {
      adviceOffers.push(id);
    } else if (part === "phoneNumber") {
      const phoneNumber = values[key]
      const type = typeof phoneNumber;
      if (type === "string") {
        payload[key] = phoneNumber.replace(/[^\d]/g, '')
      } else if (type === "number") {
        payload[key] = phoneNumber;
      } else payload[key] = null
    } else {
      payload[key] = values[key];
    }
  }
  payload.specialtyIds = specialties;
  payload.designStyleIds = designStyles;
  payload.adviceOfferIds = adviceOffers;

  return payload;
}

function JoinUsDesigner(props) {

  const [canExit, setCanExit] = useState(false);
  const [step, setStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [busy, setBusy] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [partner, setPartner] = useState(null);
  const [openFeatureCrop, setOpenFeatureCrop] = useState(false);
  const [openProfileCrop, setOpenProfileCrop] = useState(false);
  const [openPortfolioCrop, setOpenPortfolioCrop] = useState(false);
  const [tempUser, setTempUser] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [featureImage, setFeatureImage] = useState(null);
  const [portfolioImages, setPortfolioImages] = useState([]);
  const tempEmail = useRef();
  const emailExist = useRef(false);

  const [disableFields,setDisableFields] = useState({city:false,stateId:false,zipCode:false})

  const { context } = props;

  const acceptFeatureImage = useCallback((image) => {
    const file = new File([image], "feature.png", { type: "image/jpeg" });
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    });
    setFeatureImage(file);
  }, []);

  const acceptProfileImage = useCallback((image) => {
    const file = new File([image], "profile.png", { type: "image/jpeg" });
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    });
    setProfileImage(file);
  }, []);

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    props.history.push("/");
  };

  const acceptPortfolioImage = (image) => {
    const file = new File([image], "profile.png", { type: "image/jpeg" });
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    });
    setPortfolioImages((prevState) => [...prevState, file]);
  };

  const handleRemovePortfolioImage = (index) => {
    setPortfolioImages((prevState) => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1),
    ]);
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const handleRepositionPortfolioImage = (index, direction) => {
    setPortfolioImages((prevState) => {
      return reorder(prevState, index, index + direction);
    });
  };

  const stepForms = useMemo(() => {
    return [
      mapFormOptions(forms.designerStepOne, context.RegisterList),
      mapFormOptions(forms.designerStepTwo, context.RegisterList),
      null, // images
      null, // checkboxes
      mapFormOptions(forms.designerStepFour, context.RegisterList),
    ];
  }, [context.RegisterList]);

  const handleFinalSubmit = async (values) => {
    const userDetails = buildUserDetails(values);
    setErrorMessage(null);

    const formData = new FormData();
    setBusy(true);
    if (profileImage) {
      formData.append("profile", profileImage);
    }
    if (featureImage) {
      formData.append("feature", featureImage);
    }
    portfolioImages.forEach((file) => {
      formData.append("portfolio", file);
    });
    formData.append(
      "user",
      new Blob([JSON.stringify(userDetails)], {
        type: "application/json",
      })
    );
    const url = API_URL + "/account/register/designer";
    try {
      await postData(url, formData);
      setCanExit(true);
      setBusy(false);
      setPartner(`${userDetails.firstName} ${userDetails.lastName}`);
      setOpenSuccess(true);
    } catch (ex) {
      setErrorMessage(ex.message);
      setBusy(false);
    }
  };

  function renderImagePicker() {
    return (
      <Step3
        tempUser={tempUser}
        profileImage={profileImage}
        featureImage={featureImage}
        openProfileCrop={() => setOpenProfileCrop(true)}
        openFeatureCrop={() => setOpenFeatureCrop(true)}
        openPortfolioCrop={() => setOpenPortfolioCrop(true)}
        portfolioImages={portfolioImages}
        removePortfolioImage={handleRemovePortfolioImage}
        repositionPortfolioImage={handleRepositionPortfolioImage}
      />
    );
  }

  function renderCheckboxes() {
    return <Step4 context={context} />;
  }

  function renderForm(form) {
    return form.map((input, index) => {
      return (
        <Grid
          item
          md={input.col || 12}
          xs={12}
          style={{ position: "relative" }}
        >
          {getFormView(input, index, busy,null,disableFields,setDisableFields)}
        </Grid>
      );
    });
  }


  const validationSchema = useMemo(() => {
    switch (step) {
      case 0:
        return Yup.object({
          firstName: Yup.string().required("This field is required").matches(/^[^-\s]/,"Spaces are not allowed at the beginning"),
          lastName: Yup.string().required("This field is required").matches(/^[^-\s]/,"Spaces are not allowed at the beginning"),
          instagram: Yup.string()
              .matches(/^(?!@)/, "Enter account name without @").matches(/^(?!@)+\S*$/, "Spaces are not allowed"),
          since: Yup.string()
              .matches(/^[0-9]+$/, "Must be only digits")
              .min(4, "Date should consist of 4 digits")
              .max(4, "Date should consist of 4 digits"),
          email: Yup.string()
            .email("Invalid email")
            .required("This field is required")
            .test(
              "checkEmail",
              "The email you entered already exist",
              (value) => {
                return new Promise((resolve, reject) => {
                  if (
                    (!tempEmail.current || value !== tempEmail.current) &&
                    value !== undefined
                  ) {
                    tempEmail.current = value;
                    fetchData(
                      `${API_URL}/account/email-availability?email=${value}`
                    )
                      .then(() => {
                        emailExist.current = false;
                        resolve(true);
                      })
                      .catch(() => {
                        emailExist.current = true;
                        resolve(false);
                      });
                  } else {
                    resolve(!emailExist.current);
                  }
                });
              }
            ).trim(),
          password: passwordValidation,
          professionalCategoryId: Yup.string().required(
            "This field is required"
          ),
        });
      case 1:
        return Yup.object({
          businessName: Yup.string().required("This field is required").matches(/^[^-\s]/,"Spaces are not allowed at the beginning"),
          city: Yup.string().required("This field is required").matches(/^[^-\s]/,"Spaces are not allowed at the beginning"),
          streetAddress: Yup.string().required("This field is required").matches(/^[^-\s]/,"Spaces are not allowed at the beginning"),
          streetAddress2: Yup.string().matches(/^[^-\s]/,"Spaces are not allowed at the beginning"),
          zipCode: Yup.string().required("This field is required").matches(/^[^-\s]/,"Spaces are not allowed at the beginning"),
          stateId: Yup.string().required("This field is required"),
          phoneNumber: Yup.string().transform(value => value?.replace(/[^\d]/g, ''))
              .min(10, "Phone Number must contain at least 10 characters"),
          businessWebSite: Yup.string().matches(
            /^(?:https?:\/\/(?:www\.)?|www\.)[a-z0-9]+(?:[-.][a-z0-9]+)*\.[a-z]{2,5}(?::[0-9]{1,5})?(?:\/\S*)?$/,
            "Enter a valid url"
          ),
        });
      case 4:
        return Yup.object({
          consultationId: Yup.string().required("This field is required"),
        });
      default:
        return Yup.object({});
    }
  }, [step]);

  const renderFormContent = (form, index) => {
    switch (index) {
      case 2:
        return renderImagePicker();
      case 3:
        return renderCheckboxes();
      default:
        return renderForm(form);
    }
  };

  const submitStep = (values) => {
    if (step === 3) {
      const userDetails = buildUserDetails(values);
      if (!userDetails.specialtyIds[0]) {
        return alert("You need to select at least 1 speciality");
      } else if (!userDetails.designStyleIds[0]) {
        return alert("You need to select at least 1 design style");
      }
    } else if (step === 0 || step === 1) {
      const states = context.RegisterList.get.result?.states || [];
      const state = states.find((x) => x.id === values.stateId)?.name;
      setTempUser((prevState) => ({
        ...prevState,
        ...values,
        state,
      }));
    }

    return true;
  };

  function renderOptIns() {
    return (
      <Grid item container direction={"column"}>
        <FormikCheckbox
          label={"Opt-In to Freddie Newsletter"}
          id={"optInNewsletter"}
          name={"optInNewsletter"}
          disabled={busy}
        />
        <FormikCheckbox
          label={
            "Opt-In for direct mail and receive a complimentary subscription to Frederic"
          }
          id={"optInDirectMail"}
          name={"optInDirectMail"}
          disabled={busy}
        />
        <Typography>
          By submitting this form, you agree to our{" "}
          <Link
            style={{ color: "inherit", textDecoration: "underline" }}
            to={"/terms-and-conditions"}
            target={"_blank"}
          >
            Terms & Conditions
          </Link>{" "}
          and{" "}
          <Link
            style={{ color: "inherit", textDecoration: "underline" }}
            to={"/privacy-policy"}
            target={"_blank"}
          >
            Privacy Policy
          </Link>
          .
        </Typography>
      </Grid>
    );
  }

  return (
    <BaseContent
      title={"Join Us"}
      subtitle={subtitle()}
      errorMessage={errorMessage}
    >
      <Prompt
        when={!canExit}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Container
        maxWidth={"lg"}
        className={"darkInputs"}
        style={{
          backgroundColor: "#3A5273",
          color: "#fff",
          padding: 48,
        }}
      >
        <Box pb={4}>
          <Typography
            variant={"h2"}
            style={{ textAlign: "center", fontSize: 32, fontWeight: "bold" }}
          >
            {renderTitle(step)}
          </Typography>
        </Box>
        <Grid container direction={"row"} spacing={2} justify={"center"}>
          <Wizard
            busy={busy}
            stepNumber={step}
            setStepNumber={setStep}
            initialValues={{
              email: "",
              firstName: "",
              lastName: "",
              optInNewsletter: true,
              optInDirectMail: true,
              zipCode :'',
              stateId: '',
              city:'',
              streetAddress: '',
            }}
            onSubmit={handleFinalSubmit}
          >
            {stepForms.map((form, index) => (
              <WizardStep
                onSubmit={submitStep}
                validationSchema={validationSchema}
              >
                <motion.div
                  style={{ flex: 1 }}
                  className="wrapper"
                  variants={slideVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <Grid container spacing={2}>
                    {renderFormContent(form, index)}
                    {index === 4 && renderOptIns()}
                  </Grid>
                </motion.div>
              </WizardStep>
            ))}
          </Wizard>
        </Grid>
      </Container>
      <BaseDialog
        onClose={handleCloseSuccess}
        open={openSuccess}
        title={""}
        maxWidth={"sm"}
        fullWidth
      >
        <Typography
          variant={"h4"}
          style={{ textAlign: "center", fontSize: 24 }}
        >
          SIGN UP SUCCESS
        </Typography>
        <Box mt={1} />
        <Typography
          variant={"body1"}
          style={{ textAlign: "center", margin: "auto" }}
        >
          Thank you <b>{partner}</b> for you interest in becoming a Freddie
          design partner! Your account is under review and we will be in contact
          shortly.
        </Typography>
        <Box mb={6} />
      </BaseDialog>
      <DropDialog
        open={openFeatureCrop}
        handleClose={() => setOpenFeatureCrop(false)}
        setImage={acceptFeatureImage}
        aspect={16 / 9}
        resolution={[1280, 720]}
        title={"Crop Header Image"}
      />
      <DropDialog
        open={openProfileCrop}
        handleClose={() => setOpenProfileCrop(false)}
        setImage={acceptProfileImage}
        aspect={1}
        resolution={[480, 480]}
        cropShape={"round"}
        title={"Crop Profile Picture"}
      />
      <DropDialog
        open={openPortfolioCrop}
        handleClose={() => setOpenPortfolioCrop(false)}
        setImage={acceptPortfolioImage}
        autoAspect
        title={"Crop Portfolio Image"}
      />
    </BaseContent>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      RegisterList: state.RegisterList,
    },
  };
};
export default connect(mapStateToProps, {})(JoinUsDesigner);

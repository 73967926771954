import { Button, Grid } from "@material-ui/core";
import { getFormView } from "./form-views";
import { Form } from "formik";

function FormContainer(props) {
  const { form, busy, submitLabel = "Submit", submitVariant = "outlined", submitDirection = "flex-end" } = props;
  return (
    <Form>
      <Grid
        container
        direction={"row"}
        spacing={2}
        justify={"center"}
        alignItems={"center"}
      >
        {form.map((input, index) => (
          <Grid item md={input.col || 12} xs={12}>
            {getFormView(input, index, busy)}
          </Grid>
        ))}
        {props.children}
        <Grid item container justify={submitDirection}>
          <Button
            variant={submitVariant}
            color={"primary"}
            disabled={busy}
            style={{ minWidth: 150 }}
            type={"submit"}
            size={"large"}
          >
            {submitLabel}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}

export default FormContainer;

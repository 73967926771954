import "./App.css";
import {
  Box,
  CssBaseline,
  makeStyles,
  MuiThemeProvider,
  Slide,
} from "@material-ui/core";
import { useAppTheme } from "./constants/theme";
import { BrowserRouter, Switch } from "react-router-dom";
import { HOME_PAGE } from "./config";
import AppRoute from "./routes/route";
import NotFound from "./pages/NotFound";
import { authProtectedRoutes, publicRoutes } from "./routes";
import ScrollToTop from "./components/Core/ScrollToTop";
import DefaultLayout from "./components/Layouts/DefaultLayout";
import { useDependency } from "reactcoregk/hooks";
import { getAllLists } from "./store/registerList/actions";
import { connect } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { useAuthValidation } from "./hooks";
import { loginSuccess, logoutUser } from "./store/auth/login/actions";
import BusyIndicator from "./components/Core/BusyIndicator";
import { SnackbarProvider } from "notistack";
import {getHomePageComponents, getNavigation, getSlider} from "./store/pageBuilder/actions";
import { useEffect } from "react";
import { isAdmin } from "./utils";
import PageCustomizer from "./components/PageCustomizer/PageCustomizer";
import {getAllPages} from "./store/page/actions";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflowX: "hidden",
  },
});

function App(props) {
  const theme = useAppTheme();
  const classes = useStyles();
  const { getAllLists, context, loginSuccess, logoutUser, getSlider, getNavigation, getAllPages, getHomePageComponents } = props;

  const isValidating = useAuthValidation(loginSuccess, logoutUser);
  useDependency(context.RegisterList, getAllLists);

  useEffect(() => {
    getSlider();
    getNavigation()
    getHomePageComponents()
  }, [getSlider, getNavigation, getHomePageComponents]);

  useEffect(() => {
    if (context.Auth.isAuth && isAdmin()) {
      getAllPages("?items=100")
    }
  }, [context.Auth.isAuth, getAllPages])

  if (isValidating) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <BusyIndicator busy={isValidating} />
      </MuiThemeProvider>
    );
  }
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        autoHideDuration={2000}
        maxSnack={3}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <BrowserRouter basename={HOME_PAGE}>
          <Box className={classes.root}>
            <ScrollToTop />
            <AnimatePresence exitBeforeEnter>
              <Switch>
                {publicRoutes.map((route, idx) => (
                  <AppRoute
                    exact={route.exact}
                    path={route.path}
                    title={route.title}
                    layout={route.layout}
                    component={route.component}
                    hideFooter={route.hideFooter}
                    key={idx}
                    isAuthProtected={false}
                    applyTransparency={route.applyTransparency}
                  />
                ))}

                {authProtectedRoutes.map((route, idx) => (
                  <AppRoute
                    exact={route.exact}
                    path={route.path}
                    layout={route.layout}
                    component={route.component}
                    hideFooter={route.hideFooter}
                    key={idx}
                    isAuthProtected={true}
                    title={route.title}
                    applyTransparency={route.applyTransparency}
                  />
                ))}
                <AppRoute
                  component={NotFound}
                  layout={DefaultLayout}
                  title={"Not Found"}
                />
              </Switch>
            </AnimatePresence>
            {isAdmin() && <PageCustomizer />}
          </Box>
        </BrowserRouter>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      RegisterList: state.RegisterList,
      PageBuilder: state.PageBuilder,
      Auth: state.Auth,
    },
  };
};
export default connect(mapStateToProps, {
  getAllLists,
  loginSuccess,
  logoutUser,
  getSlider,
  getNavigation,
  getAllPages,
  getHomePageComponents
})(App);

import {useField} from "formik";
import { FormControl, TextField } from "@material-ui/core";
import PasswordRequirements from "../PasswordRequirements";
import {useState, Fragment} from "react";

const FormikTextField = (props) => {
  const { required = false, type, variant = "outlined", placeholder } = props;
  const [field, meta] = useField(props.name);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Fragment>
      <FormControl
        fullWidth
        required={required}
        error={meta.error && meta.touched}
      >
        <TextField
          id="outlined-basic"
          variant={variant}
          type={type}
          onClick={handleClick}
          onFocus={handleClick}
          {...field}
          {...props}
          {...props.rest}
          placeholder={placeholder}
          error={meta.error && meta.touched}
          helperText={meta.touched ? meta.error : null}
        />
      </FormControl>
      {type === "password" && props.showRequirements && (
        <PasswordRequirements
          anchorEl={anchorEl}
          open={open}
          id={id}
          setAnchorEl={setAnchorEl}
          password={field?.value}
        />
      )}
    </Fragment>
  );
};

export default FormikTextField;

export const EDIT_SLIDE = "EDIT_SLIDE"
export const EDIT_SLIDE_STYLES = "EDIT_SLIDE_STYLES"
export const MAS_SLIDE_EDIT = "MAS_SLIDE_EDIT"
export const DELETE_SLIDE = "DELETE_SLIDE"
export const ADD_SLIDE = "ADD_SLIDE"
export const PUBLISH_CHANGES = "PUBLISH_CHANGES"
export const GET_STORED_SETTINGS = "GET_STORED_SETTINGS"
export const SET_OPEN_SIDEBAR = "SET_OPEN_SIDEBAR"
export const GET_SLIDE_SHOW = "GET_SLIDE_SHOW"
export const SET_PREVIEW = "SET_PREVIEW"
export const DISCARD_CHANGES = "DISCARD_CHANGES"
export const SET_NODE_ID = "SET_NODE_ID"
export const UPDATE_PUBLIC_MENUS = "UPDATE_PUBLIC_MENUS"
export const GET_PUBLIC_MENUS = "GET_PUBLIC_MENUS"
export const MENU_TRIGGER = "MENU_TRIGGER"
export const GET_HOME_COMPONENTS = "GET_HOME_COMPONENTS"
export const EDIT_HOME_COMPONENT = "EDIT_HOME_COMPONENT"
export const ADD_HOME_COMPONENT = "ADD_HOME_COMPONENT"
export const DELETE_HOME_COMPONENT = "DELETE_HOME_COMPONENT"
export const UPDATE_HOME_COMPONENT_LIST = "UPDATE_HOME_COMPONENT_LIST"


import {Container} from "@material-ui/core";
import BaseContent from "../../components/Core/BaseContent";
import {Formik} from "formik";
import * as Yup from "yup";
import {enthusiastForm} from "../../constants/forms";
import {API_URL} from "../../config";
import {useSubmit} from "../../hooks";
import FormContainer from "../../components/FormContainer";
import {passwordValidation} from "../../constants/constants";

const url = `${API_URL}/account/register/home-owner`;
const success = "Your account has been created successfully";
export default function JoinUsDesignEnthusiast() {
  const { busy, errorMessage, successMessage, handleSubmit } = useSubmit(
    url,
    success
  );

  return (
    <BaseContent
      title={"Join Us"}
      errorMessage={errorMessage}
      successMessage={successMessage}
      subtitle={
        <>
          as a <b>Design Enthusiast</b>
        </>
      }
    >
      <Container maxWidth={"sm"}>
        <Formik
          initialValues={{optInNewsletter: true}}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={Yup.object({
            firstName: Yup.string().required("This field is required"),
            lastName: Yup.string().required("This field is required"),
            email: Yup.string()
              .email("Invalid email")
              .required("This field is required"),
            password: passwordValidation,
          })}
        >
          {(formik) => (
            <FormContainer
              form={enthusiastForm}
              busy={busy}
              submitLabel={"Join"}
            />
          )}
        </Formik>
      </Container>
    </BaseContent>
  );
}

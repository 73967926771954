import React, { useMemo } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import * as forms from "../../../constants/forms";
import { mapFormOptions } from "../../../utils";
import { connect } from "react-redux";
import FormContainer from "../../../components/FormContainer";
import { Step4 } from "../../Auth/JoinUsDesigner";

function DesignerForm(props) {
  const { context, handleSubmit, saving, designer, isLoadingEdit } = props;

  const form = useMemo(() => {
    const mapped = mapFormOptions(forms.editDesignerForm, context.RegisterList);
    mapped.splice(forms.editDesignerForm.length - 8, 0, {
      type: "custom",
      element: <Step4 context={context} />,
    });
    return mapped;
  }, [context]);

  return (
    <>
      <Formik
        initialValues={designer}
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          firstName: Yup.string().required("This field is required"),
          lastName: Yup.string().required("This field is required"),
          professionalCategoryId: Yup.string().required(
            "This field is required"
          ),
          city: Yup.string().required("This field is required"),
          zipCode: Yup.string().required("This field is required"),
          stateId: Yup.string().required("This field is required"),
          consultationId: Yup.string().required("This field is required"),
          phoneNumber: Yup.string().transform(value => value?.replace(/[^\d]/g, ''))
              .min(10, "Phone Number must contain at least 10 characters")
        })}
      >
        {(formik) => (
          <FormContainer
            busy={saving || isLoadingEdit}
            form={form}
            submitLabel={"Save"}
          />
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      RegisterList: state.RegisterList,
    },
  };
};
export default connect(mapStateToProps, {})(DesignerForm);

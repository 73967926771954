import * as Yup from "yup";
import { Formik } from "formik";
import { changePasswordForm } from "../../constants/forms";
import { API_URL } from "../../config";
import { useSubmit } from "../../hooks";
import FormContainer from "../../components/FormContainer";
import { useEffect } from "react";
import { passwordValidation } from "../../constants/constants";

const url = `${API_URL}/account/change-password`;
const success = "Your account has been updated";

function ChangePassword(props) {
  const { setErrorMessage, setSuccessMessage } = props;
  // const [password, setPassword] = useState("");
  const { busy, handleSubmit, successMessage, errorMessage } = useSubmit(
    url,
    success
  );

  useEffect(() => {
    setErrorMessage(errorMessage);
  }, [errorMessage, setErrorMessage]);

  useEffect(() => {
    setSuccessMessage(successMessage);
  }, [setSuccessMessage, successMessage]);

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => handleSubmit(values, true)}
      validationSchema={Yup.object({
        newPassword: passwordValidation,
        oldPassword: Yup.string().required("This field is required"),
      })}
    >
      {(formik) => (
        <FormContainer
          form={changePasswordForm}
          busy={busy}
          submitLabel={"Change Password"}
        />
      )}
    </Formik>
  );
}

export default ChangePassword;

import Title from "../../components/Core/Title";
import { Box, Button, Container, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import img from "../../assets/images/benefits.png";
import img2 from "../../assets/images/benefits2.png";
import img3 from "../../assets/images/benefits3.png";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import InstagramIcon from "@material-ui/icons/Instagram";
import {
  BusinessOutlined,
  ChatOutlined,
  DescriptionOutlined,
  DesktopMac,
  GroupOutlined,
  LanguageOutlined,
  PersonOutlined,
} from "@material-ui/icons";

const useStyles = makeStyles({
  image: {
    width: "100%",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  feature: {
    display: "flex",
    flexDirection: "column",
  },
  featureIcon: {
    fontSize: 48,
  },
  featureTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 16,
    marginTop: 16,
  },
  featureText: {
    fontSize: 16,
  },
  lgButton: {
    height: 50,
  },
  boxTitle: {
    fontSize: 24,
    fontWeight: "bold",
  },
});

export default function MembersBenefit() {
  const classes = useStyles();
  return (
    <div style={{ marginBottom: -31 }}>
      <Box py={16} bgcolor={"#3A5273"} color={"#fff"}>
        <Container>
          <Title>MEMBER BENEFITS</Title>
          <Box mt={16} />
          <Grid container spacing={8}>
            <Grid item md={6} xs={12}>
              <img src={img} className={classes.image} alt={"Benefits"} />
            </Grid>

            <Grid item md={6} xs={12} className={classes.box}>
              <Typography component={"h4"} variant={"h4"}>
                BRAND BUILDING
              </Typography>
              <Box mt={8} />
              <Typography
                component={"p"}
                variant={"body1"}
                style={{ fontSize: 18 }}
              >
                As a Freddie member, the focus is on you, your designs, and your
                business. We are here to help provide you with the tools to
                thrive and grow your business. Our team is dedicated to setting
                you up for success.
              </Typography>
              <Box mt={12} />
              <Button
                color={"primary"}
                variant={"outlined"}
                size={"large"}
                component={Link}
                style={{
                  color: "white",
                  borderColor: "white",
                }}
                className={classes.lgButton}
                to={"/join"}
              >
                Join Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={16} bgcolor={"#D8D8D8"}>
        <Container>
          <Grid container spacing={8}>
            <Grid item md={3} sm={6} xs={12} className={classes.feature}>
              <InstagramIcon className={classes.featureIcon} />
              <Typography
                component={"h5"}
                variant={"h5"}
                className={classes.featureTitle}
              >
                INSTAGRAM + SOCIAL GUIDANCE
              </Typography>
              <Typography
                component={"p"}
                variant={"body1"}
                className={classes.featureText}
              >
                Learn from and work with our team on utilizing social media
                platforms intelligently to increase your reach and engagement
                and create content that is authentic to your brand.
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12} className={classes.feature}>
              <DescriptionOutlined className={classes.featureIcon} />
              <Typography
                component={"h5"}
                variant={"h5"}
                className={classes.featureTitle}
              >
                NEWSLETTER FEATURE
              </Typography>
              <Typography
                component={"p"}
                variant={"body1"}
                className={classes.featureText}
              >
                Get added exposure with our targeted emails geared towards
                prospective clients and collaborators.
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12} className={classes.feature}>
              <PersonOutlined className={classes.featureIcon} />
              <Typography
                component={"h5"}
                variant={"h5"}
                className={classes.featureTitle}
              >
                PROFILE ON JOINFREDDIE.COM
              </Typography>
              <Typography
                component={"p"}
                variant={"body1"}
                className={classes.featureText}
              >
                With a dedicated profile on our platform, you are in control of
                how you and your work are seen by our design enthusiast
                community. Update whenever you want and as frequently as you
                want to share your latest projects.
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12} className={classes.feature}>
              <DesktopMac className={classes.featureIcon} />
              <Typography
                component={"h5"}
                variant={"h5"}
                className={classes.featureTitle}
              >
                ENGAGING EDITORIALS
              </Typography>
              <Typography
                component={"p"}
                variant={"body1"}
                className={classes.featureText}
              >
                Share your story with our editorial team to show our design
                enthusiast community your vision and ethos.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={16}>
        <Container>
          <Grid container spacing={8}>
            <Grid item md={6} xs={12} className={classes.box}>
              <Typography
                component={"h4"}
                variant={"h4"}
                className={classes.boxTitle}
              >
                INDUSTRY ACCESS
              </Typography>
              <Box mt={2} />
              <Typography
                component={"p"}
                variant={"body1"}
                style={{ fontSize: 18 }}
              >
                Learn from and meet your fellow peers, get a tip or two from our
                internal experts, and make new connections to potential partners
                and clients.
              </Typography>
              <Box mt={4} />
              <Typography
                component={"h4"}
                variant={"h4"}
                className={classes.boxTitle}
              >
                COLLABORATION OPPORTUNITIES
              </Typography>
              <Box mt={2} />
              <Typography
                component={"p"}
                variant={"body1"}
                style={{ fontSize: 18 }}
              >
                Partner with the industry’s leading brands creating lasting
                relationships.
              </Typography>
              <Box mt={4} />
              <Typography
                component={"h4"}
                variant={"h4"}
                className={classes.boxTitle}
              >
                EVENTS
              </Typography>
              <Box mt={2} />
              <Typography
                component={"p"}
                variant={"body1"}
                style={{ fontSize: 18 }}
              >
                Join members, industry leaders, and notable figures for
                exclusive gatherings—virtual and in-person—for deep dives on our
                industry and to get to know each other behind the design.
              </Typography>
              <Box mt={4} />
              <Typography
                component={"h4"}
                variant={"h4"}
                className={classes.boxTitle}
              >
                PRODUCT LAUNCHES
              </Typography>
              <Box mt={2} />
              <Typography
                component={"p"}
                variant={"body1"}
                style={{ fontSize: 18 }}
              >
                Get exclusive first access to the newest pieces from preferred
                vendors.
              </Typography>
              <Box mt={4} />
              <Typography
                component={"h4"}
                variant={"h4"}
                className={classes.boxTitle}
              >
                SPECIAL PRICING
              </Typography>
              <Box mt={2} />
              <Typography
                component={"p"}
                variant={"body1"}
                style={{ fontSize: 18 }}
              >
                We know you already have the ‘designer discount’ but we’re going
                to up the ante with additional incentives on key pieces.
              </Typography>
              <Box mt={8} />
              <Button
                color={"primary"}
                variant={"contained"}
                size={"large"}
                className={classes.lgButton}
                component={Link}
                to={"/join"}
              >
                Join Now
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <img src={img2} className={classes.image} alt={"Benefits"} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={16}>
        <Container>
          <Grid container spacing={8}>
            <Grid item md={6} xs={12}>
              <img src={img3} className={classes.image} alt={"Benefits"} />
            </Grid>
            <Grid item md={6} xs={12} className={classes.box}>
              <Typography component={"h4"} variant={"h4"}>
                BUSINESS SUPPORT
              </Typography>
              <Box mt={8} />
              <Typography
                component={"p"}
                variant={"body1"}
                style={{ fontSize: 18 }}
              >
                Whether you’re starting out or an industry superstar, we want to
                act as a resource to help you with your endeavors.
              </Typography>
              <Box mt={12} />
              <Button
                color={"primary"}
                variant={"outlined"}
                size={"large"}
                component={Link}
                className={classes.lgButton}
                to={"/join"}
              >
                Join Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={16} bgcolor={"#3A5273"} color={"#fff"}>
        <Container>
          <Grid container spacing={8}>
            <Grid item md={4} sm={6} xs={12} className={classes.feature}>
              <ChatOutlined className={classes.featureIcon} />
              <Typography
                component={"h5"}
                variant={"h5"}
                className={classes.featureTitle}
              >
                MEMBER  MENTORSHIP
              </Typography>
              <Typography
                component={"p"}
                variant={"body1"}
                className={classes.featureText}
              >
                Reach out to us to be connected with another Designer Member and
                learn from each other.
              </Typography>
            </Grid>
            <Grid item md={4} sm={6} xs={12} className={classes.feature}>
              <GroupOutlined className={classes.featureIcon} />
              <Typography
                component={"h5"}
                variant={"h5"}
                className={classes.featureTitle}
              >
                FOCUS  GROUPS
              </Typography>
              <Typography
                component={"p"}
                variant={"body1"}
                className={classes.featureText}
              >
                We want to hear from you about what you like, what you don’t,
                and what you want more of.
              </Typography>
            </Grid>
            <Grid item md={4} sm={6} xs={12} className={classes.feature}>
              <BusinessOutlined className={classes.featureIcon} />
              <Typography
                component={"h5"}
                variant={"h5"}
                className={classes.featureTitle}
              >
                WORKSHOPS + WEBINARS
              </Typography>
              <Typography
                component={"p"}
                variant={"body1"}
                className={classes.featureText}
              >
                Innovative and inspiring series meant to grow you as a person, a
                business, and global citizen. Pick-up tips on topics ranging
                from the realities of starting out to how to make your designs
                green and chic. And be sure to let us know what you are
                interested in hearing about so we can make it a reality.
              </Typography>
            </Grid>
            {/*<Grid item md={4} sm={6} xs={12} className={classes.feature}>*/}
            {/*  <DescriptionOutlined className={classes.featureIcon} />*/}
            {/*  <Typography*/}
            {/*    component={"h5"}*/}
            {/*    variant={"h5"}*/}
            {/*    className={classes.featureTitle}*/}
            {/*  >*/}
            {/*    CONTRACT TEMPLATES*/}
            {/*  </Typography>*/}
            {/*  <Typography*/}
            {/*    component={"p"}*/}
            {/*    variant={"body1"}*/}
            {/*    className={classes.featureText}*/}
            {/*  >*/}
            {/*    Download at your disposal so that you can close that deal.*/}
            {/*  </Typography>*/}
            {/*</Grid>*/}
            {/*<Grid item md={4} sm={6} xs={12} className={classes.feature}>*/}
            {/*  <SearchOutlined className={classes.featureIcon} />*/}
            {/*  <Typography*/}
            {/*    component={"h5"}*/}
            {/*    variant={"h5"}*/}
            {/*    className={classes.featureTitle}*/}
            {/*  >*/}
            {/*    PRODUCT FINDER*/}
            {/*  </Typography>*/}
            {/*  <Typography*/}
            {/*    component={"p"}*/}
            {/*    variant={"body1"}*/}
            {/*    className={classes.featureText}*/}
            {/*  >*/}
            {/*    Need help identifying what you need for a project? Our team’s*/}
            {/*    ready with a list of resources to serve.*/}
            {/*  </Typography>*/}
            {/*</Grid>*/}
            <Grid item md={4} sm={6} xs={12} className={classes.feature}>
              <LanguageOutlined className={classes.featureIcon} />
              <Typography
                component={"h5"}
                variant={"h5"}
                className={classes.featureTitle}
              >
                NETWORKING
              </Typography>
              <Typography
                component={"p"}
                variant={"body1"}
                className={classes.featureText}
              >
                Build your profile, grow your craft and spark connection with
                potential interior design clients.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

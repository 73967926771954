import BaseContent from "../../components/Core/BaseContent";
import img from "../../assets/images/press.jpeg";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import ContactDialog from "../../components/ContactDialog";
import { useState } from "react";

export default function PressAndPartnerships() {
  const [openContact, setOpenContact] = useState(false);
  const [entity, setEntity] = useState({})

  const handleOpen = (inquiryType) => {
    setEntity({inquiryType})
    setOpenContact(true)
  }

  return (
    <BaseContent title={"Press and Partnerships"}>
      <img src={img} alt={"benefits"} style={{ width: "100%" }} />
      <Box my={4} />

      <Grid container spacing={8} justify={"center"}>
        <Grid item md={6} xs={12}>
          <Grid
            container
            alignItems={"center"}
            direction={"column"}
            style={{ minHeight: 205 }}
          >
            <Typography variant={"h2"} style={{ fontSize: 36 }}>
              PRESS
            </Typography>
            <Typography style={{ textAlign: "center" }} variant={"body1"}>
              At Freddie, we’re all about sharing our member’s stories and
              design philosophy with everyone. We can't wait to hear from you.
            </Typography>
            <Button
              variant={"outlined"}
              color={"primary"}
              size={"large"}
              onClick={() => handleOpen("Press")}
              style={{ marginTop: "auto", minWidth: 240 }}
            >
              Get in touch
            </Button>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid
            container
            alignItems={"center"}
            direction={"column"}
            style={{ minHeight: 205 }}
          >
            <Typography variant={"h2"} style={{ fontSize: 36 }}>
              PARTNERSHIPS
            </Typography>
            <Typography style={{ textAlign: "center" }} variant={"body1"}>
              The only way our community grows is through support from our
              industry friends. If you’re a company that is interested in
              working with us or one of our member designers, contact us.
            </Typography>
            <Button
              variant={"outlined"}
              color={"primary"}
              size={"large"}
              onClick={() => handleOpen("Partnership")}
              style={{ marginTop: "auto", minWidth: 240 }}
            >
              Get in touch
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {/*<Box mt={10} />*/}
      {/*<Grid container alignItems={"center"} direction={"column"}>*/}
      {/*  <Typography variant={"h2"} style={{ fontSize: 36 }}>*/}
      {/*    PARTNERSHIP WITH US*/}
      {/*  </Typography>*/}
      {/*  <Typography style={{ textAlign: "center" }}>*/}
      {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc maximus,*/}
      {/*    nulla ut commodo sagittis, sapien dui mattis.*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}
      {/*<Box mt={10} />*/}
      {/*<Grid container spacing={8}>*/}
      {/*  <Grid item md={6} xs={12}>*/}
      {/*    <Typography variant={"h6"} style={{ fontWeight: "bolder" }}>*/}
      {/*      Content Distribution*/}
      {/*    </Typography>*/}
      {/*    <Typography variant={"body1"} component={"p"}>*/}
      {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc*/}
      {/*      maximus, nulla ut commodo sagittis, sapien dui mattis dui.Lorem*/}
      {/*      ipsum dolor sit amet, consectetur adipiscing elit. Nunc maximus,*/}
      {/*      nulla ut commodo sagittis, sapien dui mattis dui.*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}
      {/*  <Grid item md={6} xs={12}>*/}
      {/*    <Typography variant={"h6"} style={{ fontWeight: "bolder" }}>*/}
      {/*      Sponsorship*/}
      {/*    </Typography>*/}
      {/*    <Typography variant={"body1"}>*/}
      {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc*/}
      {/*      maximus, nulla ut commodo sagittis, sapien dui mattis dui.Lorem*/}
      {/*      ipsum dolor sit amet, consectetur adipiscing elit. Nunc maximus,*/}
      {/*      nulla ut commodo sagittis, sapien dui mattis dui.*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      {/*<Box mt={10} />*/}
      {/*<Grid container spacing={8}>*/}
      {/*  <Grid item md={6} xs={12}>*/}
      {/*    <Typography variant={"h6"} style={{ fontWeight: "bolder" }}>*/}
      {/*      Expertise*/}
      {/*    </Typography>*/}
      {/*    <Typography variant={"body1"}>*/}
      {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc*/}
      {/*      maximus, nulla ut commodo sagittis, sapien dui mattis dui.Lorem*/}
      {/*      ipsum dolor sit amet, consectetur adipiscing elit. Nunc maximus,*/}
      {/*      nulla ut commodo sagittis, sapien dui mattis dui.*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}
      {/*  <Grid item md={6} xs={12}>*/}
      {/*    <Typography variant={"h6"} style={{ fontWeight: "bolder" }}>*/}
      {/*      Expertise*/}
      {/*    </Typography>*/}
      {/*    <Typography variant={"body1"}>*/}
      {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc*/}
      {/*      maximus, nulla ut commodo sagittis, sapien dui mattis dui.Lorem*/}
      {/*      ipsum dolor sit amet, consectetur adipiscing elit. Nunc maximus,*/}
      {/*      nulla ut commodo sagittis, sapien dui mattis dui.*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      {/*<Box mt={10} />*/}
      {/*<Grid container alignItems={"center"} direction={"column"}>*/}
      {/*  <Typography variant={"h2"} style={{ fontSize: 24 }}>*/}
      {/*    PARTNERS*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}
      {/*<Box mt={4} />*/}
      {/*<Grid container alignItems={"center"} justify={"center"}>*/}
      {/*  <img src={logo1} style={{ height: 50 }} alt={"the edit"} />*/}
      {/*  <img src={logo2} style={{ height: 50 }} alt={"Schumacher"} />*/}
      {/*  <img src={logo3} style={{ height: 50 }} alt={"PMF"} />*/}
      {/*</Grid>*/}
      <ContactDialog
        open={openContact}
        handleClose={() => setOpenContact(false)}
        entity={entity}
      />
    </BaseContent>
  );
}

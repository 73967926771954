import { Box, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Title from "./Title";
import ErrorBoundary from "../ErrorBoundary";
import AlertMessage from "./AlertMessage";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    flex: 1,
  },
  subtitle: {
    margin: "auto",
    marginBottom: 24,
    maxWidth: 800,
    textAlign: "center",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
  },
}));

function BaseContent(props) {
  const classes = useStyles();
  const {
    title,
    subtitle,
    errorMessage,
    successMessage,
    warningMessage,
    infoMessage,
  } = props;

  return (
    <Container className={classes.root}>
      <Box pt={2} className={classes.flex}>
        {title && (
          <Box className={classes.flex} style={{ marginBottom: 16 }}>
            <Title>{title}</Title>
            {subtitle && (
              <Typography className={classes.subtitle} variant={"body1"}>
                {subtitle}
              </Typography>
            )}
          </Box>
        )}
        <AlertMessage message={errorMessage} severity={"error"} />
        <AlertMessage message={infoMessage} severity={"info"} />
        <AlertMessage message={warningMessage} severity={"warning"} />
        <AlertMessage message={successMessage} severity={"success"} />
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </Box>
    </Container>
  );
}

export default BaseContent;

BaseContent.propTypes = {
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  warningMessage: PropTypes.string,
  infoMessage: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

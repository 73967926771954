import { Grid, Typography } from "@material-ui/core";
import BaseContent from "../../components/Core/BaseContent";
import { Link } from "react-router-dom";

function Item({ question, answer }) {
  return (
    <Grid item>
      <Typography style={{ fontWeight: "bold" }}>{question}</Typography>
      <Typography>{answer}</Typography>
    </Grid>
  );
}

export default function Faq() {
  const handleNewsletter = () => {
    const input = document.getElementById("newsletterEmail");
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 200);
    }
  };

  const faqs = [
    {
      question: "Who owns Freddie?",
      answer:
        "F. Schumacher & Co., which was founded in 1889, launched Freddie this October. While Freddie is a separate legal entity, F. Schumacher & Co. is the sole owner.",
    },
    {
      question: "What is Freddie?",
      answer:
        "Freddie is a new membership experience designed for the interior community. Freddie’s mission is to empower and highlight the creativity and innovation of our industry and help bring their work, experience, and insights to the public through our channels and newsletters. For members, it’s a forum where member designers can chat and learn while growing their business.",
    },
    {
      question: "What is Freddie not?",
      answer:
        "Freddie is not an end-to-end design solution for prospective clients and will not own the client-to-designer matching process. Freddie is a community based membership organization and helps bring visibility to designers and the design community. Any revenue as it relates to a designer’s business is solely their own.",
    },
    {
      question: "What are Freddie’s offerings?",
      answer:
        "We’re just getting started but for member designers, we will be providing brand building and support including digital first opportunities including social and marketing help, along with providing our industry insights in our newsletters. As we continue to grow and build our network, our programming will also include virtual member networking events, webinars, and connecting with our community.",
    },
    {
      question: "Who is F. Schumacher & Co.?",
      answer:
        "F. Schumacher & Co. is a privately held company, founded and based in New York City, that designs and manufacturers products for the interior design industry worldwide. It is a 131-year-old entity with a rich history in innovation and the artistry of design. It is the parent company to Schumacher, Patterson Flynn Martin, Schumacher Hospitality, and now Freddie.",
    },
    {
      question: "Who can subscribe?",
      answer: (
        <span>
          Anyone! We want designers and design enthusiasts to meet and engage in
          our community. While membership will be limited to designers, we hope
          everyone signs up for our newsletter and follows us on our social
          channels at @joinfreddie. For designers who wish to apply for
          membership, please{" "}
          <Link className={"hyperlink"} to={"/join"}>
            click here.
          </Link>
        </span>
      ),
    },
    {
      question: "How can I subscribe?",
      answer: (
        <span>
          Follow us @joinfreddie on Facebook, Instagram, and Pinterest and make
          sure to sign up for our newsletter,{" "}
          <a
            href={"#newsletter"}
            className={"hyperlink"}
            onClick={handleNewsletter}
          >
            here!
          </a>
        </span>
      ),
    },
  ];

  return (
    <BaseContent title={"FAQ"}>
      <Grid container spacing={2}>
        {faqs.map((faq, index) => (
          <Item key={index} question={faq.question} answer={faq.answer} />
        ))}
      </Grid>
    </BaseContent>
  );
}

import React from "react";
import Section from "../Section";
import { useInstagram } from "../../hooks";
import { CardActionArea, Grid } from "@material-ui/core";
import ZoomImage from "../ZoomImage";
import Carousel from "../Carousel/Carousel";

function InstagramGrid({ settings, name, hideDivider }) {
  const size = settings.size || 4;
  const fullWidth = settings.fullWidth || false;
  const instaPosts = useInstagram({ size });

  function renderItem(item) {
    return <InstaPost post={item} />;
  }

  return (
    <Section
      fluid
      maxWidth={fullWidth ? "xl" : "lg"}
      title={name}
      hideDivider={hideDivider}
    >
      <Carousel renderItem={renderItem} items={instaPosts} />
    </Section>
  );
}

export default InstagramGrid;

function InstaPost({ post }) {
  return (
    <Grid>
      <CardActionArea>
        <a href={post.url || post.permalink} target={"_blank"} rel="noreferrer">
          <ZoomImage
            src={post.src || post.media_url}
            height={400}
            width={"100%"}
            alt={"insta post"}
          />
        </a>
      </CardActionArea>
    </Grid>
  );
}

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  createMenu,
  deleteMenu,
  getAllMenus,
  updateMenu,
} from "../../store/menu/actions";
import {
  useDependency,
  useEntityProgress,
  useProgress,
} from "reactcoregk/hooks";
import Box from "@material-ui/core/Box";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemText,
  Paper,
} from "@material-ui/core";
import {
  AddCircleOutline,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import FormikTextField from "../Core/FormikTextField";
import { Form, Formik } from "formik";
import { useAlert, useAppLinks } from "../../hooks";
import { deleteData, fetchData, postData, updateData } from "../../utils";
import { API_URL } from "../../config";
import FormikSelect from "../Core/FormikSelect";
import { TabPanel } from "../../pages/Designers/ContactDesignerDialog";
import { Skeleton } from "@material-ui/lab";
import ErrorButton from "../ErrorButton";
import { triggerMenu, updatePublicMenu } from "../../store/pageBuilder/actions";
import { compareProp } from "reactcoregk/utils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const grid = 8;

const EntryCardItem = styled.div`
  margin-bottom: ${grid}px;
  padding: ${0}px;
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function LoadingState() {
  return [...Array(4)].map((k, i) => (
    <Paper variant={"outlined"} style={{ marginBottom: 8 }}>
      <ListItem>
        <Skeleton variant={"text"} width={"100%"} height={32} />
      </ListItem>
    </Paper>
  ));
}

function EntryItem({
  entry,
  linkOptions,
  handleDeleteEntry,
  handleUpdateEntry,
  loadingEntries,
}) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Paper variant={"outlined"} style={{ marginBottom: 8 }}>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={entry.name} secondary={entry.url} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Divider />
        <Formik
          enableReinitialize
          initialValues={entry}
          onSubmit={(values, { resetForm }) => {
            handleUpdateEntry(values).then(() => {
              handleClick();
            });
          }}
        >
          <Form>
            <ListItem
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <FormikTextField
                variant={"standard"}
                margin="none"
                placeholder={"New menu.."}
                type={"text"}
                fullWidth
                required
                id={"name"}
                name={"name"}
                autoComplete="off"
                disabled={loadingEntries}
                label={"Name"}
              />
              <Box mt={1} />
              <FormikSelect
                variant={"standard"}
                margin="normal"
                placeholder={"New menu.."}
                fullWidth
                label={"Url"}
                required
                id={"url"}
                disabled={loadingEntries}
                name={"url"}
                options={linkOptions}
                autoComplete="off"
              />
              <Box my={2} display={"flex"}>
                <Button
                  disabled={loadingEntries}
                  onClick={() => handleDeleteEntry(entry)}
                >
                  Delete
                </Button>
                <Button
                  color={"primary"}
                  disabled={loadingEntries}
                  type={"submit"}
                >
                  Update
                </Button>
              </Box>
            </ListItem>
          </Form>
        </Formik>
      </Collapse>
    </Paper>
  );
}

function DraggableEntry(props) {
  return (
    <Draggable draggableId={`id-${props.entry.id}}`} index={props.index}>
      {(provided) => (
        <EntryCardItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <EntryItem {...props} />
        </EntryCardItem>
      )}
    </Draggable>
  );
}

const EntryList = React.memo(function TaskList({ entries, ...rest }) {
  return entries.map((entry, index) => (
    <DraggableEntry entry={entry} index={index} key={entry.id} {...rest} />
  ));
});

function MenuEntries({
  menu,
  goBack,
  deleteMenu,
  busy,
  updateMenu,
  linkOptions,
  updatePublicMenu,
  menuSettings,
  triggerMenu,
}) {
  const {
    loadingEntries,
    entries,
    handleDeleteEntry,
    handleNewEntry,
    handleUpdateEntry,
    handleRepositionEntries,
  } = useMenuEntries(menu.id, triggerMenu);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const isPrimary = menuSettings.temp.headerMenuId === menu.id;
  const wasPrimary = menuSettings.result.headerMenuId === menu.id;
  const isFooter = menuSettings.temp.footerMenuId === menu.id;
  const wasFooter = menuSettings.result.footerMenuId === menu.id;

  const handleTogglePrimary = (e) => {
    updatePublicMenu({
      themeMenuId: "headerMenuId",
      appMenuId: isPrimary
        ? wasPrimary
          ? null
          : menuSettings.result.headerMenuId
        : menu.id,
    });
  };

  const handleToggleFooter = (e) => {
    updatePublicMenu({
      themeMenuId: "footerMenuId",
      appMenuId: isFooter
        ? wasFooter
          ? null
          : menuSettings.result.footerMenuId
        : menu.id,
    });
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newEntries = reorder(
      entries,
      result.source.index,
      result.destination.index
    );

    newEntries.forEach((entry, index) => {
      entry.order = index;
    });
    handleRepositionEntries(newEntries);
  }

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"}>
        <Formik
          enableReinitialize
          initialValues={menu}
          onSubmit={(values) => updateMenu(values)}
        >
          <Form style={{ flex: 1 }}>
            <Paper variant={"outlined"}>
              <ListItem>
                <FormikTextField
                  variant={"standard"}
                  margin="none"
                  placeholder={"New menu.."}
                  type={"text"}
                  fullWidth
                  required
                  id={"name"}
                  name={"name"}
                  autoComplete="off"
                  label={""}
                  InputProps={{
                    startAdornment: (
                      <IconButton size={"small"} color={"primary"} onClick={goBack} style={{ marginRight: 8 }}>
                        <ChevronLeft />
                      </IconButton>
                    ),
                    disableUnderline: true,
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  disabled={busy}
                />
              </ListItem>
            </Paper>
          </Form>
        </Formik>
      </Box>
      <Box mt={1} />
      <FormControlLabel
        control={
          <Checkbox
            checked={isPrimary}
            onChange={handleTogglePrimary}
            name={isPrimary}
            color="primary"
          />
        }
        label="Set as primary menu"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isFooter}
            onChange={handleToggleFooter}
            name={isPrimary}
            color="primary"
          />
        }
        label="Set as footer menu"
      />
      <List component="nav" aria-labelledby="nested-list-subheader">
        {loadingEntries && !entries[0] ? (
          <LoadingState />
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <EntryList
                    loadingEntries={loadingEntries}
                    entries={entries}
                    linkOptions={linkOptions}
                    handleDeleteEntry={handleDeleteEntry}
                    handleUpdateEntry={handleUpdateEntry}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <Paper variant={"outlined"}>
          <ListItem button onClick={handleClick} disabled={loadingEntries}>
            <ListItemText primary={"New Link"} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <Formik
              enableReinitialize
              initialValues={{}}
              onSubmit={(values, { resetForm }) => {
                handleNewEntry(values).then(() => {
                  handleClick();
                  resetForm({});
                });
              }}
            >
              <Form>
                <ListItem
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <FormikTextField
                    variant={"standard"}
                    margin="none"
                    placeholder={"New menu.."}
                    type={"text"}
                    fullWidth
                    required
                    id={"name"}
                    name={"name"}
                    autoComplete="off"
                    disabled={loadingEntries}
                    label={"Name"}
                  />
                  <Box mt={1} />
                  <FormikSelect
                    variant={"standard"}
                    margin="normal"
                    placeholder={"New menu.."}
                    fullWidth
                    label={"Url"}
                    required
                    id={"url"}
                    disabled={loadingEntries}
                    name={"url"}
                    options={linkOptions}
                    autoComplete="off"
                  />
                  <Box my={2} display={"flex"}>
                    <Button
                      color={"primary"}
                      disabled={loadingEntries}
                      type={"submit"}
                    >
                      Add
                    </Button>
                  </Box>
                </ListItem>
              </Form>
            </Formik>
          </Collapse>
        </Paper>
      </List>
      <ErrorButton
        style={{ width: 150, marginTop: 32 }}
        size={"large"}
        disabled={busy}
        onClick={() => deleteMenu(menu)}
      >
        Delete
      </ErrorButton>
    </Box>
  );
}

function MenusManagement({
  context,
  getAllMenus,
  createMenu,
  updateMenu,
  deleteMenu,
  updatePublicMenu,
  triggerMenu,
}) {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [menu, setMenu] = useState({});

  useDependency(context.Menu, getAllMenus);

  const { menuSettings } = context.PageBuilder;
  const menus = context.Menu.getAll.result;
  const loadingMenus = context.Menu.getAll.isLoading;

  const [busy, error] = useEntityProgress(context.Menu);

  const onDelete = useCallback(() => {
    setStep(0);
    triggerMenu();
  }, [triggerMenu]);

  useProgress(null, onDelete, null, context.Menu.delete);

  useAlert(error, "error");

  const handleMenuSelect = (menu) => {
    setMenu(menu);
    setStep(1);
  };

  const linkOptions = useAppLinks(context);

  return (
    <Box px={2}>
      <TabPanel value={0} index={step}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          {loadingMenus ? (
            <LoadingState />
          ) : (
            menus.map((menu) => (
              <Paper variant={"outlined"} style={{ marginBottom: 8 }}>
                <ListItem button onClick={() => handleMenuSelect(menu)}>
                  <ListItemText primary={menu.name} />
                  <ChevronRight />
                </ListItem>
              </Paper>
            ))
          )}
          <Formik
            enableReinitialize
            initialValues={{ name: "" }}
            onSubmit={(values, { resetForm }) => {
              createMenu(values);
              resetForm({ name: "" });
            }}
          >
            <Form>
              <Paper variant={"outlined"}>
                <ListItem button>
                  <FormikTextField
                    variant={"standard"}
                    margin="none"
                    placeholder={"New menu.."}
                    type={"text"}
                    fullWidth
                    required
                    id={"name"}
                    name={"name"}
                    autoComplete="off"
                    label={""}
                    InputProps={{
                      disableUnderline: true,
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    disabled={busy}
                  />
                  <IconButton size={"small"} type={"submit"} disabled={busy}>
                    <AddCircleOutline />
                  </IconButton>
                </ListItem>
              </Paper>
            </Form>
          </Formik>
        </List>
      </TabPanel>
      <TabPanel index={1} value={step}>
        <MenuEntries
          menu={menu}
          goBack={() => setStep(0)}
          deleteMenu={deleteMenu}
          busy={busy}
          updatePublicMenu={updatePublicMenu}
          updateMenu={updateMenu}
          linkOptions={linkOptions}
          menuSettings={menuSettings}
          triggerMenu={triggerMenu}
        />
      </TabPanel>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Menu: state.Menu,
      PageBuilder: state.PageBuilder,
      Page: state.Page,
    },
  };
};
export default connect(mapStateToProps, {
  getAllMenus,
  createMenu,
  updateMenu,
  deleteMenu,
  updatePublicMenu,
  triggerMenu,
})(MenusManagement);

const useMenuEntries = (menuId, triggerMenu) => {
  const [entries, setEntries] = useState([]);
  const [errorEntries, setErrorEntries] = useState();
  const [busy, setBusy] = useState(false);

  useAlert(errorEntries, "error");

  useEffect(() => {
    (async () => {
      setBusy(true);
      try {
        const url = `${API_URL}/manage/menus/${menuId}/entries`;
        const result = await fetchData(url);
        setEntries(result.sort((a, b) => compareProp(a, b)));
      } catch (ex) {
        setErrorEntries(ex.message);
      }
      setBusy(false);
    })();
  }, [menuId]);

  const handleNewEntry = useCallback(
    async (entry) => {
      setBusy(true);
      try {
        const url = `${API_URL}/manage/menus/${menuId}/entries`;
        const result = await postData(url, entry);
        setEntries((prevState) => [...prevState, result]);
        triggerMenu();
      } catch (ex) {
        setErrorEntries(ex.message);
      }
      setBusy(false);
    },
    [menuId, triggerMenu]
  );

  const handleUpdateEntry = useCallback(
    async (entry) => {
      setBusy(true);
      try {
        const url = `${API_URL}/manage/menu-entries/${entry.id}`;
        const result = await updateData(url, entry);
        setEntries((prevState) => [...prevState, result]);
        triggerMenu();
      } catch (ex) {
        setErrorEntries(ex.message);
      }
      setBusy(false);
    },
    [triggerMenu]
  );

  const handleDeleteEntry = useCallback(
    async (entry) => {
      setBusy(true);
      try {
        await deleteData(`${API_URL}/manage/menu-entries/${entry.id}`);
        setEntries((prevEntries) =>
          prevEntries.filter((x) => x.id !== entry.id)
        );
        triggerMenu();
      } catch (ex) {
        setErrorEntries(ex.message);
      }
      setBusy(false);
    },
    [triggerMenu]
  );

  const handleRepositionEntries = useCallback(
    (newEntries) => {
      setEntries(newEntries);
      const requests = newEntries.map((entry) => {
        const url = `${API_URL}/manage/menu-entries/${entry.id}`;
        return updateData(url, entry);
      });
      Promise.all(requests)
        .then(() => {
          triggerMenu();
        })
        .catch((ex) => {
          setErrorEntries(ex.message);
        });
    },
    [triggerMenu]
  );

  return {
    entries,
    loadingEntries: busy,
    handleNewEntry,
    handleUpdateEntry,
    handleDeleteEntry,
    setEntries,
    handleRepositionEntries,
  };
};

import axios from "axios";
import {handleError} from "reactcoregk/store/api";
import {API_URL} from "../../../config";
import {getCredentials} from "../../../utils";
// import md5 from 'md5';

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = getCredentials()
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};


// Login Method
const postLogin = async (data) => {
  // const config = {
  //   headers: {
  //     Authorization: `${data.username}:${md5(data.password)}`
  //   }
  // }
  const url = API_URL + "/account/login";
  return axios
    .post(url, data)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

//Logout Method
const postLogout = async (history) => {
  // const config = {
  //   headers: {
  //     Authorization: `${data.username}:${md5(data.password)}`
  //   }
  // }
  const url = API_URL + "/token/logout";
  return axios
    .post(url, {})
    .then((response) => {
      localStorage.removeItem('authUser')
        history.push('/sign-in')
    })
    .catch(handleError);
};

// postForgetPwd
const postForgetPwd = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  postForgetPwd,
  postLogout
};

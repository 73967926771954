import React, {Fragment} from "react";
import DesignerForm from "./DesignerForm";
import DesignerView from "./DesignerView";

function FormViewWrapper(props) {
  const {
    isEditing,
    handleSubmit,
    designerIdOrSlug,
    designerEdit,
    saving,
    isLoadingEdit,
    shouldCollapsePortfolio,
    designerView,
    setOpenContact,
    isLoading,
  } = props;

  return (
    <Fragment>
      {isEditing ? (
        <DesignerForm
          handleSubmit={handleSubmit}
          designerId={designerIdOrSlug}
          designer={designerEdit}
          saving={saving}
          isLoadingEdit={isLoadingEdit}
        />
      ) : (
        <DesignerView
          shouldCollapsePortfolio={shouldCollapsePortfolio}
          designer={designerView}
          openContact={() => setOpenContact(true)}
          isLoading={isLoading}
        />
      )}
    </Fragment>
  );
}

export default FormViewWrapper;

import {useFetch} from "reactcoregk/hooks";
import {ApiEndpoint} from "../../store/@core/endpoint";
import {EntityType} from "../../store/@core/entityType";
import Section from "../Section";
import FeaturedDesigner from "../FeaturedDesigner/FeatureDesigner";
import Carousel from "../Carousel/Carousel";

const url = ApiEndpoint[EntityType.Designer] + "/homepage";

function DesignersGrid(props) {
  const fullWidth = props.settings.fullWidth || false;
  const [loadingDesigners, designers] = useFetch(url, {}, []);

  function renderItem(item, loading) {
    return <FeaturedDesigner designer={item} isLoading={loading} />;
  }
  return (
    <Section
      title={props.name}
      maxWidth={fullWidth ? "xl" : "lg"}
      hideDivider={props.hideDivider}
    >
      <Carousel
        items={designers}
        renderItem={renderItem}
        loading={loadingDesigners}
        hideProgress
      />
    </Section>
  );
}

export default DesignersGrid;

import { entityType } from "./module";
import {
  ADD_HOME_COMPONENT,
  ADD_SLIDE,
  DELETE_HOME_COMPONENT,
  DELETE_SLIDE,
  DISCARD_CHANGES,
  EDIT_HOME_COMPONENT,
  EDIT_SLIDE,
  EDIT_SLIDE_STYLES,
  GET_HOME_COMPONENTS,
  GET_PUBLIC_MENUS,
  GET_SLIDE_SHOW,
  GET_STORED_SETTINGS,
  MAS_SLIDE_EDIT,
  MENU_TRIGGER,
  PUBLISH_CHANGES,
  SET_NODE_ID,
  SET_OPEN_SIDEBAR,
  SET_PREVIEW,
  UPDATE_HOME_COMPONENT_LIST,
  UPDATE_PUBLIC_MENUS,
} from "./actionTypes";
import { ActionStates } from "reactcoregk/store/actions";
import { makeid } from "reactcoregk/utils";
import {
  createOperationState,
  handleAsyncState,
} from "reactcoregk/store/reducer";
import { unwrapSlides } from "../../utils";
import { sliderStylesDefault } from "../../constants/constants";

const initialState = {
  openSidebar: false,
  dirty: false,
  preview: true,
  nodeId: 0,
  publish: createOperationState({}),
  slider: {
    result: [],
    temp: [],
  },
  footerLinks: {
    result: [],
    temp: [],
  },
  slideShow: createOperationState([]),
  menuSettings: {
    temp: {
      headerMenuId: 0,
      footerMenuId: 0,
    },
    result: {
      headerMenuId: 0,
      footerMenuId: 0,
    },
  },
  menuTriggerCount: 0,
  homeScreen: {
    result: [],
    temp: [],
  },
  menus: {
    result: {
      header: [],
      footer: [],
    },
    temp: {
      header: [],
      footer: [],
    },
  },
};

const PageBuilder = (state = initialState, action) => {
  const actionEntity = action.type.split(".")[0];

  if (actionEntity !== entityType) return state;
  const actionType = action.type.split(".")[1];
  const asyncState = action.type.split(".")[2];

  switch (actionType) {
    case SET_OPEN_SIDEBAR: {
      return {
        ...state,
        openSidebar: action.payload,
      };
    }
    case SET_NODE_ID: {
      return {
        ...state,
        nodeId: action.payload,
      };
    }
    case GET_PUBLIC_MENUS: {
      if (asyncState === ActionStates.success) {
        const { menus } = action.payload;
        return {
          ...state,
          menus: {
            result: menus,
            temp: menus,
          },
        };
      }
      return state;
    }
    case PUBLISH_CHANGES: {
      const newState = handleAsyncState(state, action, "publish");
      if (newState) {
        if (asyncState === ActionStates.success) {
          return {
            ...newState,
            dirty: false,
          };
        }
        return newState;
      }
      break;
    }
    case SET_PREVIEW: {
      return {
        ...state,
        preview: action.payload,
      };
    }
    case DISCARD_CHANGES: {
      return {
        ...state,
        dirty: false,
        slider: {
          ...state.slider,
          temp: state.slider.result,
        },
        menus: {
          ...state.menus,
          temp: state.menus.result,
        },
        menuSettings: {
          ...state.menuSettings,
          temp: state.menuSettings.result,
        },
      };
    }
    case GET_SLIDE_SHOW: {
      const newState = handleAsyncState(state, action, "slideShow");
      if (newState) {
        if (asyncState === ActionStates.success) {
          return {
            ...newState,
            slideShow: {
              ...newState.slideShow,
              result: unwrapSlides(newState.slideShow.result),
            },
          };
          // return unwrapSlides(newState)
        }
        return newState;
      }
      break;
    }
    // case
    case ADD_SLIDE: {
      return {
        ...state,
        dirty: true,
        slider: {
          ...state.slider,
          temp: [
            ...state.slider.temp,
            {
              id: makeid(),
              order: state.slider.temp.filter((x) => !x.shouldRemoved).length,
              shouldCreate: true,
              title: "New Slide",
              description: "Slide Description",
              styles: sliderStylesDefault,
            },
          ],
        },
      };
    }
    case EDIT_SLIDE: {
      const { id, prop, value } = action.payload;
      return {
        ...state,
        dirty: true,
        slider: {
          ...state.slider,
          temp: state.slider.temp.map((slider) => {
            if (slider.id === id) {
              return {
                ...slider,
                [prop]: value,
                shouldUpdate: true,
              };
            }
            return slider;
          }),
        },
      };
    }
    case EDIT_SLIDE_STYLES: {
      const { id, prop, value } = action.payload;
      return {
        ...state,
        dirty: true,
        slider: {
          ...state.slider,
          temp: state.slider.temp.map((slider) => {
            if (slider.id === id) {
              return {
                ...slider,
                styles: {
                  ...slider.styles,
                  [prop]: {
                    ...slider.styles[prop],
                    ...value,
                  },
                },
                shouldUpdate: true,
              };
            }
            return slider;
          }),
        },
      };
    }
    case DELETE_SLIDE: {
      const { id } = action.payload;
      const isStored = state.slider.result.find((x) => x.id === id);
      if (isStored) {
        return {
          ...state,
          dirty: true,
          slider: {
            ...state.slider,
            temp: state.slider.temp.map((slide) => {
              if (slide.id === id) {
                return {
                  ...slide,
                  shouldRemoved: true,
                };
              }
              return slide;
            }),
          },
        };
      } else {
        return {
          ...state,
          dirty: true,
          slider: {
            ...state.slider,
            temp: state.slider.temp.filter((x) => x.id !== id),
          },
        };
      }
    }

    case GET_STORED_SETTINGS: {
      if (asyncState === ActionStates.success) {
        const { slides, menuSettings } = action.payload;
        return {
          ...state,
          dirty: false,
          menuSettings: {
            result: menuSettings,
            temp: menuSettings,
          },
          slider: {
            result: unwrapSlides(slides),
            temp: unwrapSlides(slides),
          },
        };
      }
      return state;
    }
    case UPDATE_PUBLIC_MENUS: {
      const { themeMenuId, appMenuId } = action.payload;
      return {
        ...state,
        dirty: true,
        menuSettings: {
          ...state.menuSettings,
          temp: {
            ...state.menuSettings.temp,
            [themeMenuId]: appMenuId,
          },
        },
      };
    }
    case MAS_SLIDE_EDIT: {
      return {
        ...state,
        dirty: true,
        slider: {
          ...state.slider,
          temp: action.payload.map((slide) => ({
            ...slide,
            shouldUpdate: !slide.shouldCreate && !slide.shouldRemoved,
          })),
        },
      };
    }
    case MENU_TRIGGER: {
      return {
        ...state,
        menuTriggerCount: state.menuTriggerCount + 1,
      };
    }
    case UPDATE_HOME_COMPONENT_LIST: {
      return {
        ...state,
        dirty: true,
        homeScreen: {
          ...state.homeScreen,
          temp: action.payload,
        },
      };
    }
    case GET_HOME_COMPONENTS: {
      if (asyncState === ActionStates.success) {
        return {
          ...state,
          homeScreen: {
            result: action.payload,
            temp: action.payload,
          },
        };
      }
      return state;
    }
    case DELETE_HOME_COMPONENT: {
      const index = action.payload;
      return {
        ...state,
        dirty: true,
        homeScreen: {
          ...state.homeScreen,
          temp: [
            ...state.homeScreen.temp.slice(0, index),
            ...state.homeScreen.temp.slice(index + 1),
          ],
        },
      };
    }
    case ADD_HOME_COMPONENT: {
      const component = action.payload;
      return {
        ...state,
        dirty: true,
        homeScreen: {
          ...state.homeScreen,
          temp: [
            ...state.homeScreen.temp,
            {
              ...component,
              id: makeid(),
              order: state.homeScreen.temp.length,
            },
          ],
        },
      };
    }
    case EDIT_HOME_COMPONENT: {
      const { prop, index, value } = action.payload;
      return {
        ...state,
        dirty: true,
        homeScreen: {
          ...state.homeScreen,
          temp: state.homeScreen.temp.map((comp, i) => {
            if (i === index) {
              return {
                ...comp,
                [prop]: value,
              };
            }
            return comp;
          }),
        },
      };
    }
    default:
      return state;
  }
};

export default PageBuilder;

import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { isAdmin } from "../utils";
import ParallaxSlider from "../components/ParallaxSlider";
import { connect } from "react-redux";
import { useMemo } from "react";
import BusyIndicator from "../components/Core/BusyIndicator";
import { appComponent } from "../constants/enums";
import InstagramGrid from "../components/HomeWidgets/InstagramGrid";
import DesignersGrid from "../components/HomeWidgets/DesignersGrid";
import StoriesGrid from "../components/HomeWidgets/StoriesGrid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function getComponent(component, isLast) {
  switch (component.key) {
    case appComponent.INSTAGRAM:
      return <InstagramGrid {...component} hideDivider={isLast} />;
    case appComponent.DESIGNERS:
      return <DesignersGrid {...component} hideDivider={isLast} />;
    case appComponent.STORIES:
      return <StoriesGrid {...component} hideDivider={isLast} />;
    case appComponent.TEXT:
      return (
        <Typography variant={"h6"} style={{ fontSize: 36, fontWeight: "bold" }}>
          {component.name}
        </Typography>
      );
    default:
      return null;
  }
}

function Home(props) {
  const { slideShow, slider, preview, homeScreen } = props.context.PageBuilder;
  const matches = useMediaQuery("(min-width:600px)");

  const propToShow = useMemo(() => {
    return matches ? "showOnWeb" : "showOnMobile";
  }, [matches]);

  const sliderAssets = useMemo(() => {
    if (isAdmin() && preview) {
      return slider.temp.filter((x) => !x.shouldRemoved && x[propToShow]);
    }
    return slideShow.result.filter((x) => x[propToShow]);
  }, [preview, propToShow, slideShow.result, slider.temp]);

  const homeComponents = useMemo(() => {
    if (isAdmin() && preview) {
      return homeScreen.temp;
    }
    return homeScreen.result;
  }, [homeScreen.result, homeScreen.temp, preview]);

  if (slideShow.isLoading && !isAdmin() && !preview) {
    return <BusyIndicator busy />;
  }

  return (
    <Box style={{ marginTop: -60 }}>
      <ParallaxSlider sliderAssets={sliderAssets} />
      {homeComponents.map((component, index) => (
        <Box key={component.id}>
          {getComponent(component, index === homeComponents.length - 1)}
        </Box>
      ))}
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      PageBuilder: state.PageBuilder,
    },
  };
};
export default connect(mapStateToProps, {})(Home);

import BaseContent from "../../components/Core/BaseContent";
import { Box, Button, Grid } from "@material-ui/core";
import image from "../../assets/images/home-our-mission.jpeg";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";

const sub =
  "WHETHER YOU ARE JUST STARTING OR ARE ESTABLISHED, FREDDIE IS HERE AS AN ALLY TO HELP AND GROW YOUR BUSINESS.";

export default function About() {
  return (
    <BaseContent title={"MEMBERSHIP"} subtitle={sub}>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <img style={{ width: "100%" }} src={image} alt={"membership"} />
        </Grid>
        <Grid item md={6} sm={12}>
          <Box p={4}>
            <Grid container direction={"column"} spacing={2}>
              <Grid item>
                <Typography variant={"h2"}>Our Mission</Typography>
              </Grid>
              <Grid item>
                <Typography component={"p"}>
                  Freddie was created to highlight the innovation and passion of
                  the design community by equipping designers with the resources
                  and space they need to connect, collaborate, and flourish.
                </Typography>
              </Grid>
              <Grid item>
                <Typography component={"p"}>
                  Our goal is to aid designers with brand development and
                  growth, coupled with bridging the many creatives in our
                  industry.
                </Typography>
              </Grid>
              <Grid item>
                <Button variant={"contained"} color={"primary"} size={"large"} component={Link} to={"/join"}>
                  Join Us
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </BaseContent>
  );
}

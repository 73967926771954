import React from "react";
import { Grid } from "@material-ui/core";
import Section from "../Section";
import { useFetch } from "reactcoregk/hooks";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";
import { getDefaultHeaders } from "../../utils";
import { Paging } from "reactcoregk/models";
import StoryCard from "../StoryCard/StoryCard";

function StoriesGrid(props) {
  const size = props.settings.size || 3;
  const fullWidth = props.settings.fullWidth || false;
  const [loadingStories, stories] = useFetch(
    `${ApiEndpoint[EntityType.Article]}?items=${size}`,
    getDefaultHeaders(),
    new Paging()
  );
  return (
    <Section title={props.name} maxWidth={fullWidth ? "xl" : "lg"} hideDivider={props.hideDivider}>
      <Grid container spacing={2}>
        {loadingStories
          ? [...Array(10)].map((x, i) => (
              <Grid item sm={6} xs={12} key={i}>
                {" "}
                <StoryCard isLoading={true} key={i} />
              </Grid>
            ))
          : stories.content?.map((story, i) => (
              <Grid item sm={6} xs={12} key={i}>
                <StoryCard story={story} />
              </Grid>
            ))}
      </Grid>
    </Section>
  );
}

export default StoriesGrid;

import * as colors from "./colors";
import { errorColor } from "./colors";
import { createMuiTheme } from "@material-ui/core/styles";
import {useMemo} from "react";
// import { useMediaQuery } from "@material-ui/@core";
const {breakpoints} = createMuiTheme({ })

const overrides = () => {
  return {
    MuiPaper: {
      rounded: {
        borderRadius: 0,
      },
    },
    MuiFilledInput: {
      underline: {
        borderRadius: 0,
      },
    },
    MuiInputLabel: {
      root: {
        [breakpoints.down("sm")]: {
          // overflow: "hidden",
          // textOverflow: "ellipsis",
          whiteSpace: "wrap",
          maxWidth: "calc(100% - 12px)"
        }
      },
      shrink: {
        [breakpoints.down("sm")]: {
          // overflow: "auto",
          // textOverflow: "initial",
          // whiteSpace: "break-spaces",
          maxWidth: "100%"
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
    MuiFormHelperText: {
      root: {
        margin: 0,
        '&$error': {
          backgroundColor: colors.errorColor.main,
          color: "white",
          margin: 0,
          padding: "2px 8px",
        }
      },
      error: {}
    },
    MuiError: {
      backgroundColor: "red",
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
      label: {
        fontFamily: "Montserrat, sans-serif",
        textTransform: "uppercase",
      },
    },
  };
};

const typography = () => {
  return {
    fontFamily: ["Montserrat, sans-serif", "Source Sans Pro, sans-serif"],
    h2: {
      fontFamily: "Source Sans Pro, sans-serif"
    },
    h3: {
      fontFamily: "Playfair Display, sans-serif",
      [breakpoints.down("md")]: {
        fontSize: "2.5rem"
      },
      [breakpoints.down("xs")]: {
        fontSize: "1.5rem"
      }
    },
    h4: {
      fontFamily: "Playfair Display, sans-serif",
    },
    h6: {
      fontFamily: "Avenir, sans-serif"
    },
    body1: {
      fontFamily: "Avenir, sans-serif",
      // fontSize: 14,
    },
  };
};

export function getTheme(prefersDarkMode, customOverrides) {
  return createMuiTheme({
    palette: {
      type: prefersDarkMode ? "dark" : "light",
      primary: prefersDarkMode ? colors.primaryDark : colors.primaryLight,
      error: errorColor,
      secondary: prefersDarkMode
          ? colors.secondaryDark
          : colors.secondaryLight,
      background: prefersDarkMode
          ? colors.backgroundDark
          : colors.backgroundLight,
    },
    overrides: {...overrides(prefersDarkMode), ...customOverrides},
    typography: typography(prefersDarkMode),
  })
}

function useAppTheme(customOverrides) {
  const prefersDarkMode = false;
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return useMemo(() => getTheme(prefersDarkMode, customOverrides),
    [customOverrides, prefersDarkMode]
  );
}

export { colors, useAppTheme };

import BaseContent from "../../components/Core/BaseContent";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

export default function CommunityGuidelines() {
  return (
    <BaseContent title={"Community Guidelines"}>
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Freddie’s mission is to inspire creativity and bring joy. We are
        building a global community where people can create and share, discover
        the world around them, and connect with others across the globe through
        the lens of design. As we grow, we are committed to maintaining a
        supportive environment for our community. Our Community Guidelines
        define a set of norms and common code of conduct for Freddie; they
        provide guidance on what is and is not allowed to make a welcoming space
        for everyone.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        At Freddie, we prioritize safety, diversity, inclusion, and
        authenticity. We encourage members to celebrate what makes them unique
        and viewers to engage in what inspires them; and we believe that a safe
        environment helps everyone express themselves openly.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Our Community Guidelines apply to everyone and to everything on Freddie,
        and your use of the Services and the Site constitute your express
        consent to these Community Guidelines. We proactively enforce them using
        a mix of technology and human moderation before content gets reported to
        us. For these reasons, Freddie has the right to monitor accounts to
        create an authentic environment that highlight the work of interior
        designers. Freddie has the right to suspend accounts or remove images
        should they misrepresent and not rightfully show their own original
        content. Freddie encourages creativity amongst members and creative
        expression. Should there be abuse or harassment on Freddie, you are in
        violation of the inclusive and collaborative community that Freddie
        stands for.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Individuals will be notified of our decisions and can appeal if they
        believe no violation has occurred. We will suspend or ban members that
        are involved in severe or repeated violations; when warranted, we will
        report the accounts to relevant legal authorities. From time to time we
        will update our Community Guidelines to evolve as part of our commitment
        to keeping Freddie a safe place for creativity and joy.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        <b>No hateful Content:</b> We don’t allow content that attacks, denigrates,
        intimidates, dehumanizes, incites or threatens hatred, violence,
        prejudicial or discriminatory action against individuals or groups
        because of their actual or perceived race, ethnicity, national origin,
        caste, gender, gender identity, sexual orientation, religious
        affiliation, or disability status. Hate groups are not permitted. Do not
        use racial, religious, or other slurs that incite or promote hatred, or
        any other content intended to create division.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        <b>No Sexual Harassment or Advances:</b> We don’t allow unwanted expressions of
        attraction, desire, requests for a romantic relationship, sexual
        advances or innuendo, or lewd remarks. Do not use Freddie to pursue
        romantic connections, ask for romantic dates, or provide sexual
        commentary on someone’s appearance.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        <b>No Harassment or Bullying:</b> We don’t allow bullying or harassment. This
        includes abusive language, revealing others’ personal or sensitive
        information (aka “doxing”), or inciting or engaging others to do any of
        the same.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        <b>No Violence or Threats:</b> We don’t allow threatening or inciting violence
        of any kind. We don’t allow individuals or groups that engage in or
        promote violence, property damage, or organized criminal activity. You
        may not use Freddie to express support for such individuals or groups or
        to otherwise glorify violence.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        <b>No Harmful or Offensive Material:</b> We don’t allow content that is
        excessively gruesome or shocking. This includes content that is sadistic
        or gratuitously graphic, such as the depiction of severe physical
        violence. We don’t allow content or activities that promote, organize,
        depict, or facilitate criminal activity. We also don’t allow content
        depicting or promoting instructional weapon making, drug abuse, and
        threats of theft. Do not engage in or promote non-consensual sexually
        explicit content (e.g., revenge porn), escort services, prostitution,
        exploitation of children, or human trafficking. Do not share content or
        activities that promote or encourage suicide or any type of self-injury,
        including self-mutilation and eating disorders. If you see signs that
        someone may be considering self-harm, please report it.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        <b>No False or Misleading Content:</b> Do not share content in a way that you
        know is, or think may be, misleading or inaccurate, including
        misinformation or disinformation. Do not share content to interfere with
        or improperly influence an election or another civic process. We may
        prevent you from posting content from sites that are known to produce or
        contain misinformation. Do not post “deepfake” images or videos of
        others or otherwise post content that has been manipulated to deceive.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        <b>No Fake Profiles:</b> We don’t allow fake profiles or entities. Do not post
        misleading or deceptive information about yourself, your business, your
        qualifications, work experience, affiliations, or achievements. Do not
        use an image of someone else, or any other image that is not your
        likeness, for your profile photo. Do not associate yourself with a
        business or organization that you are not actually associated with. Do
        not use or attempt to use another person’s account or create a member
        profile for anyone other than yourself.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        <b>No Spam or Scams:</b> We don’t allow untargeted, irrelevant, obviously
        unwanted, unauthorized, inappropriately commercial or promotional, or
        gratuitously repetitive messages or other similar content. Do not use
        our invitation feature to send promotional messages to people you don’t
        know or to otherwise spam people. Please make the effort to create
        original, professional, relevant, and interesting content in order to
        gain popularity, instead of trying ways to artificially increase the
        number of views, shares, likes, or comments. Respond authentically to
        others’ content and don’t agree with others ahead of time to like or
        re-share each other’s content. Pyramid Schemes, chain letters, phishing
        schemes, or any other scams are also prohibited.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Respect others’ privacy: You may not solicit email addresses or other
        personal information from other members you don’t know without
        authorization; use, disclose or distribute any data obtained in
        violation of any of our policies or agreements; disclose information
        that you don’t have the right to disclose (such as personal or
        confidential information of others, including your employer); or post or
        distribute content depicting children without the necessary consent from
        their legal guardian or parent. Don’t use others’ names, likenesses, or
        photos to suggest an endorsement of a product, service, or investment
        without their prior consent.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Comply with the law: You must comply with all applicable laws,
        including, for example, privacy laws, intellectual property laws,
        anti-spam laws, export control laws, tax laws, and regulatory
        requirements. Do not use the Services to create or operate a pyramid
        scheme, engage in fraud, or to sell or promote illegal products,
        including drugs.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Comply with the law: You must comply with all applicable laws,
        including, for example, privacy laws, intellectual property laws,
        anti-spam laws, export control laws, tax laws, and regulatory
        requirements. Do not use the Services to create or operate a pyramid
        scheme, engage in fraud, or to sell or promote illegal products,
        including drugs.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Respect the intellectual property of others and do not violate the
        intellectual property rights of others: We respect the intellectual
        property rights of others. Do not violate the intellectual property
        rights of others, including copyrights, patents, trademarks, trade
        secrets, or other proprietary rights. For example, do nNo hateful Contentot copy or
        distribute (except through the available sharing functionality) the
        posts or other content of others without their permission, which they
        may give by posting under a Creative Commons license.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        <b>No Unauthorized Use or Access:</b> Access Freddie only in the manner that we
        expressly authorize, for the tasks that it is intended for. Do not
        access Freddie except through the interfaces intentionally provided by
        us, such as joinfreddie.com. Any use of or attempt to retrieve source
        code that is not public is unauthorized.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        No Interference with the Functionality of the Site or Services: Do not
        interfere with the functionality of, or our rights in, the Freddie
        service. Do not remove any copyright, trademark, or other proprietary
        rights notices contained in or on any Service.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        <b>Respect our rules:</b>  You must follow the spirit and letter of our rules,
        agreements, and policies. Do not violate any additional terms concerning
        the specific Freddie services that are provided when you sign up for or
        start using such services.
      </Typography>
      <Box mt={2} />
    </BaseContent>
  );
}

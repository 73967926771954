import BaseContent from "../../components/Core/BaseContent";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { compareProp } from "reactcoregk/utils";
import { groupBy, isAdmin } from "../../utils";
import Grid from "@material-ui/core/Grid";
import { useDependency } from "reactcoregk/hooks";
import { useMemo } from "react";
import { connect } from "react-redux";
import { getAllGlossaries } from "../../store/glossary/actions";
import { Skeleton } from "@material-ui/lab";
import AnchorLink from "react-anchor-link-smooth-scroll";

const useStyles = makeStyles({
  subtitle: {
    marginBottom: 24,
    fontSize: 24,
    marginRight: 24,
    textDecoration:'none',
    color:'black',
    zIndex: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  initial: {
    margin: "auto",
    fontSize: 24,
    marginBottom: 24,
    zIndex: -1,
  },
});

function GlossaryItem({ initial, group, isLoading }) {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item style={{ minWidth: 100}}>
        {isLoading ? (
          <Skeleton variant={"text"} height={48} width={36} />
        ) : (
          <Typography
            // style={{
            //   paddingTop: isAdmin() ? 120 : 0,
            //   marginTop: -isAdmin() ? -120 : 0,
            // }}
            className={classes.initial}
            id={initial.toLowerCase()}
          >
            {initial}
          </Typography>
        )}
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Grid container spacing={2}>
          {isLoading
            ? [...Array(4)].map((k, i) => (
                <Grid item sm={6} xs={12}>
                  <Typography variant={"body1"}>
                    <Skeleton variant={"text"} width={100} />
                  </Typography>
                  <Skeleton variant={"rect"} width={"100%"} height={120} />
                </Grid>
              ))
            : group.map((section) => (
                <Grid item sm={6} xs={12}>
                  <Typography variant={"body1"}>
                    <b>{section.term}</b>
                  </Typography>
                  <Typography>{section.description}</Typography>
                </Grid>
              ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

function formatGlossaries(glossaries) {
  return glossaries
    .sort((a, b) => compareProp(a, b, "term"))
    .map((x) => ({
      ...x,
      initial: x.term.charAt(0),
    }));
}

function DecoratingGlossary({ context, getAllGlossaries }) {
  const classes = useStyles();

  useDependency(context.Glossary, getAllGlossaries);
  const glossaries = context.Glossary.getAll.result;
  const isLoading = context.Glossary.getAll.isLoading;

  const mapped = useMemo(() => {
    const sortedGlossaries = formatGlossaries(glossaries);
    const grouped = groupBy(sortedGlossaries, "initial");
    const initials = Object.keys(grouped).map((key) => key);

    return {
      initials,
      grouped,
    };
  }, [glossaries]);

  return (
    <BaseContent title={"Decorating Glossary"}>
      <Box display={"flex"} flexWrap={"nowrap"} overflow={'auto'}>
        {!isLoading ? (
          mapped.initials.map((letter) => (
            <AnchorLink
                offset={isAdmin?'130':'70'}
                href={`#${letter}`}
                className={classes.subtitle}
            >
              <Typography variant={"body1"}>{letter}</Typography>
            </AnchorLink>
          ))
        ) : (
          <Skeleton variant={"text"} width={400} height={32} />
        )}
      </Box>
      <Box mt={4}>
        <Grid container direction={"column"} spacing={4}>
          {isLoading
            ? [...Array(20)].map((k, i) => (
                <Grid item>
                  <GlossaryItem isLoading />
                </Grid>
              ))
            : mapped.initials.map((initial) => (
                <Grid item id={initial}>
                  <GlossaryItem
                    initial={initial}
                    group={mapped.grouped[initial]}
                  />
                </Grid>
              ))}
        </Grid>
      </Box>
    </BaseContent>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Glossary: state.Glossary,
    },
  };
};
export default connect(mapStateToProps, { getAllGlossaries })(
  DecoratingGlossary
);
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Avatar, Box, CardActions } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import IconButton from "@material-ui/core/IconButton";
import { EmailOutlined } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles({
  media: {
    height: 150,
    paddingTop: "56.25%", // 16:9
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    marginTop: -40,
    width: 80,
    height: 80,
  },
  text: {
    width: "100%",
    textAlign: "center",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "space-around",
  },
  editable: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
});
function Editable(props) {
  const classes = useStyles();
  const { isEditing, ...rest } = props;
  return isEditing ? (
    <Box className={classes.editable} {...rest}>
      {props.children}
    </Box>
  ) : null;
}

export default function DesignerPreviewCard({
  designer,
  profileImage,
  featureImage,
}) {
  const classes = useStyles();
  return (
    <Card variant={"outlined"}>
      {!featureImage ? (
        <div style={{ position: "relative" }}>
          <Skeleton variant="rect" className={classes.media} />
          <Editable isEditing>
            <Typography style={{ fontWeight: "bold" }}>
              Header
            </Typography>
          </Editable>
        </div>
      ) : (
        <CardMedia
          className={classes.media}
          image={featureImage.preview}
          title="Paella dish"
        />
      )}
      <Box style={{ display: "flex", justifyContent: "center" }}>
        {!profileImage ? (
          <div style={{ position: "relative" }}>
            <Skeleton variant="circle" className={classes.avatar} />
            <Editable isEditing>
              <Typography style={{ marginTop: -40, fontWeight: "bold" }}>
                Profile
              </Typography>
            </Editable>
          </div>
        ) : (
          <Avatar
            alt="PAB NAZA"
            src={profileImage.preview}
            className={classes.avatar}
          />
        )}
      </Box>
      <CardContent className={classes.container}>
        {!designer ? (
          <>
            <Skeleton variant="text" width={200} height={30} />
            <Skeleton variant="text" width={150} height={20} />
            <Skeleton variant="text" width={100} height={20} />
          </>
        ) : (
          <>
            <Typography
              variant="h6"
              component="h4"
              noWrap
              className={classes.text}
            >
              {`${designer.firstName} ${designer.lastName}`}
            </Typography>
            <Typography noWrap className={classes.text}>
              {designer.businessName}
            </Typography>
            <Typography noWrap className={classes.text}>
              {designer.state}
            </Typography>
          </>
        )}
      </CardContent>
      <CardActions disableSpacing className={classes.btnGroup}>
        {/*<IconButton aria-label="see more">*/}
        {/*  <Info />*/}
        {/*</IconButton>*/}
        <IconButton aria-label="contact">
          <EmailOutlined />
        </IconButton>
        {/*<IconButton aria-label="add to favorites">*/}
        {/*  <FavoriteBorderOutlined />*/}
        {/*</IconButton>*/}
      </CardActions>
    </Card>
  );
}

import {
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import BaseContent from "../../components/Core/BaseContent";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { getParameterByName } from "../../utils";
import { postData } from "reactcoregk/utils";
import { API_URL } from "../../config";
import IconTwist from "../../components/Core/IconWist";

export default function VerifyAccount() {
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const token = getParameterByName("token");
    postData(`${API_URL}/account/confirm`, { token })
      .then(() => {
        setVerified(true);
        setLoading(false);
      })
      .catch((ex) => {
        setVerified(false);
        setError(ex.message);
        setLoading(false);
      });
  }, []);

  return (
    <BaseContent title={"Verify Account"}>
      <Container maxWidth={"sm"}>
        <Grid
          container
          direction={"column"}
          spacing={2}
          justify={"center"}
          alignItems={"center"}
        >
          <Grid item md={12}>
            {loading && <CircularProgress />}
            {!loading && (
              <IconTwist>
                {verified ? (
                  <CheckCircleOutlineOutlinedIcon />
                ) : (
                  <ErrorOutlineOutlinedIcon />
                )}
              </IconTwist>
            )}
          </Grid>
          <Grid item>
            {!loading && (
              <motion.div animate={{ scale: 1 }} transition={{ duration: 0.5 }}>
                <Typography>
                  {verified
                    ? "Your account has been verified successfully"
                    : error}
                </Typography>
              </motion.div>
            )}
          </Grid>
        </Grid>
      </Container>
    </BaseContent>
  );
}

// Login Method
import {handleError} from "reactcoregk/store/api";
import {fetchData, updateData} from "../../utils";
import {API_URL} from "../../config";
import {makeid} from "reactcoregk/utils";

export const getSlides = async () => {
  const url = API_URL + "/manage/home-page";
  return fetchData(url)
    .then((data) => data.content)
    .catch((ex) => handleError(ex));
};

export const getMenus = async () => {
  const url = API_URL + "/menus";
  return fetchData(url).catch((ex) => handleError(ex));
};

export const getMenuSettings = async () => {
  const url = API_URL + "/manage/menus/settings";
  return fetchData(url).catch((ex) => handleError(ex));
};

export const updateMenuSettings = async (data) => {
  const url = API_URL + "/manage/menus/settings";
  return updateData(url, data).catch((ex) => handleError(ex));
};

export const getSlideShow = async () => {
  const url = API_URL + "/home-page";
  return fetchData(url)
    .catch((ex) => handleError(ex));
};

const safeParse = (payload) => {
  try {
    return JSON.parse(payload)
  } catch (e) {
    return {}
  }
}

export const homeComponents = async () => {
  const url = API_URL + "/home-settings";
  return fetchData(url)
      .then(data => data.map(x => ({
        ...x,
        id: makeid(),
        settings: safeParse(x.settings)
      })))
    .catch((ex) => handleError(ex));
};

export const updateHomePageSettings = async (data) => {
  const url = API_URL + "/manage/home-settings";
  const homeSettings = data.map(component => ({
    ...component,
    settings: JSON.stringify(component.settings)
  }))
  return updateData(url, {homeSettings}).catch((ex) => handleError(ex));
};
export function mapRSlide(slide) {
  const formData = new FormData();
  const { file, ...data } = slide;
  formData.append("file", file);
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], {
      type: "application/json",
    })
  );
  return formData;
}

import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { entityType } from "./module";
import {
  GET_HOME_COMPONENTS,
  GET_PUBLIC_MENUS,
  GET_SLIDE_SHOW,
  GET_STORED_SETTINGS,
  PUBLISH_CHANGES,
} from "./actionTypes";
import {
  getCustomSettings,
  getCustomSettingsActions, getHomePageComponents,
  getHomePageComponentsActions,
  getNavigation,
  getNavigationActions,
  getSlider,
  getSlideShowActions,
  publishCustomSettingsActions,
} from "./actions";
import {
  getMenus,
  getMenuSettings,
  getSlides,
  getSlideShow,
  homeComponents,
  mapRSlide,
  updateHomePageSettings,
  updateMenuSettings,
} from "./api";
import { ActionStates, buildAsyncActionType } from "reactcoregk/store/actions";
import { deleteData, postData, updateData, wrapSlides } from "../../utils";
import { API_URL } from "../../config";

function* fetchSlideShow() {
  try {
    const slideShow = yield call(getSlideShow);
    yield put(getSlideShowActions.success(slideShow));
  } catch (error) {
    yield put(getSlideShowActions.failure(error.message));
  }
}

function* fetchHomeComponents() {
  try {
    const homeScreen = yield call(homeComponents);
    yield put(getHomePageComponentsActions.success(homeScreen));
  } catch (error) {
    yield put(getHomePageComponentsActions.failure(error.message));
  }
}

function* fetchAllSettings() {
  try {
    const slides = yield call(getSlides);
    const menuSettings = yield call(getMenuSettings);
    yield put(getCustomSettingsActions.success({ slides, menuSettings }));
  } catch (error) {
    yield put(getCustomSettingsActions.failure(error.message));
  }
}

function* publishChanges({ payload: { context } }) {
  try {
    const url = API_URL + "/manage/home-page";
    const slides = context.slider.temp;
    const edited = wrapSlides(
      slides.filter((x) => x.shouldUpdate && !x.shouldCreate)
    );
    const deleted = slides.filter((x) => x.shouldRemoved);
    const created = wrapSlides(slides.filter((x) => x.shouldCreate));
    const updatedMenus = context.menuSettings.temp;
    const updatedHomeSettings = context.homeScreen.temp;
    yield call(updateMenuSettings, updatedMenus);
    yield call(updateHomePageSettings, updatedHomeSettings);
    yield all(created.map((data) => call(postData, url, mapRSlide(data))));
    yield all(edited.map((data) => call(updateData, `${url}/${data.id}`, mapRSlide(data))));
    yield all(deleted.map((data) => call(deleteData, `${url}/${data.id}`)));
    yield put(getCustomSettings());
    yield put(getSlider());
    yield put(getNavigation());
    yield put(getHomePageComponents());
    yield put(publishCustomSettingsActions.success({}));
  } catch (error) {
    yield put(publishCustomSettingsActions.failure(error.message));
  }
}

export function* watchPublicChange() {
  const actionType = buildAsyncActionType(
    entityType,
    PUBLISH_CHANGES,
    ActionStates.request
  );
  yield takeEvery(actionType, publishChanges);
}

export function* watchStoredSettings() {
  const actionType = buildAsyncActionType(
    entityType,
    GET_STORED_SETTINGS,
    ActionStates.request
  );
  yield takeEvery(actionType, fetchAllSettings);
}

export function* watchSlideShow() {
  const actionType = buildAsyncActionType(
    entityType,
    GET_SLIDE_SHOW,
    ActionStates.request
  );
  yield takeEvery(actionType, fetchSlideShow);
}

export function* watchHomeSettings() {
  const actionType = buildAsyncActionType(
    entityType,
    GET_HOME_COMPONENTS,
    ActionStates.request
  );
  yield takeEvery(actionType, fetchHomeComponents);
}

function* fetchNavigation() {
  try {
    const menus = yield call(getMenus);
    yield put(getNavigationActions.success({ menus }));
  } catch (error) {
    yield put(getNavigationActions.failure(error.message));
  }
}

export function* watchNavigation() {
  const actionType = buildAsyncActionType(
    entityType,
    GET_PUBLIC_MENUS,
    ActionStates.request
  );
  yield takeEvery(actionType, fetchNavigation);
}

function* sagas() {
  yield all([fork(watchPublicChange)]);
  yield all([fork(watchStoredSettings)]);
  yield all([fork(watchSlideShow)]);
  yield all([fork(watchNavigation)]);
  yield all([fork(watchHomeSettings)]);
}

export default sagas;

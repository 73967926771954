import {Container, Grid, Typography} from "@material-ui/core";
import BaseContent from "../../components/Core/BaseContent";
import {Link, Redirect} from "react-router-dom";
import * as Yup from "yup";
import {Formik} from "formik";
import {loginForm} from "../../constants/forms";
import {useEffect} from "react";
import {connect} from "react-redux";
import {apiError, loginUser} from "../../store/auth/login/actions";
import FormContainer from "../../components/FormContainer";
import {getParameterByName} from "../../utils";

function Login(props) {
  const { apiError, loginUser, error, loading, history, isAuth } = props;

  useEffect(() => {
    apiError("");
  }, [apiError]);

  if (isAuth) {
    return <Redirect to={"/"} />;
  }
  const redirectionPath = getParameterByName("redirect")

  return (
    <BaseContent title={"Log in"} errorMessage={error}>
      <Container maxWidth={"sm"}>
        <Grid
          container
          direction={"row"}
          spacing={2}
          justify={"center"}
          alignItems={"center"}
        >
          <Formik
            initialValues={{}}
            onSubmit={(values) => loginUser(values, history, redirectionPath)}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email")
                .required("This field is required"),
              password: Yup.string()
                .min(8, "At least 8 characters")
                .required("This field is required"),
            })}
          >
            {(formik) => (
              <FormContainer
                form={loginForm}
                busy={loading}
                submitLabel={"Log In"}
              />
            )}
          </Formik>
          <Grid item container>
            <Typography
              color={"primary"}
              component={Link}
              className={"hyperlink"}
              to={"/forgot-password"}
            >
              Forgot your password?
            </Typography>
          </Grid>
          <Grid item container>
            <Typography
              color={"primary"}
              component={Link}
              className={"hyperlink"}
              to={"/join"}
            >
              Don't have an account? Create one
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </BaseContent>
  );
}
const mapStateToProps = (state) => {
  const { error, loading, isAuth } = state.Auth;
  return { error, loading, UserSetting: state.UserSetting, isAuth };
};

export default connect(mapStateToProps, { loginUser, apiError })(Login);

import styled from "styled-components";
import {
  Button,
  ClickAwayListener,
  Divider, FormControlLabel,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper, Switch,
  TextField,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import React, { useState } from "react";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import {
  addHomeComponent,
  deleteHomeComponent,
  editHomeComponent,
  updateHomeComponentList,
} from "../../store/pageBuilder/actions";
import { DragHandle } from "@material-ui/icons";
import { appComponent, appComponents } from "../../constants/enums";
import ErrorButton from "../ErrorButton";

const grid = 8;

const DraggableComponentItem = styled.div`
  margin-bottom: ${grid}px;
  padding: ${0}px;
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// function LoadingState() {
//   return [...Array(4)].map((k, i) => (
//     <Paper variant={"outlined"} style={{ marginBottom: 8 }}>
//       <ListItem>
//         <Skeleton variant={"text"} width={"100%"} height={32} />
//       </ListItem>
//     </Paper>
//   ));
// }

const getTitle = (component) => {
  switch (component.key) {
    case appComponent.STORIES:
      return "Stories";
    case appComponent.DESIGNERS:
      return "Designers";
    case appComponent.INSTAGRAM:
      return "Instagram";
    case appComponent.TEXT:
      return "Text";
    default:
      return component.name;
  }
};
function ComponentItem({ component, handleDelete, handleEdit, index }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Paper variant={"outlined"} style={{ marginBottom: 8 }}>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <DragHandle />
        </ListItemIcon>
        <ListItemText
          primary={getTitle(component)}
          secondary={component.name}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Divider />
        <ListItem
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <TextField
            fullWidth
            margin={"normal"}
            label={"Title"}
            value={component.name}
            onChange={(e) => {
              const value = e.target.value;
              const prop = "name";
              handleEdit({ index, prop, value });
            }}
          />
          {component.key !== appComponent.TEXT && component.key !== appComponent.DESIGNERS && (
            <TextField
              fullWidth
              margin={"normal"}
              label={"Total elements"}
              type={"number"}
              value={component.settings.size}
              onChange={(e) => {
                const size = e.target.value;
                const value = { ...component.settings, size };
                const prop = "settings";
                handleEdit({ index, prop, value });
              }}
            />
          )}
          <FormControlLabel
              control={
                <Switch
                    checked={component.settings.fullWidth}
                    onChange={(e) => {
                      const fullWidth = e.target.checked;
                      const value = { ...component.settings, fullWidth };
                      const prop = "settings";
                      handleEdit({ index, prop, value });
                    }}
                    name="fullWidth"
                    color="primary"
                />
              }
              label="Full Width"
          />
          <Box mt={2} />
          <ErrorButton onClick={() => handleDelete(index)}>Remove</ErrorButton>
        </ListItem>
      </Collapse>
    </Paper>
  );
}

function DraggableComponent(props) {
  return (
    <Draggable draggableId={`id-${props.component.id}}`} index={props.index}>
      {(provided) => (
        <DraggableComponentItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ComponentItem {...props} />
        </DraggableComponentItem>
      )}
    </Draggable>
  );
}

const ComponentsList = React.memo(function ComponentsList({
  components,
  ...rest
}) {
  return components.map((component, index) => (
    <DraggableComponent
      component={component}
      index={index}
      key={component.id}
      {...rest}
    />
  ));
});

function HomeScreen(props) {
  const {
    context,
    editHomeComponent,
    deleteHomeComponent,
    updateHomeComponentList,
    addHomeComponent,
  } = props;
  const homeComponents = context.PageBuilder.homeScreen.temp;

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newComponents = reorder(
      homeComponents,
      result.source.index,
      result.destination.index
    );

    newComponents.forEach((entry, index) => {
      entry.order = index;
    });
    updateHomeComponentList(newComponents);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleComponentSelect = (component) => {
    handleClose();
    addHomeComponent(component);
  };

  return (
    <Box px={2}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <ComponentsList
                components={homeComponents}
                handleEdit={editHomeComponent}
                handleDelete={deleteHomeComponent}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Button variant={"outlined"} fullWidth onClick={handleClick}>
          Add component
        </Button>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          role={undefined}
          transition
          placement={"bottom-end"}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={Boolean(anchorEl)}
                    id="menu-list-grow"
                  >
                    {appComponents.map((component) => (
                      <MenuItem
                        key={component.id}
                        onClick={() => handleComponentSelect(component)}
                      >
                        {component.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </DragDropContext>
    </Box>
  );
}
const mapStateToProps = (state) => {
  return {
    context: {
      PageBuilder: state.PageBuilder,
    },
  };
};
export default connect(mapStateToProps, {
  addHomeComponent,
  editHomeComponent,
  deleteHomeComponent,
  updateHomeComponentList,
})(HomeScreen);

import { combineReducers } from "redux";
import RegisterList from "./registerList/reducer";
import Auth from "./auth/login/reducer";
import Glossary from "./glossary/reducer";
import PageBuilder from "./pageBuilder/reducer";
import Menu from "./menu/reducer";
import Page from "./page/reducer";
// Authentication

const rootReducer = combineReducers({
  RegisterList,
  Auth,
  Glossary,
  PageBuilder,
  Menu,
  Page
});

export default rootReducer;

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useState } from "react";
import BaseContent from "../../components/Core/BaseContent";
import ChangePassword from "./ChangePassword";
import EditProfile from "./EditProfile";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {getUserId, isDesigner} from "../../utils";
import {Redirect} from "react-router-dom";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flex: 1 }}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabSelected: {
    backgroundColor: "black",
    color: "white",
  },
}));

export default function Account() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const matches = useMediaQuery("(min-width:720px)");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isDesigner()) {
    return <Redirect to={`/designer/${getUserId()}`}/>
  }

  return (
    <BaseContent
      title={"My Account"}
      successMessage={successMessage}
      errorMessage={errorMessage}
    >
      <div
        className={classes.root}
        style={{ display: matches ? "inherit" : "block" }}
      >
        <Tabs
          orientation={matches ? "vertical" : "horizontal"}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab
            label="Change Password"
            {...a11yProps(0)}
            classes={{
              selected: classes.tabSelected,
            }}
          />
          <Tab
            label="Edit your Info"
            {...a11yProps(1)}
            classes={{
              selected: classes.tabSelected,
            }}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <ChangePassword
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EditProfile
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />
        </TabPanel>
      </div>
    </BaseContent>
  );
}

import { Button, Grid, Typography } from "@material-ui/core";
import BaseContent from "../../components/Core/BaseContent";
import { Link } from "react-router-dom";

export default function JoinUs() {
  return (
    <BaseContent title={"Join Us"}>
      <Grid container spacing={4} alignItems={"center"}>
        <Grid item md={6} container alignItems={"center"} justify={"center"}>
          <Typography variant={"h4"} style={{ textAlign: "center"}}>FOR DESIGNERS</Typography>
          <Typography component={"p"} style={{ textAlign: "center", margin: 24 }}>
            Join our new interior designer network focused on providing you the
            tools to thrive. From brand building to connections with clients,
            Freddie is here to grow your business. And for the first year, <b>we’re
            making membership complimentary</b>.
          </Typography>
          <Button
            color={"primary"}
            variant={"contained"}
            size={"large"}
            component={Link}
            to={"/join/designer"}
          >
            Join as a designer
          </Button>
        </Grid>
        <Grid item md={6} container alignItems={"center"} justify={"center"}>
          <Typography variant={"h4"} style={{ textAlign: "center"}}>FOR DESIGN ENTHUSIAST</Typography>
          <Typography component={"p"} style={{ textAlign: "center", margin: 24  }}>
            Sign up and get ready to receive exclusive newsletters featuring
            fresh interior inspo, pro tips and insights, and connect with a
            member of our interior designer community to make your design dream a
            reality. Check back daily for new stories decoding our members’
            latest projects.
          </Typography>
          <Button
            color={"primary"}
            variant={"contained"}
            size={"large"}
            component={Link}
            to={"/join/enthusiast"}
          >
            Join as a design enthusiast
          </Button>
        </Grid>
      </Grid>
    </BaseContent>
  );
}

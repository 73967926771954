import {useCallback, useEffect, useMemo, useState} from "react";
import {useSnackbar} from "notistack";
import {deleteData, fetchData, getUserId, updateData} from "../../../utils";
import {useImage} from "../../../hooks";
import profileDefault from "../../../assets/images/profile-filler.png";
import {ApiEndpoint} from "../../../store/@core/endpoint";
import {EntityType} from "../../../store/@core/entityType";
import {buildUserDetails} from "../../Auth/JoinUsDesigner";
import {API_URL} from "../../../config";
import {formatBytes} from "reactcoregk/utils";


const designerViewModel = {
    firstName: "",
    lastName: "",
    email: "",
    profileImageId: "",
    featureImageId: "",
    consultation: "",
    businessWebSite: "",
    instagram: null,
    bio: null,
    since: null,
    specialties: [],
    designStyles: [],
    adviceOffers: [],
    portfolioImageIds: [],
};

const designerEditModel = {
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    instagram: "",
    professionalCategoryId: null,
    bio: "",
    since: "",
    businessName: "",
    businessWebSite: "",
    businessTypeId: null,
    employeesRangeId: null,
    businessEstablished: "",
    streetAddress: "",
    streetAddress2: "",
    city: "",
    stateId: null,
    zipCode: "",
    associations: "",
    consultationId: null,
    minimumProjectSpendId: null,
    averageFurnishingsSpendId: null,
    averageFabricWallpaperSpendId: null,
    averageCarpetsRugsSpendId: null,
    optInNewsletter: true,
    optInDirectMail: true,
    approved: true,
    profileImageId: "",
    featureImageId: "",
    specialtyIds: [],
    designStyleIds: [],
    adviceOfferIds: [],
    portfolioImageIds: [],
};

function formatEdit(response) {
    const { designStyleIds, specialtyIds, adviceOfferIds, ...rest } = response;
    const formatted = {
        ...rest,
        // TODO: Fix logic
        portfolioImageIds: rest.portfolioImages.map(x => x.imageId)
    };
    specialtyIds.forEach((id) => {
        formatted[`specialties_${id}`] = true;
    });
    adviceOfferIds.forEach((id) => {
        formatted[`adviceOffers_${id}`] = true;
    });
    designStyleIds.forEach((id) => {
        formatted[`designStyles_${id}`] = true;
    });
    return formatted;
}

export const useDesigner = (designerId, setIsEditing) => {
    const [designerView, setDesignerView] = useState(designerViewModel);
    const [designerEdit, setDesignerEdit] = useState(designerEditModel);
    const [designerResult, setDesignerResult] = useState(designerEditModel);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [saving, setSaving] = useState(false);
    const [newFeatureImage, setNewFeatureImage] = useState(null);
    const [newProfileImage, setNewProfileImage] = useState(null);
    const [isPublic, setIsPublic] = useState(false);
    const [savingPublic, setSavingPublic] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [portfolioImages, setPortfolioImages] = useState([]);
    const hasEditPermission = useMemo(() => designerView.id === getUserId(), [
        designerView.id,
    ]);

    const [profileImage, loadingProfile, setProfile] = useImage(
        designerView?.profileImageId,
        profileDefault
    );
    const [featureImage, loadingFeatureImage, setFeature] = useImage(
        designerView?.featureImageId
    );

    const toggleProfile = useCallback(async () => {
        setSavingPublic(true);
        try {
            if (isPublic) {
                await deleteData(`${ApiEndpoint[EntityType.Designer]}/public`);
                setIsPublic(false);
                enqueueSnackbar("Your profile became private", { variant: "success" });
            } else {
                await updateData(`${ApiEndpoint[EntityType.Designer]}/public`);
                setIsPublic(true);
                enqueueSnackbar("Your profile is public now", { variant: "success" });
            }
        } catch (e) {
            setErrorMessage(e.message);
        }
        setSavingPublic(false);
    }, [enqueueSnackbar, isPublic]);

    const handleRollBack = useCallback(() => {
        setDesignerEdit(designerResult);
        setNewProfileImage(null);
        setNewFeatureImage(null);
        setPortfolioImages([]);
    }, [designerResult]);

    useEffect(() => {
        handleRollBack();
    }, [handleRollBack]);

    useEffect(() => {
        setIsLoading(true);
        fetchData(`${ApiEndpoint[EntityType.Designer]}/${designerId}`)
            .then((data) => {
                setDesignerView(data);
                setIsLoading(false);
                setIsPublic(data.public);
            })
            .catch((ex) => {
                setErrorMessage(ex.message);
                setIsLoading(false);
            });
    }, [designerId]);

    useEffect(() => {
        if (hasEditPermission) {
            setIsLoadingEdit(true);
            fetchData(`${ApiEndpoint[EntityType.Designer]}/edit`)
                .then((data) => {
                    const formatted = formatEdit(data);
                    setDesignerEdit(formatted);
                    setDesignerResult(formatted);
                    setIsLoadingEdit(false);
                })
                .catch((ex) => {
                    setErrorMessage(ex.message);
                    setIsLoadingEdit(false);
                });
        }
    }, [designerId, hasEditPermission]);

    const handleSubmit = async (values) => {
        const userDetails = buildUserDetails(values);
        if (!userDetails.specialtyIds[0]) {
            return alert("You need to select at least 1 speciality");
        } else if (!userDetails.designStyleIds[0]) {
            return alert("You need to select at least 1 design style");
        }
        userDetails.portfolioImages = JSON.parse(
            JSON.stringify(designerEdit.portfolioImageIds)
        ).map((imageId) => ({ imageId }));
        const formData = new FormData();
        setSaving(true);
        if (newProfileImage) {
            formData.append("profile", newProfileImage);
            setProfile(null);
        }
        if (newFeatureImage) {
            formData.append("feature", newFeatureImage);
            // TODO: improve
            setFeature(null);
        }
        portfolioImages.forEach((file, index) => {
            const key = `portfolio_${index}`;
            formData.append(key, file);
            userDetails.portfolioImages.push({ imageId: key });
        });

        // userDetails.portfolioImageIds = userDetails.portfolioImages.map(x => x.id)

        formData.append(
            "data",
            new Blob([JSON.stringify(userDetails)], {
                type: "application/json",
            })
        );
        try {
            await updateData(ApiEndpoint[EntityType.Designer], formData);
            window.scrollTo(0, 0);
            const updated = await fetchData(`${API_URL}/designers/${designerId}`);
            const edited = await fetchData(
                `${ApiEndpoint[EntityType.Designer]}/edit`
            );
            const formatted = formatEdit(edited);
            setSuccessMessage("Your account has been updated");
            setDesignerView(updated);
            setIsPublic(updated.public);
            setDesignerEdit(formatted);
            setDesignerResult(formatted);
            setIsEditing(false);
            setSaving(false);
        } catch (ex) {
            setErrorMessage(ex.message);
            setSaving(false);
        }
    };

    const removeImage = useCallback((uuid) => {
        setDesignerEdit((prevState) => ({
            ...prevState,
            portfolioImageIds: prevState.portfolioImageIds.filter((x) => x !== uuid),
            // TODO: Fix logic
            portfolioImages: prevState.portfolioImages.filter(x => x.imageId !== uuid)
        }));
    }, []);

    const acceptFeatureImage = useCallback(
        (image) => {
            const file = new File([image], "feature.png", { type: "image/jpeg" });
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            });
            setNewFeatureImage(file);
            setIsEditing(true);
        },
        [setIsEditing]
    );

    const acceptProfileImage = useCallback(
        (image) => {
            const file = new File([image], "profile.png", { type: "image/jpeg" });
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            });
            setNewProfileImage(file);
            setIsEditing(true);
        },
        [setIsEditing]
    );

    const acceptPortfolioImage = useCallback(
        (image) => {
            const file = new File([image], "profile.png", { type: "image/jpeg" });
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            });
            setPortfolioImages((prevState) => [...prevState, file]);
            setIsEditing(true);
        },
        [setIsEditing]
    );

    const removeAddedImage = useCallback((index) => {
        setPortfolioImages((prevState) => [
            ...prevState.slice(0, index),
            ...prevState.slice(index + 1),
        ]);
    }, []);

    return {
        designerView,
        designerEdit,
        successMessage,
        errorMessage,
        isLoading,
        handleRollBack,
        handleSubmit,
        saving,
        isLoadingEdit,
        newProfileImage,
        acceptProfileImage,
        newFeatureImage,
        removeImage,
        profileImage,
        loadingProfile,
        featureImage,
        loadingFeatureImage,
        acceptFeatureImage,
        isPublic,
        toggleProfile,
        savingPublic,
        acceptPortfolioImage,
        removeAddedImage,
        portfolioImages,
    };
};

import JoinUs from "../pages/Static/JoinUs";
import About from "../pages/Static/About";
import MembersBenefit from "../pages/Static/MembersBenefit";
import Faq from "../pages/Static/Faq";
import Home from "../pages/Home";
import JoinUsDesigner from "../pages/Auth/JoinUsDesigner";
import JoinUsDesignEnthusiast from "../pages/Auth/JoinUsDesignEnthusiast";
import Stories from "../pages/Stories/Stories";
import Terms from "../pages/Static/Terms";
import Login from "../pages/Auth/Login";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import DefaultLayout from "../components/Layouts/DefaultLayout";
import StoryDetails from "../pages/Stories/StoryDetails";
import PrivacyPolicy from "../pages/Static/PrivacyPolicy";
import Designers from "../pages/Designers";
import DesignerDetails from "../pages/Designers/DesignerDetails";
import VerifyAccount from "../pages/Auth/VerifyAccount";
import Account from "../pages/Account";
import ResetPassword from "../pages/Auth/ResetPassword";
import ContactUs from "../pages/Static/ContactUs";
import DecoratingGlossary from "../pages/Static/DecoratingGlossary";
import PressAndPartnerships from "../pages/Static/PressAndPartnerships";
import CommunityGuidelines from "../pages/Static/CommunityGuidelines";
import Page from "../pages/Page";

const authProtectedRoutes = [
    { path: "/account", component: Account, layout: DefaultLayout, title: "My Account" },

]

const publicRoutes = [
    { exact: true, path: "/", component: Home, layout: DefaultLayout, title: "Home", applyTransparency: true },
    { path: "/membership", component: About, layout: DefaultLayout, title: "About Us" },
    { path: "/community-guidelines", component: CommunityGuidelines, layout: DefaultLayout, title: "Community Guidelines" },
    { path: "/decorating-glossary", component: DecoratingGlossary, layout: DefaultLayout, title: "Decorating Glossary" },
    { path: "/press-and-partnerships", component: PressAndPartnerships, layout: DefaultLayout, title: "Press and Partnership" },
    { path: "/account/confirm", component: VerifyAccount, layout: DefaultLayout, title: "Verify Account" },
    { path: "/account/reset-password", component: ResetPassword, layout: DefaultLayout, title: "Reset Password" },
    { path: "/forgot-password", component: ForgotPassword, layout: DefaultLayout, title: "Forgot Password?" },
    { path: "/member-benefits", component: MembersBenefit, layout: DefaultLayout, title: "Member Benefits" },
    { path: "/designer-faq", component: Faq, layout: DefaultLayout, title: "FAQ" },
    { path: "/contact-us", component: ContactUs, layout: DefaultLayout, title: "Contact Us" },
    { exact: true, path: "/join", component: JoinUs, layout: DefaultLayout, title: "Join Us" },
    { exact: true, path: "/join/designer", component: JoinUsDesigner, layout: DefaultLayout, title: "Join Us - Designer" },
    { exact: true, path: "/join/enthusiast", component: JoinUsDesignEnthusiast, layout: DefaultLayout, title: "Join Us - Designer Enthusiast"  },
    { exact: true, path: "/stories", component: Stories, layout: DefaultLayout, title: "Stories"},
    // { exact: true, path: "/stories/:categoryId/:slug", component: StoryDetails, layout: DefaultLayout, title: "Stories"},
    { path: "/stories/:slug", component: StoryDetails, layout: DefaultLayout, title: "Stories"},
    { path: "/pages/:slug", component: Page, layout: DefaultLayout, title: "Page"},
    { path: "/terms-and-conditions", component: Terms, layout: DefaultLayout, title: "Terms" },
    { path: "/privacy-policy", component: PrivacyPolicy, layout: DefaultLayout, title: "Privacy Policy" },
    { exact: true, path: "/designers", component: Designers, layout: DefaultLayout, title: "Designers" },
    { path: "/designer/:idOrSlug", component: DesignerDetails, layout: DefaultLayout, title: "Designers", hideFooter: true },
    { path: "/auth", component: Login, layout: DefaultLayout, title: "Login" },
]

export { authProtectedRoutes, publicRoutes };

import React from 'react';
import {useField} from "formik";
import {usePlacesWidget} from "react-google-autocomplete";
import {TextField} from "@material-ui/core";
import {connect} from "react-redux";

const FormikPlaces = (props) => {
    const {placeholder, context,setDisableFields} = props;
    const [field,,helper] = useField(props.name);
    const [,, helperCity] = useField('city')
    const [, , helperZip] = useField('zipCode')
    const [, , helperStateField] = useField('stateId')

    const {ref} = usePlacesWidget({
        apiKey: 'AIzaSyBza8xSwzUoNDwbNNq7gvwmBjK8Tbx0eBY',
        onPlaceSelected: (place) => {
            const {address_components} = place
            let streetFullAddress = ''
            const fieldsToBeDisabled = {city:false,stateId:false,zipCode:false}
            // initialize on place change
            helper.setValue('')
            helperCity.setValue('')
            helperStateField.setValue(0)
            helperZip.setValue('')
            address_components.forEach((addr) => {
                const findZip = addr.types.findIndex((item) => item === 'postal_code')
                const findState = addr.types.findIndex((item) => item === 'administrative_area_level_1')
                const findCity = addr.types.findIndex((item) => item === 'locality')
                const findStreetNumber = addr.types.findIndex((item) => item === 'street_number')
                const findRoute = addr.types.findIndex((item) => item === 'route')

                if (findStreetNumber !==-1)
                {
                    streetFullAddress += addr.long_name
                }

                if (findRoute !==-1) {
                    helper.setValue(`${streetFullAddress} ${addr.long_name}`)
                }

                if (findCity !== -1)
                {
                    helperCity.setValue(addr.long_name)
                    fieldsToBeDisabled.city=true
                }
                if (findZip !== -1) {
                    helperZip.setValue(addr.long_name)
                    fieldsToBeDisabled.zipCode=true
                }
                if (findState !== -1) {
                    const states = context.RegisterList.get.result?.states || [];
                    const state = states.find((x) => x.name === addr.long_name);

                    if (state)
                        {
                            helperStateField.setValue(state.id)
                            fieldsToBeDisabled.stateId=true
                        }
                }
            })
            setDisableFields(fieldsToBeDisabled)
            // helperCity.setValue
        },
        options: {
            types: ['geocode', 'establishment'],
            componentRestrictions: {country: "us"}
        },
    });
    return <>
        <TextField
            fullWidth
            inputRef={ref}
            disabled={true}
            onClick={()=>{helper.setValue('')}}
            {...field}
            {...props}
            {...props.rest}
            placeholder={placeholder}
        />
    </>

}

const mapStateToProps = (state) => {
    return {
        context: {
            RegisterList: state.RegisterList,
        },
    };
};
export default connect(mapStateToProps, {})(FormikPlaces)
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
// Login Redux States
import {LOGIN_USER, LOGOUT_USER} from "./actionTypes";
import {apiError, loginSuccess} from "./actions";
//Include Both Helper File with needed methods
import {postLogin} from "./api";
import axios from "axios";
import {fetchData, getJwt} from "../../../utils";
import {ApiEndpoint} from "../../@core/endpoint";
import {EntityType} from "../../@core/entityType";

function* loginUser({ payload: { user, history, redirection} }) {
  try {
    const response = yield call(postLogin, user);
    if (user.rememberMe) {
      localStorage.setItem("authUser", JSON.stringify(response));
    } else {
      sessionStorage.setItem("authUser", JSON.stringify(response));
    }
    axios.defaults.headers.common = { Authorization: getJwt() };
    const userDetails = yield call(fetchData, ApiEndpoint[EntityType.Account]);
    yield put(loginSuccess(userDetails));
    if (redirection)
      history.push(redirection)
    else
      history.push("/");
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    sessionStorage.removeItem("authUser");
    axios.defaults.headers.common = { Authorization: null };
    history.push("/");
  } catch (error) {
    yield put(apiError(error.message));
  }
}


export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}


function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;

import BaseContent from "../components/Core/BaseContent";
import { Box } from "@material-ui/core";

function NotFound() {
  return (
    <BaseContent title={"Page Not Found"}>
      <Box />
    </BaseContent>
  );
}

export default NotFound;

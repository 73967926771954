import { Fragment } from "react";
import { Redirect, Route } from "react-router-dom";
import { getCredentials } from "../utils";

const AppRoute = ({
  path: Path,
  component: Component,
  layout: Layout,
  submenu: SubMenu,
  title,
  back,
  isAuthProtected,
  hideFooter,
  applyTransparency,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !getCredentials()) {
        return (
          <Redirect
            to={{ pathname: "/sign-in", state: { from: props.location } }}
          />
        );
      }

      const Wrapper = Layout || Fragment;

      return (
        <Wrapper title={title} hideFooter={hideFooter} applyTransparency={applyTransparency}>
          <Component {...props} />
        </Wrapper>
      );
    }}
  />
);

export default AppRoute;

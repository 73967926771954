import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  Avatar,
  Container,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from "@material-ui/core";
import { NavLink, withRouter } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import { connect } from "react-redux";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { logoutUser } from "../../store/auth/login/actions";
import Box from "@material-ui/core/Box";
import { isAdmin } from "../../utils";
import SpeedIcon from "@material-ui/icons/Speed";
import {
  setOpenPreview,
  setOpenSidebar,
} from "../../store/pageBuilder/actions";
import EditIcon from "@material-ui/icons/Edit";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useMenuLinks, useScrollPosition } from "../../hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {ReactComponent as FreddieLogo} from "../../assets/images/logo.svg";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 60,
  },
  title: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontSize: 28,
    fontWeight: "bold",
  },
  linkLabel: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  drawer: {
    // [theme.breakpoints.up('sm')]: {
    //   width: drawerWidth,
    //   flexShrink: 0,
    // },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Navigation(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:720px)");

  const {
    context,
    history,
    logoutUser,
    setOpenSidebar,
    setOpenPreview,
    applyTransparency,
  } = props;
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [anchorElAdmin, setAnchorElAdmin] = React.useState(null);

  const handleClickAdmin = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };

  const handleCloseAdmin = () => {
    setAnchorElAdmin(null);
  };

  const { preview } = context.PageBuilder;

  const menuLinks = useMenuLinks(context, "header");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [hideOnScroll, setHideOnScroll] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < -10;
      // const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  const hasColor = applyTransparency && !hideOnScroll;
  const color = hasColor ? "white" : "black";
  const backgroundColor = hasColor ? "transparent" : "white";
  const avatarColor = hasColor ? "#3a5273" : "white";
  const avatarBg = hasColor ? "white" : "#3a5273";
  const joinBg = hasColor ? "transparent" : "#3a5273";
  const { userDetails, isAuth } = context.Auth;

  function renderAuthLinks() {
    const { userDetails } = context.Auth;

    return context.Auth.isAuth ? (
      <>
        {!isAdmin() && (
          <IconButton
            color="inherit"
            aria-controls="simple-menu"
            aria-haspopup="true"
            size={"small"}
            onClick={handleClick}
            endIcon={<AccountCircleIcon />}
          >
            <Avatar style={{ backgroundColor: avatarBg, color: avatarColor }}>
              {userDetails?.firstName?.charAt(0)}
              {userDetails?.lastName?.charAt(0)}
            </Avatar>
          </IconButton>
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          anchorPosition={"right"}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              history.push("/account");
            }}
          >
            My Account
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              logoutUser(history);
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </>
    ) : (
      <>
        <Button
          classes={{label: classes.linkLabel}}
          color="inherit"
          component={NavLink}
          style={{ color, marginRight: 8 }}
          to={"/auth"}
        >
          Login
        </Button>
        <Button
          classes={{label: classes.linkLabel}}
          color="inherit"
          style={{
            color: "white",
            backgroundColor: joinBg,
            borderRadius: 30,
            minWidth: 100,
          }}
          component={NavLink}
          to={"/join"}
        >
          Join Us
        </Button>
      </>
    );
  }

  const drawer = (
    <div>
      <List>
        {menuLinks.map((link, i) => (
          <ListItem
            button
            key={i}
            component={NavLink}
            to={link.url}
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={link.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
      {isAuth ? (
        <>
          <ListItem
            button
            component={NavLink}
            to={"/account"}
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={"My Account"} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              logoutUser(history);
              handleDrawerToggle();
            }}
          >
            <ListItemText primary={"Logout"} />
          </ListItem>
        </>
      ) : (
        <>
          <ListItem
            button
            component={NavLink}
            to={"/auth"}
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={"Login"} />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to={"/join"}
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={"Join Us"} />
          </ListItem>
        </>
      )}
    </div>
  );

  return (
    <div className={classes.root} style={{ marginBottom: isAdmin() ? 60 : 0 }}>
      <AppBar
        position="fixed"
        elevation={0}
        style={{ backgroundColor }}
        color={"transparent"}
      >
        {isAdmin() && (
          <Box
            component={Paper}
            color={"#fff"}
            style={{ backgroundColor: "#000" }}
            display={"flex"}
            alignItems={"center"}
            p={1}
          >
            {!matches && (
              <>
                <Button
                  style={{ color: "#fff" }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClickAdmin}
                >
                  Menu
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorElAdmin}
                  keepMounted
                  open={Boolean(anchorElAdmin)}
                  onClose={handleCloseAdmin}
                >
                  <a href={"/admin"} style={{ color: "black" }}>
                    <MenuItem onClick={handleCloseAdmin}>Dashboard</MenuItem>
                  </a>
                  <MenuItem
                    onClick={() => {
                      handleCloseAdmin();
                      setOpenSidebar(true);
                    }}
                  >
                    Customize
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseAdmin();
                      setOpenPreview(!preview);
                    }}
                  >
                    {preview ? "Live Preview ON" : "Live Preview Off"}
                  </MenuItem>
                </Menu>
              </>
            )}
            {matches && (
              <>
                <a href={"/admin"}>
                  <Button
                    style={{ color: "#fff" }}
                    size={"small"}
                    startIcon={<SpeedIcon />}
                  >
                    Freddie
                  </Button>
                </a>
                <Button
                  startIcon={<EditIcon />}
                  style={{ color: "#fff" }}
                  size={"small"}
                  onClick={() => setOpenSidebar(true)}
                >
                  Customize
                </Button>
                <Button
                  style={{ color: "#fff" }}
                  startIcon={!preview ? <VisibilityOff /> : <Visibility />}
                  onClick={() => setOpenPreview(!preview)}
                >
                  {preview ? "Live Preview ON" : "Live Preview Off"}
                </Button>
              </>
            )}
            <div style={{ flex: 1 }} />
            <IconButton
              color="inherit"
              aria-controls="simple-menu"
              aria-haspopup="true"
              size={"small"}
              onClick={handleClick}
              endIcon={<AccountCircleIcon />}
            >
              <Avatar>
                {userDetails?.firstName?.charAt(0)}
                {userDetails?.lastName?.charAt(0)}
              </Avatar>
            </IconButton>
          </Box>
        )}
        <Container>
          <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
            <nav className={classes.drawer} aria-label="mailbox folders">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={"right"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
            <Box component={NavLink} to={'/'}><FreddieLogo color={color} style={{height:20,color:color,marginRight: 30}}/></Box>
            <div style={{flex: 1, display: "flex", justifyContent: "center"}}>
            <Hidden smDown implementation="css">
              {menuLinks.map((link, i) => (
                <Button
                  key={i}
                  classes={{label: classes.linkLabel}}
                  color="inherit"
                  component={NavLink}
                  to={link.url}
                >
                  <Typography variant={"inherit"} noWrap style={{ color }}>
                    {link.name}
                  </Typography>
                </Button>
              ))}
            </Hidden>
            </div>
            <div style={{width: 200, display: "flex", justifyContent: "flex-end"}}>
              <Hidden smDown implementation="css">
                {renderAuthLinks()}
              </Hidden>
            </div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              style={{ color }}
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Auth: state.Auth,
      PageBuilder: state.PageBuilder,
    },
  };
};
export default connect(mapStateToProps, {
  logoutUser,
  setOpenSidebar,
  setOpenPreview,
})(withRouter(Navigation));

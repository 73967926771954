export const roles = {
  HOME_OWNER: "HOME_OWNER",
  DESIGNER: "DESIGNER",
  ADMIN: "ADMIN",
};

export const appComponent = {
  INSTAGRAM: "INSTAGRAM",
  DESIGNERS: "DESIGNERS",
  STORIES: "STORIES",
  TEXT: "TEXT",
};

export const appComponents = [
  {
    key: appComponent.DESIGNERS,
    name: "DESIGNERS",
    order: 0,
    settings: {},
  },
  {
    key: appComponent.STORIES,
    name: "STORIES",
    order: 0,
    settings: {},
  },
  {
    key: appComponent.INSTAGRAM,
    name: "INSTAGRAM",
    order: 0,
    settings: {},
  },
  {
    key: appComponent.TEXT,
    name: "TEXT",
    order: 0,
    settings: {},
  },
];

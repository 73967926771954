import {useField} from "formik";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

const FormikSelect = (props) => {
  const { options, required = false, variant = "outlined" } = props;
  const [field, meta, helpers] = useField(props.name);
  return (
    <FormControl
      fullWidth
      required={required}
      variant={variant}
      error={meta.error && meta.touched}
      helperText={meta.touched ? meta.error : null}
    >
      <InputLabel id="demo-simple-select-outlined-label">
        {props.label}
      </InputLabel>
      <Select
        {...field}
        {...props}
        value={field.value}
        onChange={(e) => helpers.setValue(e.target.value)}
      >
        {!required && (
          <MenuItem selected={!field.value} key={"none"} value={null}>
            None
          </MenuItem>
        )}
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option.id}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FormikSelect;

import axios from "axios";
import ApiHandler from "reactcoregk/models/apiHandler";
import {API_URL} from "./config";
import {roles} from "./constants/enums";
import {sliderStylesDefault} from "./constants/constants";

axios.defaults.headers.common = {
  Authorization: getJwt(),
};

export function getDefaultHeaders() {
  return {
    Authorization: getJwt()
  };
}

export function getAxiosConfig() {
  return {
    headers: getDefaultHeaders(),
  };
}

export const getDefaultApiHandler = () =>
  new ApiHandler(false, true, getDefaultHeaders());

export function getCredentials() {
  const permUserAuth = localStorage.getItem("authUser");
  const tempUserAuth = sessionStorage.getItem("authUser");
  return tempUserAuth || permUserAuth
}

export function getToken() {
  const credentials = getCredentials()
  return credentials ? JSON.parse(credentials).access_token : null;
}

export function getJwt() {
  const token = getToken()
  return token ? `Bearer ${token}` : null
}

export function getUsername() {
  const token = getToken()
  if (!token) return null
  return parseJwt(token)?.name
}
export function getRoles() {
  const token = getToken()
  if (!token) return []
  return parseJwt(token)?.roles || []
}

export function isAdmin() {
  const userRoles = getRoles()
  return userRoles.includes(roles.ADMIN)
}

export function isDesigner() {
  const userRoles = getRoles()
  return userRoles.includes(roles.DESIGNER)
}
export function isHomeOwner() {
  const userRoles = getRoles()
  return userRoles.includes(roles.HOME_OWNER)
}

export function getUserId() {
  const token = getToken()
  if (!token) return null
  return parseJwt(token)?.sub
}

export const getNameOrDefault = (map, id, prop = "name") =>
  map.has(id) ? map.get(id)[prop] : "-";

export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function handleError(err) {
  if (err.response) {
    const errorMsg = err.response.data.message;
    if (errorMsg) throw new Error(err.response.data.message);
    throw new Error(`Something went wrong. Error code: ${err.request.status}.`);
  }
  throw new Error("Something went wrong.");
}

export const fetchData = (url) =>
  axios
    .get(url)
    .then((res) => res.data)
    .catch(handleError);

export const postData = (url, data) =>
  axios
    .post(url, data)
    .then((res) => res.data)
    .catch(handleError);

export const updateData = (url, data) =>
  axios
    .put(url, data)
    .then((res) => res.data)
    .catch(handleError);

export const deleteData = (url, data) =>
  axios.delete(url, { data }).catch(handleError);


export const downloadFile = (exportURL, fileName, fileExtension, showTime) => {
  const currentdate = new Date();
  const datetime =
    "Last Sync: " +
    currentdate.getDate() +
    "/" +
    (currentdate.getMonth() + 1) +
    "/" +
    currentdate.getFullYear() +
    " @ " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();

  return fetch(exportURL, {
    method: "GET",
    mode: "cors",
    headers: getAxiosConfig().headers,
  })
    .then(function (resp) {
      return resp.blob();
    })
    .then(function (blob) {
      var exportURL = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = exportURL;
      a.download =
        fileName + (showTime ? `_${datetime}` : "") + "." + fileExtension;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    })
    .catch(handleError);
};

export function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function updateURLParameter(url, param, paramVal) {
  var newAdditionalURL = "";
  var tempArray = url.split("?");
  var baseURL = tempArray[0];
  var additionalURL = tempArray[1];
  var temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] !== param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  var rows_txt = temp + "" + param + "=" + paramVal;
  return baseURL + "?" + newAdditionalURL + rows_txt;
}

export function validateInput(value, type) {
  switch (type) {
    case "email":
      if (!value) {
        return "Required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        return "Invalid email address";
      }
      break;
    default:
      if (!value) {
        return "Required";
      }
      return null;
  }
}

export function safeOptions(list) {
  return list && list[0] ? list : [{id: null, name: 'No options'}]
}

export function mapFormOptions(form, context){
  const lists = context.get.result
  return form.map(input => {
    if (input.options) {
      return {
        ...input,
        options: lists[input.options] || []
      }
    }
    return input
  })
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function getFileUrl(uuid, type = "files") {
  return `${API_URL}/${type}/${uuid}`
}

export const downloadImage = (
    downloadUrl,
    setThumbnail,
    setIsLoading
) => {
  let headers = {
    Authorization: getToken(),
  };
  let base64Data = null;
  axios
      .get(downloadUrl, { headers, responseType: "blob" })
      .then((response) => {
        const { data } = response;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          base64Data = reader.result;
          setThumbnail(base64Data);
          setIsLoading(false);
        };
      })
      .catch((e) => {
        // setThumbnail(null);
      });
};

export function getImageSize(file) {
  return new Promise (function (resolved, rejected) {
    var i = new Image()
    i.onload = function(){
      resolved([i.width, i.height])
    };
    i.src = file
  })
}
// cropImage.js

// create the image with a src of the base64 string
const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', error => reject(error))
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
    })

export const getCroppedImg = async (imageSrc, crop, aspect, resolution) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  // const maxWidth = 1280
  // const maxHeight = 720
  // let ratio = aspect

  if (resolution) {
    canvas.width = resolution[0]
    canvas.height = resolution[1]

  } else {
    canvas.width = image.width
    canvas.height = image.height

    /* setting canvas width & height allows us to
    resize from the original image resolution */
    // Check if the current width is larger than the max
    // if(image.width > maxWidth){
    //   ratio = maxWidth / image.width;   // get ratio for scaling image
    //   canvas.height = image.height * ratio;    // Reset height to match scaled image
    //   canvas.width = image.width * ratio;    // Reset width to match scaled image
    // }
    // // Check if current height is larger than max
    // if(image.height > maxHeight){
    //   ratio = maxHeight / image.height; // get ratio for scaling image
    //   canvas.width = image.width * ratio;    // Reset width to match scaled image
    //   canvas.height = image.height * ratio;    // Reset height to match scaled image
    // }

  }

  ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      canvas.width,
      canvas.height
  )

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob)
    }, 'image/jpeg')
  })
}

export const groupBy = (array, key) => {
  return array.reduce((result, obj) => {
    (result[obj[key]] = result[obj[key]] || []).push(obj);
    return result;
  }, {});
};


export function flatList(array) {
  let result = [];
  const flatten = (array) => {
    array.forEach((element) => {
      if (element.list) {
        result.push(element);
        flatten(element.list);
      }
    });
  };
  flatten(array);

  return result;
}

export function passwordHasUpperCase(str) {
  const regex = new RegExp("[A-Z]+"); // Check for uppercase first
  return regex.test(str) === true
}

export function passwordHasLowerCase(str) {
  const regex = new RegExp("[a-z]+"); // Check for uppercase first
  return regex.test(str) === true
}

export function passwordHasNumber(str) {
  const regex = new RegExp("[0-9]+")// Check for uppercase first
  return regex.test(str) === true
}
export const getIgUrl = (instagram) => {
  return instagram ? `https://www.instagram.com/${instagram}` : undefined
}

const getJson = (slide) => {
  try {
    if (slide.styles) {
      const json = JSON.parse(slide.styles);
      Object.keys(sliderStylesDefault).forEach((key) => {
        if (!json[key]) {
          json[key] = sliderStylesDefault[key];
        }
        Object.keys(sliderStylesDefault[key]).forEach((styleKey) => {
          if (!json[key][styleKey]) {
            json[key][styleKey] = sliderStylesDefault[key][styleKey];
          }
        });
      });
      return json;
    }
    return sliderStylesDefault;
  } catch (e) {
    return sliderStylesDefault;
  }
};

export const unwrapSlides = (slides) => {
  return slides.map(slide => {
    return {
      ...slide,
      styles: getJson(slide)
    }
  })
}

export const wrapSlides = (slides) => {
  return slides.map(slide => {
    return {
      ...slide,
      styles: JSON.stringify(slide.styles)
    }
  })
}

export const combineStrings = (divider, ...strings) => {
  return [...strings].filter(x => x).join(divider)
}

import React from 'react';
import MaskedInput from 'react-text-mask';
import FormikTextField from "./FormikTextField";


function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
      <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          placeholderChar={'\u2000'}
          showMask
      />
  );
}

export default function FormikPhoneNumber(props) {
    return <FormikTextField {...props} InputProps={{
        inputComponent: TextMaskCustom,
    }}/>
}

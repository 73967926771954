import { useStyles } from "./styles";
import { Box, CardActionArea, Typography } from "@material-ui/core";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import React from "react";

function Editable(props) {
  const classes = useStyles();
  const { isEditing, ...rest } = props;
  return isEditing ? (
    <Box className={classes.editable} {...rest}>
      {props.children}
    </Box>
  ) : null;
}

function PortfolioAdd({ openCrop }) {
  return (
    <div style={{ width: "100%", height: 300 }}>
      <CardActionArea
        onClick={openCrop}
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        <Editable isEditing={true}>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Typography style={{ color: "#fff" }}>Upload Image</Typography>
            <PhotoLibraryIcon style={{ color: "#fff" }} fontSize={"large"} />
          </Box>
        </Editable>
      </CardActionArea>
    </div>
  );
}

export { Editable, PortfolioAdd };

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box, Button, CardActionArea, Container, Fab, Link as MuiLink } from "@material-ui/core";
import { useImage } from "../hooks";
import { Link } from "react-router-dom";
import { Fragment, useState } from "react";
import { motion } from "framer-motion";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import SwipeableViews from "react-swipeable-views";

const useStyles = makeStyles((theme) => ({
  intro: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 24,
    zIndex: 1,
  },
  banner: {
    height: 650,
    display: "flex",
  },
  fab: {
    width: 52,
    height: 52,
    position: "absolute",
    top: "calc(50% - 26px)",
    backgroundColor: "white",
  },
}));

const containerVariants3 = {
  hidden: {
    opacity: 0,
    // transform: "scale(0.95)",
  },
  visible: {
    opacity: 1,
    // transform: "scale(1)",
    transition: "opacity 500ms, transform 300ms",
    width: "100%",
    height: "100%",
  },
  exit: {
    opacity: 1,
    // transform: "scale(0.95)",
  },
};

const bannerVariants = {
  hidden: {
    marginRight: 200,
  },
  visible: {
    opacity: 1,
    // transform: "scale(1)",
    marginRight: 0,
    transition: "opacity 500ms, transform 300ms",
    width: "100%",
    height: "100%",
  },
  exit: {
    opacity: 1,
    marginRight: 200,
    // transform: "scale(0.95)",
  },
};

function CarouselItem(props) {
  const classes = useStyles();
  const { currentSlide } = props;
  const [image] = useImage(currentSlide?.imageId);

  if (!currentSlide) return null;

  const { styles } = currentSlide;

  return (
    <motion.div
      style={{ flex: 1 }}
      className="wrapper"
      variants={containerVariants3}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {/*<Parallax*/}
      {/*  bgImage={currentSlide?.file?.preview || image}*/}
      {/*  bgImageAlt="the cat"*/}
      {/*  strength={400}*/}
      {/*>*/}
      <Box component={MuiLink} href={currentSlide?.imageUrl || "#"}>
        <img
          alt={""}
          src={currentSlide?.file?.preview || image}
          style={{
            position: "absolute",
            width: "100%",
            height: 650,
            objectFit: "cover",
          }}
        />
        <motion.div
          style={{ flex: 1 }}
          className="wrapper"
          variants={bannerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className={classes.banner} style={styles.root}>
            <div className={classes.intro} style={{ ...styles.banner }}>
              <Typography
                component={"h6"}
                variant={"h6"}
                style={{ textAlign: "center", ...styles.title }}
              >
                {currentSlide.title}
              </Typography>
              <Box mt={1} />
              <Typography
                component={"h4"}
                variant={"h4"}
                style={{ textAlign: "center", ...styles.description }}
              >
                {currentSlide.description}
              </Typography>
              {currentSlide.actionPath && currentSlide.actionLabel ? (
                currentSlide.externalUrl ? (
                  <MuiLink
                    href={currentSlide.actionPath}
                    // target={"_blank"}
                  >
                    <Button
                      style={{ marginTop: 16 }}
                      size={"large"}
                      variant={"contained"}
                      color={"primary"}
                      // to={currentSlide.actionPath}
                    >
                      {currentSlide.actionLabel}
                    </Button>
                  </MuiLink>
                ) : (
                  <Button
                    style={{ marginTop: 16 }}
                    size={"large"}
                    variant={"contained"}
                    color={"primary"}
                    component={Link}
                    to={currentSlide.actionPath}
                  >
                    {currentSlide.actionLabel}
                  </Button>
                )
              ) : null}
            </div>
          </div>
        </motion.div>
        {props.children}
        {/*</Parallax>*/}
      </Box>
    </motion.div>
  );
}

function ParallaxSlider({ sliderAssets }) {
  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
    else setCurrentIndex(sliderAssets.length - 1);
  };

  const handleNext = () => {
    if (currentIndex === sliderAssets.length - 1) setCurrentIndex(0);
    else setCurrentIndex(currentIndex + 1);
  };

  const renderProgress = () => {
    if (sliderAssets.length < 2) return null;
    return (
      <Container maxWidth={"xl"}>
        <Box display={"flex"} width={"100%"} mt={4}>
          {sliderAssets.map((group, index) => (
            <CardActionArea
              onClick={() => setCurrentIndex(index)}
              key={index}
              style={{
                flex: 1,
                height: 4,
                backgroundColor:
                  index === currentIndex
                    ? "rgba(112, 112, 112, 0.8)"
                    : "#d8d8d8",
              }}
            />
          ))}
        </Box>
      </Container>
    );
  };

  const renderArrows = () => {
    if (sliderAssets.length < 2) return null;
    return (
      <Fragment>
        <Fab onClick={handlePrev} className={classes.fab} style={{ left: 16 }}>
          <ChevronLeft fontSize={"large"} />
        </Fab>
        <Fab className={classes.fab} onClick={handleNext} style={{ right: 16 }}>
          <ChevronRight fontSize={"large"} />
        </Fab>
      </Fragment>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <SwipeableViews index={currentIndex} onChangeIndex={setCurrentIndex}>
        {sliderAssets.map((currentSlide, idx) => (
          <CarouselItem currentSlide={currentSlide} key={idx} />
        ))}
      </SwipeableViews>
      {renderArrows()}
      {renderProgress()}
    </div>
  );
}

export default ParallaxSlider;

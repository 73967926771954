import BaseContent from "../../components/Core/BaseContent";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

export default function PrivacyPolicy() {
  return (
    <BaseContent title={"PRIVACY POLICY"}>
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        At Freddie we recognize and respect your privacy. We understand that you
        want to control your personal information. Below is a description of our
        Privacy Policy, which supplements our Terms of Use. By accessing and
        using our site, you agree to the Terms of Use and that we may collect
        and use your information on the terms and conditions set forth below.
        All capitalized terms not defined herein shall have the meaning ascribed
        to them in our Terms of Use.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Our registered users (“Members”) share their identities, build and
        interact with their network, post content, and find resources and
        opportunities.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        This Privacy Policy applies to JoinFreddie.com, Freddie, and other
        Freddie-related sites (“Freddie”), apps, and Freddie’s services
        (collectively, the “Services”).
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        You acknowledge that your continued use of our Services after we publish
        or send a notice about our changes to this Privacy Policy means that the
        collection, use and sharing of your personal data is subject to this
        Privacy Policy.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Responsibility:
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        To help protect against unauthorized disclosure of the information you
        share with us, we limit employee access to our customer files only to
        those with a need to know. We make all efforts to keep your Personal
        Information secure, including the use of a secure connection and a
        secure server that is administered for us by a service provider. Our
        service providers have agreed not to disclose to third parties’
        confidential information received from Freddie, except to their
        employees and contractors who need to know such information to carry out
        their responsibilities. Each service provider is required to inform such
        third parties of the existence and requirements of this confidentiality
        obligation.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        What is Personal Information? Broadly speaking, Personal Information
        means information that identifies, relates to, describes, is capable of
        being associated with or could reasonably be linked, directly or
        indirectly, with a you or your household.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Personal Information We Collect:
      </Typography>
      <Box mt={2} />
      <ol>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Information you provide:
          </Typography>
          <ol>
            <li>
              <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
                Registration: By agreeing to the use of the Site, Freddie, or
                its predecessors, affiliates, parents, you have provided certain
                personal information. In addition, we may possess or obtain
                additional Personal Information about you.
              </Typography>
            </li>
            <li>
              <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
                Profile: We collect and gather Personal Information you post on
                your profile and otherwise use our Services. You have broad
                latitude in the information you post on your profile, and some
                information may add to the effectiveness of the Services and
                your user experience.
              </Typography>
            </li>
            <li>
              <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
                Use of Service: We collect Personal Information when you utilize
                the Services, including without limitation, when you upload
                contact information or photographs, your network, and commercial
                data.
              </Typography>
            </li>
            <li>
              <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
                Your activities: When you use the Site, we may automatically
                collect certain information about you, your device, including
                information about your web browser, IP address, time zone, and
                some of the cookies that are installed on your device. In
                addition, your use of the Site may require the disclosure of
                your Personal Information.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            We also may collect information from any messaging within the site,
            including posts or responses on your Profile, for various reasons,
            including for example, to manage or block content that violates our
            User Agreement or other policies.
          </Typography>
          <ol>
            <li>
              <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
                From Others: We may receive your Personal Information when
                others, for example, import or sync their contacts or calendar
                with our Services, post on their Profiles, or send messages
                using our Services (including invites or connection requests).
                If you or others opt-in to sync email accounts with our
                Services, we will also collect “email header” information that
                we can associate with Member profiles
              </Typography>
            </li>
            <li>
              <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
                Device Information: Additionally, as you browse the Site, we
                collect information about your communications with us, what
                websites or search terms referred you to the Site, and
                information about how you interact with the Site. We refer to
                this automatically-collected information as “Device
                Information.”
              </Typography>
            </li>
          </ol>
        </li>
      </ol>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        We collect Device Information using the following technologies:
      </Typography>
      <ol>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            “Cookies” are data files that are placed on your device or computer
            and often include an anonymous unique identifier. For more
            information about cookies, and how to disable cookies, click here.
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            “Log files” track actions occurring on the Site, and collect data
            including your IP address, browser type, Internet service provider,
            referring/exit pages, and date/time stamps.
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            “Web beacons,” “tags,” and “pixels” are electronic files used to
            record information about how you browse the Site.
          </Typography>
        </li>
      </ol>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        How Do We Use Your Personal Information?
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        How Do We Use Your Personal Information?
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        We use the Personal Information in numerous ways including to enhance
        the Site experience, our Services, and to provide marketing information
        to you. To such ends, we use this Personal Information to, for example,
        (and without limitation):
      </Typography>
      <ul>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Communicate with you, via email, phone, notifications, mail,
            reminders, or otherwise;
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Confirm identification and screen for fraud, if applicable;
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Suggest new opportunities, connections and contacts,
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Marketing Communications: We provide direct communications to you
            from our family of brands, including personalized marketing
            communication such as information about new products, services and
            offers tailored to your interests;
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Making Connections; and
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            When in line with the preferences you have shared with us, provide
            you with information or advertising relating to our products or
            services or those of our partners or affiliates.
          </Typography>
        </li>
      </ul>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Your profile is publicly visible/visible to other Members. Your Personal
        Information will appear on your Profile. It’s your choice whether to
        include sensitive information on your profile and to make that sensitive
        information public. Please do not post or add personal data to your
        profile that you would not want to be publicly available.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        We may also use the Device Information that we collect to help us screen
        for potential risk and fraud (in particular, your IP address), and more
        generally to improve and optimize our Site (for example, by generating
        analytics about how our customers browse and interact with the Site, and
        to assess the success of our marketing and advertising campaigns).
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        While you may change your communication preferences. Please be aware
        that you cannot opt out of receiving service messages from us, including
        security and legal notices.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        In addition to advertising our Services, we use Personal Information for
        promotional purposes, promoting our Services, and those of our
        affiliates, and we reserve the right to use your Personal Information,
        including photos you may provide, for such uses.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Sharing Your Personal Information
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        We will share your personal data with our partners and affiliates for
        various reasons, including to provide and develop our Services. We may
        combine information internally across the different Services covered by
        this Privacy Policy to help our Services be more relevant and useful to
        you and others.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        We use others to help us provide our Services (e.g., maintenance,
        analysis, audit, payments, fraud detection, marketing and development).
        They will have access to your Personal Information as reasonably
        necessary to perform these tasks on our behalf and are obligated not to
        disclose or use it for other purposes. Any Personal Information is
        subject to any partners or affiliates privacy policies.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        We reserve the right to share, rent, release, or otherwise sell Personal
        Information to any third party, for consideration or otherwise, at our
        discretion.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        We may share your Personal Information to comply with applicable laws
        and regulations, to respond to a subpoena, search warrant or other
        lawful request for information we receive, or to otherwise protect our
        rights.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        As described above, we use your Personal Information to provide you with
        targeted advertisements or marketing communications we believe may be of
        interest to you. For more information about how targeted advertising
        works, you can visit the Network Advertising Initiative’s (“NAI”)
        educational page.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Personal Information Options
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        We generally retain your personal data as long as you keep your account
        open or as needed to provide you Services. This includes information you
        or others provided to us and information generated or inferred from your
        use of our Services. Some Personal Information may be retained after
        deletion of your account.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        California Residents
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        If you are a California resident you have the right to request all
        Personal Information we have collected from you (free of charge), and/or
        for us to delete such information. You may also ask us to refrain from
        sharing or selling your Personal Information with third parties,
        including our affiliates if they are separate legal entities, for direct
        marketing purposes.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        You may tell us your preference by contacting us via email at
        support@joinfreddie.com, the contact information in the “Contact Us”
        section of this Privacy Policy, or by calling us at our toll free number
        (877) 831-0330.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        We share or sell Personal Information to third parties. You may opt out
        by following this link
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        To best protect your privacy, we may be required to verify your identity
        for any above request. If so, we may ask for a limited amount of
        identifying information, including, for example, your name, telephone
        number, e-mail address, mailing address, and last transaction amount. We
        will match this information to the information on our systems. For an
        online request to delete, we will also send you a second verification to
        confirm that you want your information to be deleted. In order to
        designate an authorized agent to act on your behalf you must provide the
        authorized agent with written permission to do so, and your authorized
        agent must submit that written proof at the time they make the request
        on your behalf. If you wish to make multiple requests under this
        section, we recommend sending the deletion request last, as we may not
        be able to fulfill your other requests once we have deleted your
        information. We will maintain a record of your request to know or
        request to delete
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Please note that if we delete your personal information, some of our
        services may not work the same. These are unintended and unavoidable
        results of deleting your Personal Information. However, in no way will
        you face any discrimination or intentional consequences if you elect to
        exercise any of your rights listed herein – you will still be permitted
        to make a purchase, start an account, or otherwise engage with and
        utilize our services.
      </Typography>
      <Box mt={2} />
      <ul>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Customer Service: Our ability to handle customer service requests
            may be limited. We may not have the ability to look up prior orders
            or account information and may request proof of purchase in order to
            best serve your needs.
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Online Account: Your online account and history will be deleted,
            including without limitation, search history, saved addresses, saved
            connections and any other saved preferences.
          </Typography>
        </li>
      </ul>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Children
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Our services are generally not directed to children under 16. We do not
        knowingly collect Personal Information from anyone under 16 without
        parental consent. If you become aware that we have collected Personal
        Information from a child under the age of 16 without parental consent,
        please let us know so we can take appropriate action.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        How We Protect Personal Information
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        In proportion to the sensitivity of the information, we maintain
        reasonable administrative, technical, and/or physical safeguards and
        appropriate security measures to protect Personal Information from loss,
        misuse or unauthorized access, disclosure, alteration, or destruction of
        the information you provide. However, we cannot guarantee the security
        of your Personal Information, as no electronic data transmission or
        storage of information is completely secure. If an incident is reported
        affecting your information, we will investigate and comply with all
        required reporting obligations.
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Data Retention
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        We may not delete all of your personal information if one of the
        following exceptions applies:
      </Typography>
      <Box mt={2} />
      <ul>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Transactional: to complete a transaction for which the personal
            information was collected, provide a good or service requested by
            you, or perform a contract we have with you;
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Security: to detect data security incidents;
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Error Correction: to debug or repair any errors;
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Legal: to protect against fraud or illegal activity or to comply
            with applicable law or a legal obligation, or exercise rights under
            the law, such as the right to free speech; or
          </Typography>
        </li>
        <li>
          <Typography style={{ marginBottom: 8, fontSize: 16 }} variant={"body1"}>
            Internal Use: to use your personal information, internally, in a
            lawful manner that is compatible with the context in which you
            provided the information (i.e. to improve our services).
          </Typography>
        </li>
      </ul>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        Changes
      </Typography>
      <Box mt={2} />
      <Typography variant={"body1"} style={{ fontSize: 16 }}>
        We may update this privacy policy from time to time in order to reflect,
        for example, changes to our practices or for other operational, legal,
        or regulatory reasons.
      </Typography>
    </BaseContent>
  );
}

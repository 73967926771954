import {entityType} from "./module";
import {
    ADD_HOME_COMPONENT,
    ADD_SLIDE, DELETE_HOME_COMPONENT,
    DELETE_SLIDE, DISCARD_CHANGES, EDIT_HOME_COMPONENT,
    EDIT_SLIDE, EDIT_SLIDE_STYLES, GET_HOME_COMPONENTS, GET_PUBLIC_MENUS,
    GET_SLIDE_SHOW,
    GET_STORED_SETTINGS, MAS_SLIDE_EDIT, MENU_TRIGGER,
    PUBLISH_CHANGES, SET_NODE_ID,
    SET_OPEN_SIDEBAR,
    SET_PREVIEW, UPDATE_HOME_COMPONENT_LIST,
    UPDATE_PUBLIC_MENUS,
} from "./actionTypes";
import {buildActionType, createAction, createAsyncAction} from "reactcoregk/store/actions";

export const getCustomSettingsActions = createAsyncAction(entityType, GET_STORED_SETTINGS)
export const publishCustomSettingsActions = createAsyncAction(entityType, PUBLISH_CHANGES)
export const getHomePageComponentsActions = createAsyncAction(entityType, GET_HOME_COMPONENTS)
export const getSlideShowActions = createAsyncAction(entityType, GET_SLIDE_SHOW)
export const getNavigationActions = createAsyncAction(entityType, GET_PUBLIC_MENUS)

export const getCustomSettings = () => getCustomSettingsActions.request()
export const publishSettings = (payload) => publishCustomSettingsActions.request(payload)
export const getSlider = () => getSlideShowActions.request()
export const getNavigation = () => getNavigationActions.request()
export const getHomePageComponents = () => getHomePageComponentsActions.request()
export const addSlide = () => createAction(buildActionType(entityType, ADD_SLIDE))
export const editSlide = (payload) => createAction(buildActionType(entityType, EDIT_SLIDE), payload)
export const editSlideStyles = (payload) => createAction(buildActionType(entityType, EDIT_SLIDE_STYLES), payload)
export const masSlideEdit = (payload) => createAction(buildActionType(entityType, MAS_SLIDE_EDIT), payload)
export const deleteSlide = (payload) => createAction(buildActionType(entityType, DELETE_SLIDE), payload)
export const setOpenSidebar = (payload) => createAction(buildActionType(entityType, SET_OPEN_SIDEBAR), payload)
export const setOpenPreview = (payload) => createAction(buildActionType(entityType, SET_PREVIEW), payload)
export const discardChanges = (payload) => createAction(buildActionType(entityType, DISCARD_CHANGES), payload)
export const setNodeId = (payload) => createAction(buildActionType(entityType, SET_NODE_ID), payload)
export const updatePublicMenu = (payload) => createAction(buildActionType(entityType, UPDATE_PUBLIC_MENUS), payload)
export const triggerMenu = () => createAction(buildActionType(entityType, MENU_TRIGGER))
export const editHomeComponent = (payload) => createAction(buildActionType(entityType, EDIT_HOME_COMPONENT), payload)
export const addHomeComponent = (payload) => createAction(buildActionType(entityType, ADD_HOME_COMPONENT), payload)
export const deleteHomeComponent = (payload) => createAction(buildActionType(entityType, DELETE_HOME_COMPONENT), payload)
export const updateHomeComponentList = (payload) => createAction(buildActionType(entityType, UPDATE_HOME_COMPONENT_LIST), payload)

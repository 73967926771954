import * as Yup from "yup";
import {
  passwordHasLowerCase,
  passwordHasNumber,
  passwordHasUpperCase,
} from "../utils";

export const slideVariants = {
  hidden: {
    opacity: 0,
    marginLeft: 20,
  },
  visible: {
    opacity: 1,
    marginLeft: 0,
    transition: "opacity 500ms, transform 100ms",
  },
  exit: {
    opacity: 1,
    marginLeft: -20,
  },
};

export const sliderStylesDefault = {
  root: {
    alignItems: "center",
    justifyContent: "flex-end",
  },
  title: {
    color: "#fff",
    fontSize: 14,
  },
  description: {
    color: "#999999",
    fontSize: 32,
  },
  banner: {
    backgroundColor: "#3a5273",
    visibility: "visible",
    margin: 0,
    padding: 40,
  },
};

export const passwordValidation = Yup.string()
  .min(8, "At least 8 characters")
  .required("This field is required")
  .test("checkPassword", "Password does not meet the requirements", (value) => {
    return new Promise((resolve, reject) => {
      const psw = value || "";
      const hasSize = psw.length >= 8;
      const hasUpper = passwordHasUpperCase(psw);
      const hasLower = passwordHasLowerCase(psw);
      const hasNumber = passwordHasNumber(psw);
      resolve(hasSize && hasUpper && hasLower && hasNumber);
    });
  });

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    margin: "auto",
    marginBottom: 24,
    textTransform: "uppercase",
    maxWidth: 800,
    textAlign: "center",
  },
});

function Title(props) {
  const classes = useStyles();
  return (
    <Typography className={classes.title} variant={"h3"}>
      {props.children}
    </Typography>
  );
}

export default Title;

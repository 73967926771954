import * as Yup from "yup";
import { Formik } from "formik";
import { profileForm } from "../../constants/forms";
import { API_URL } from "../../config";
import { useSubmit } from "../../hooks";
import FormContainer from "../../components/FormContainer";
import { useFetch } from "reactcoregk/hooks";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";
import { getDefaultHeaders } from "../../utils";
import { connect } from "react-redux";
import { loginSuccess } from "../../store/auth/login/actions";
import { useEffect } from "react";

const url = `${API_URL}/account`;
const success = "Your account has been created successfully";

function ChangePassword(props) {
  const { loginSuccess, setErrorMessage, setSuccessMessage } = props;
  const { busy, handleSubmit, successMessage, errorMessage } = useSubmit(url, success, true);

  useEffect(() => {
    setErrorMessage(errorMessage);
  }, [errorMessage, setErrorMessage]);

  useEffect(() => {
    setSuccessMessage(successMessage);
  }, [setSuccessMessage, successMessage]);

  const [loading, data] = useFetch(
    ApiEndpoint[EntityType.Account],
    getDefaultHeaders(),
    {
      firstName: "",
      lastName: "",
    }
  );

  const onSubmit = (values) => {
    handleSubmit(values, true, loginSuccess);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={data}
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        firstName: Yup.string().required("This field is required"),
        lastName: Yup.string().required("This field is required"),
      })}
    >
      {(formik) => (
        <FormContainer
          form={profileForm}
          busy={busy || loading}
          submitLabel={"Save"}
        />
      )}
    </Formik>
  );
}

export default connect(null, { loginSuccess })(ChangePassword);

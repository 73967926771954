import { Box, Container, Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export default function Section(props) {
  const { title, hideDivider, ...rest } = props;
  return (
    <>
      <Container {...rest}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 48,
            marginBottom: 48,
          }}
        >
          <Typography
            component={"h4"}
            variant={"h4"}
            style={{
              textAlign: "center",
              marginBottom: 32,
              fontSize: 34,
              textTransform: "uppercase",
            }}
          >
            {title}
          </Typography>
          {props.children}
        </Box>
        {!hideDivider && <Divider />}
      </Container>
    </>
  );
}

import { Container, FormControl, Grid } from "@material-ui/core";
import BaseContent from "../../components/Core/BaseContent";
import { Formik } from "formik";
import { contactForm } from "../../constants/forms";
import { API_URL, CAPTCHA_KEY } from "../../config";
import { useSubmit } from "../../hooks";
import FormContainer from "../../components/FormContainer";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { getFormView } from "../../components/form-views";

const url = `${API_URL}/contact`;
const success = "Email sent";

export default function ContactUs() {
  const [captcha, setCaptcha] = useState(null);
  const { successMessage, errorMessage, busy, handleSubmit } = useSubmit(
    url,
    success
  );
  const { enqueueSnackbar } = useSnackbar();

  return (
    <BaseContent
      title={"Get in touch"}
      successMessage={successMessage}
      errorMessage={errorMessage}
    >
      <Container maxWidth={"md"}>
        <Grid
          container
          direction={"row"}
          spacing={2}
          justify={"center"}
          alignItems={"center"}
        >
          <Formik
            initialValues={{ inquiryType: "General", optInNewsletter: true }}
            onSubmit={(values, { setFieldValue }) => {
              if (!captcha) {
                enqueueSnackbar("Please complete hCaptcha verification", {
                  variant: "warning",
                });
                return;
              }
              const reset = () => {
                setFieldValue("firstName", "");
                setFieldValue("lastName", "");
                setFieldValue("email", "");
                setFieldValue("message", "");
              };
              handleSubmit(
                { ...values, passcode: captcha.token },
                false,
                reset
              );
            }}
          >
            {(formik) => (
              <FormContainer form={contactForm} busy={busy}>
                <Grid item xs={12}>
                  {getFormView({
                    id: "subscribe",
                    label: "Opt-In to Freddie Newsletter",
                    type: "checkbox",
                    required: false,
                    col: 12,
                  })}
                </Grid>
                <Grid item xs={12}>
                  <FormControl required>
                    <HCaptcha
                      sitekey={CAPTCHA_KEY}
                      onVerify={(token, ekey) => setCaptcha({ token, ekey })}
                    />
                  </FormControl>
                </Grid>
              </FormContainer>
            )}
          </Formik>
        </Grid>
      </Container>
    </BaseContent>
  );
}

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Select as MuiSelect } from "@material-ui/core";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";

const BaseSelect = (props) => {
    const {
        onChange,
        options,
        placeholder,
        id,
        label,
        value,
        hasNone,
        controlId,
        fullWidth,
        margin,
        disabled,
        variant,
        ...other
    } = props;

    return (
        <FormControl
            fullWidth={fullWidth}
            margin={margin}
            disabled={disabled}
            placeholder={placeholder}
            variant={variant}
            {...other}
        >
            <InputLabel id={controlId}>{label}</InputLabel>
            <MuiSelect labelId={controlId} id={id} onChange={onChange} value={value}>
                {hasNone && (
                    <MenuItem value={0}>
                        <em>{placeholder}</em>
                    </MenuItem>
                )}
                {options.map((option) => {
                    const Icon = option.icon;
                    return (
                        <MenuItem
                            key={`${controlId}-options-${option.id}`}
                            value={option.id}
                            selected={false}
                            disabled={option.disabled}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            {Boolean(option.icon) && (
                                <Icon style={{ height: 14, width: 14, marginRight: 2 }} />
                            )}
                            <span>{option.name}</span>
                        </MenuItem>
                    );
                })}
            </MuiSelect>
        </FormControl>
    );
};

export default BaseSelect;

BaseSelect.defaultProps = {
    fullWidth: true,
    margin: "normal",
    variant: "outlined"
};

BaseSelect.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    hasNone: PropTypes.bool,
    controlId: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    margin: PropTypes.oneOf(["normal", "dense", "none"]),
    variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
        })
    ).isRequired,
};

import { Container, Grid } from "@material-ui/core";
import BaseContent from "../../components/Core/BaseContent";
import * as Yup from "yup";
import { Formik } from "formik";
import { resetPassword } from "../../constants/forms";
import { API_URL } from "../../config";
import { useSubmit } from "../../hooks";
import { getParameterByName } from "../../utils";
import FormContainer from "../../components/FormContainer";
import { passwordValidation } from "../../constants/constants";

const url = `${API_URL}/account/reset-password`;
const success = "Your password has been updated";
const sub = "Enter your new password";

export default function ResetPassword() {
  const { successMessage, errorMessage, busy, handleSubmit } = useSubmit(
    url,
    success
  );

  const token = getParameterByName("token");

  return (
    <BaseContent
      title={"Reset your password"}
      subtitle={sub}
      successMessage={successMessage}
      errorMessage={errorMessage}
    >
      <Container maxWidth={"sm"}>
        <Grid
          container
          direction={"row"}
          spacing={2}
          justify={"center"}
          alignItems={"center"}
        >
          <Formik
            initialValues={{}}
            onSubmit={(values) =>
              handleSubmit({
                password: values.password,
                token,
              })
            }
            validationSchema={Yup.object({
              password: passwordValidation,
            })}
          >
            {(formik) => (
              <FormContainer
                form={resetPassword}
                busy={busy}
                submitLabel={"Reset Password"}
              />
            )}
          </Formik>
        </Grid>
      </Container>
    </BaseContent>
  );
}

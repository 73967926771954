import {Formik} from "formik";
import FormContainer from "../../components/FormContainer";
import {getInTouchForm} from "../../constants/forms";
import {API_URL, CAPTCHA_KEY} from "../../config";
import BaseDialog from "../../components/Core/BaseDialog";
import {useSubmit} from "../../hooks";
import {motion} from "framer-motion";
import {slideVariants} from "../../constants/constants";
import PropTypes from "prop-types";
import {Button, FormControl, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {fetchData} from "../../utils";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flex: 1, minHeight: 600 }}
      {...other}
      className="wrapper"
    >
      {value === index && (
        <motion.div
          variants={slideVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {children}
        </motion.div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const contactSuccess = "Your Message has been sent";

function ContactDesignerDialog(props) {
  const { open, handleClose, designer } = props;
  const contactUrl = `${API_URL}/designers/${designer.id}/message`;
  const [step, setStep] = useState(0);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [captcha, setCaptcha] = useState(null);
  const {
    errorMessage: errorContact,
    busy: busyContact,
    handleSubmit: submitContact,
  } = useSubmit(contactUrl, contactSuccess);


  useEffect(() => {
    if (open) {
      setStep(0);
    }
  }, [open]);


  const handleContactSuccess = async (values) => {
    setIsLoadingUser(true);
    try {
      await fetchData(`${API_URL}/account/email-availability?email=${values.email}`);
      setStep(1);
    } catch (ex) {
      enqueueSnackbar("Your Message has been sent.", { variant: "success" });
      handleClose();
    }
    setIsLoadingUser(false);
  };

  return (
    <BaseDialog
      open={open}
      title={"Get in touch"}
      errorMessage={errorContact}
      onClose={handleClose}
      showProgress
    >
      <TabPanel index={step} value={0}>
        <Formik
          initialValues={{}}
          onSubmit={(values) => {
            if (!captcha) {
              enqueueSnackbar("Please complete hCaptcha verification", {
                variant: "warning",
              });
              return;
            }
            submitContact({ ...values, passcode: captcha.token }, null, handleContactSuccess);
          }}
        >
          {(formik) => (
            <FormContainer
              submitLabel={"Send"}
              submitVariant={"contained"}
              form={getInTouchForm.map((x) => ({ ...x, variant: "standard" }))}
              busy={busyContact || isLoadingUser}
              submitDirection={"flex-start"}
            >
              <Grid item xs={12}>
                <FormControl required>
                  <HCaptcha
                    sitekey={CAPTCHA_KEY}
                    onVerify={(token, ekey) => setCaptcha({ token, ekey })}
                  />
                </FormControl>
              </Grid>
            </FormContainer>
          )}
        </Formik>
      </TabPanel>
      <TabPanel index={step} value={1}>
        <Box
          justifyContent={"center"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Typography variant={"h5"}>Your Message has been sent.</Typography>
          <Box mt={8} />
          <Typography variant={"h6"}>In the meantime,</Typography>
          <Typography variant={"h6"}>
            would you like to join Freddie?
          </Typography>

          <Box mt={8} />
          <Button
            style={{ minWidth: 200 }}
            color={"primary"}
            variant={"contained"}
            size={"large"}
            component={Link}
            to={"/join"}
          >
            Yes
          </Button>
          <Box mt={1} />
          <Button
            onClick={handleClose}
            size={"large"}
            style={{ minWidth: 200 }}
            color={"primary"}
          >
            Not yet
          </Button>
        </Box>
      </TabPanel>
    </BaseDialog>
  );
}

export default ContactDesignerDialog;

import { Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link, withRouter } from "react-router-dom";
import { useImage } from "../../hooks";
import ZoomImage from "../../components/ZoomImage";
import Box from "@material-ui/core/Box";
import Placeholder from "../../components/Placeholder";
import PropTypes from 'prop-types'

function StoryCard(props) {
  const { story, isLoading, height } = props;
  // const { renderButton } = useFavorite(
  //   props,
  //   story,
  //   EntityType.Article,
  //   "slug"
  // );
  const [storyImage] = useImage(story?.imageId);
  return (
    <Grid container spacing={2} direction={"column"}>
      <Grid item>
        {isLoading ? (
          <Skeleton variant="rect" style={{ width: "100%", height: 300 }} />
        ) : (
          <Link to={`/stories/${story.slug}`}>
            {storyImage ? (
              <ZoomImage
                alt={"story"}
                src={storyImage}
                width={"100%"}
                height={height}
              />
            ) : (
              <Box height={height}>
                <Placeholder type={"Story"} />
              </Box>
            )}
          </Link>
        )}
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {isLoading ? (
            <Skeleton variant="rect" width={200} height={20} />
          ) : (
            <Typography
              component={Link}
              to={`/stories/${story.slug}`}
              variant={"h2"}
              style={{
                fontWeight: "bold",
                fontSize: 18,
                marginRight: 8,
                color: "inherit",
                textTransform: "uppercase",
              }}
            >
              {story.title}
            </Typography>
          )}
          {/*{getToken() && (*/}
          {/*  <>*/}
          {/*    {isLoading ? (*/}
          {/*      <Skeleton variant="circle" width={40} height={40} />*/}
          {/*    ) : (*/}
          {/*      renderButton()*/}
          {/*    )}*/}
          {/*  </>*/}
          {/*)}*/}
        </div>
        <Box mt={1}>
          {isLoading ? (
            <Skeleton variant="rect" width={"100%"} height={60} />
          ) : (
            <Typography variant={"body1"}>
              {maxWidth(story.summary, 200)}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
export default withRouter(StoryCard);

function maxWidth(string, max) {
  if (string?.length > max + 3) {
    return string.substring(0, max) + "...";
  }
  return string;
}

StoryCard.propTypes = {
    story: PropTypes.object,
    isLoading: PropTypes.bool,
    height: PropTypes.number
}

StoryCard.defaultProps = {
    height: 400
}

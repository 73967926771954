import {motion} from "framer-motion";

const IconTwist = (props) => {
  return (
    <motion.div
      animate={{
        scale: [2, 3],
        rotate: [-180, 0, 0],
      }}
    >
      {props.children}
    </motion.div>
  );
};

export default IconTwist;

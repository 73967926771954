import React from "react";
import { Typography } from "@material-ui/core";
import error from "../assets/images/undraw_fixing_bugs_w7gi.svg";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div
          style={{
            padding: 16,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant={"h6"} component={"h2"}>
            Something went wrong
          </Typography>
          <img
            src={error}
            style={{ width: 400, marginTop: 32 }}
            alt={"Error"}
          />
        </div>
      );
    }
    // Normally, just render children
    return <>{this.props.children}</>;
  }
}

import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {
  CardHeader,
  FormControlLabel,
  IconButton,
  Input,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  addSlide,
  deleteSlide,
  editSlide,
  editSlideStyles,
  masSlideEdit,
} from "../../store/pageBuilder/actions";
import { useAppLinks, useImage } from "../../hooks";
import Skeleton from "@material-ui/lab/Skeleton";
import DropDialog from "../DropDialog";
import React, { useCallback, useState } from "react";
import { compareProp, formatBytes } from "reactcoregk/utils";
import BaseSelect from "../Core/BaseSelect";
import styled from "styled-components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DragIndicator, FormatPaintOutlined } from "@material-ui/icons";
import ReactCardFlip from "react-card-flip";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { ColorPicker } from "material-ui-color";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: "auto",
    width: "100%",
  },
});

const grid = 8;

const SlideCardItem = styled.div`
  margin: ${grid}px;
  padding: ${0}px;
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function DragableSlideCard(props) {
  return (
    <Draggable draggableId={`id-${props.slide.id}}`} index={props.index}>
      {(provided) => (
        <SlideCardItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <SlideCard {...props} />
        </SlideCardItem>
      )}
    </Draggable>
  );
}

const flexOptions = [
  { id: "flex-start", name: "Start" },
  { id: "center", name: "Center" },
  { id: "flex-end", name: "End" },
  { id: "stretch", name: "Stretch" },
];

function SlideCard(props) {
  const classes = useStyles();
  const {
    slide,
    handleDelete,
    handleChange,
    handleNewImage,
    index,
    handleChangeStyles,
    linkOptions,
  } = props;
  const [file, isLoading] = useImage(slide.imageId, null);
  const [isFlipped, setIsFlipped] = useState(false);

  const source =
    slide.file?.preview || file || "https://via.placeholder.com/1920x1080";

  const { styles } = slide;
  const isBannerVisible = slide.styles.banner.visibility === "visible";

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <CardHeader
            style={{ padding: 0 }}
            action={
              <Box>
                <IconButton
                  aria-label="settings"
                  onClick={() => setIsFlipped(true)}
                >
                  <FormatPaintOutlined />
                </IconButton>
                <IconButton aria-label="settings">
                  <DragIndicator />
                </IconButton>
              </Box>
            }
            title={`Slide ${index + 1}`}
          />
          {!isLoading ? (
            <img className={classes.media} src={source} alt={slide?.title} />
          ) : (
            <Skeleton variant="rect" className={classes.media} />
          )}
          <TextField
            margin={"dense"}
            fullWidth
            multiline
            value={slide.imageUrl}
            label={"Image Url"}
            placeholder={"ex: https://joinfreddie.com"}
            onChange={(e) => {
              const value = e.target.value;
              handleChange(slide.id, "imageUrl", value);
            }}
          />
          <TextField
            margin={"dense"}
            fullWidth
            value={slide.title}
            label={"Title"}
            onChange={(e) => {
              const value = e.target.value;
              handleChange(slide.id, "title", value);
            }}
          />
          <TextField
            margin={"dense"}
            fullWidth
            multiline
            value={slide.description}
            label={"Description"}
            onChange={(e) => {
              const value = e.target.value;
              handleChange(slide.id, "description", value);
            }}
          />
          <TextField
            margin={"dense"}
            fullWidth
            multiline
            value={slide.actionLabel}
            label={"Action Name"}
            placeholder={"ex: Join Us"}
            onChange={(e) => {
              const value = e.target.value;
              handleChange(slide.id, "actionLabel", value);
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={slide.externalUrl}
                onChange={(e) => {
                  const value = e.target.checked;
                  handleChange(slide.id, "externalUrl", value);
                }}
                name="externalUrl"
                color="primary"
              />
            }
            label="Custom Url"
          />
          {slide.externalUrl ? (
            <TextField
              margin={"dense"}
              fullWidth
              multiline
              value={slide.actionPath}
              label={"Action Link"}
              placeholder={"ex: https://joinfreddie.com"}
              onChange={(e) => {
                const value = e.target.value;
                handleChange(slide.id, "actionPath", value);
              }}
            />
          ) : (
            <BaseSelect
              margin={"dense"}
              fullWidth
              multiline
              hasNone
              variant={"standard"}
              value={slide.actionPath}
              options={linkOptions}
              label={"Action Link"}
              placeholder={"ex: /join-us"}
              controlId={"actionPath"}
              onChange={(e) => {
                const value = e.target.value;
                handleChange(slide.id, "actionPath", value);
              }}
            />
          )}
          <FormControlLabel
            control={
              <Switch
                checked={slide.showOnWeb}
                onChange={(e) => {
                  const value = e.target.checked;
                  handleChange(slide.id, "showOnWeb", value);
                }}
                name="showOnWeb"
                color="primary"
              />
            }
            label="Show in Web"
          />
          <FormControlLabel
            control={
              <Switch
                checked={slide.showOnMobile}
                onChange={(e) => {
                  const value = e.target.checked;
                  handleChange(slide.id, "showOnMobile", value);
                }}
                name="showOnMobile"
                color="primary"
              />
            }
            label="Show in Mobile"
          />
        </CardContent>
        <CardActions>
          <Button onClick={() => handleDelete(slide.id)} size="small">
            Delete
          </Button>
          <Button onClick={() => handleNewImage(slide.id)} size="small">
            Set Image
          </Button>
        </CardActions>
      </Card>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <CardHeader
            style={{ padding: 0 }}
            action={
              <Box>
                <IconButton
                  aria-label="settings"
                  onClick={() => setIsFlipped(false)}
                >
                  <CloseIcon />
                </IconButton>
                <IconButton aria-label="settings">
                  <DragIndicator />
                </IconButton>
              </Box>
            }
            title={`Slide ${index + 1}`}
          />
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography variant={"body1"}>Title</Typography>
            <Box display={"flex"} flexDirection={"column"}>
              <ColorPicker
                value={styles.title.color}
                onChange={(e) => {
                  const color = e?.css?.backgroundColor || e;
                  handleChangeStyles(slide.id, "title", { color });
                }}
              />
              <Input
                value={styles.title.fontSize}
                placeholder={"Fontsize"}
                type={"number"}
                endAdornment={<Typography>px</Typography>}
                onChange={(e) => {
                  const fontSize = parseInt(e.target.value);
                  handleChangeStyles(slide.id, "title", { fontSize });
                }}
              />
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} mt={1}>
            <Typography variant={"body1"}>Description</Typography>
            <Box display={"flex"} flexDirection={"column"}>
              <ColorPicker
                value={styles.description.color}
                onChange={(e) => {
                  const color = e?.css?.backgroundColor || e;
                  handleChangeStyles(slide.id, "description", { color });
                }}
              />
              <Input
                value={styles.description.fontSize}
                placeholder={"Fontsize"}
                endAdornment={<Typography>px</Typography>}
                type={"number"}
                onChange={(e) => {
                  const fontSize = parseInt(e.target.value);
                  handleChangeStyles(slide.id, "description", { fontSize });
                }}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mt={1}
          >
            <Typography variant={"body1"}>Banner</Typography>
            <ColorPicker
              value={styles.banner.backgroundColor}
              onChange={(e) => {
                const backgroundColor = e.css.backgroundColor;
                handleChangeStyles(slide.id, "banner", { backgroundColor });
              }}
            />
          </Box>
          <BaseSelect
            fullWidth
            margin={"dense"}
            variant={"standard"}
            value={styles.root.alignItems}
            options={flexOptions}
            label={"Align"}
            controlId={"align"}
            onChange={(e) => {
              const alignItems = e.target.value;
              handleChangeStyles(slide.id, "root", { alignItems });
            }}
          />
          <BaseSelect
            fullWidth
            margin={"dense"}
            variant={"standard"}
            value={styles.root.justifyContent}
            options={flexOptions}
            label={"Justify"}
            controlId={"justify"}
            onChange={(e) => {
              const justifyContent = e.target.value;
              handleChangeStyles(slide.id, "root", { justifyContent });
            }}
          />
          <TextField
            label={"Margin"}
            value={styles.banner.margin}
            placeholder={"Margin"}
            margin={"dense"}
            fullWidth
            type={"number"}
            onChange={(e) => {
              const margin = parseInt(e.target.value);
              handleChangeStyles(slide.id, "banner", { margin });
            }}
          />
          <TextField
            label={"Padding"}
            value={styles.banner.padding}
            placeholder={"Padding"}
            margin={"dense"}
            fullWidth
            type={"number"}
            onChange={(e) => {
              const padding = parseInt(e.target.value);
              handleChangeStyles(slide.id, "banner", { padding });
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={isBannerVisible}
                onChange={(e) => {
                  const visibility = e.target.checked ? "visible" : "hidden";
                  handleChangeStyles(slide.id, "banner", { visibility });
                }}
                name="active"
                color="primary"
              />
            }
            label="Visible"
          />
        </CardContent>
      </Card>
    </ReactCardFlip>
  );
}

const SliderList = React.memo(function TaskList({ slides, ...rest }) {
  return slides.map((slide, index) => (
    <DragableSlideCard slide={slide} index={index} key={slide.id} {...rest} />
  ));
});

function SliderSettings(props) {
  const {
    addSlide,
    deleteSlide,
    editSlide,
    masSlideEdit,
    editSlideStyles,
    context,
  } = props;
  const { slider } = context;
  const [openImage, setOpenImage] = useState(false);
  const [slideId, setSlideId] = useState(false);
  const slides = slider.temp
    .filter((x) => !x.shouldRemoved)
    .sort((a, b) => compareProp(a, b, "order"));

  const linkOptions = useAppLinks(context);

  const handleDelete = (id) => {
    deleteSlide({ id });
  };

  const handleChange = (id, prop, value) => {
    editSlide({ id, prop, value });
  };

  const handleChangeStyles = (id, prop, value) => {
    editSlideStyles({ id, prop, value });
  };

  const acceptImage = useCallback(
    (image) => {
      const file = new File([image], "slide.png", { type: "image/jpeg" });
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
      const id = slideId;
      const prop = "file";
      const value = file;
      editSlide({ id, prop, value });
    },
    [editSlide, slideId]
  );

  const handleNewImage = (id) => {
    setSlideId(id);
    setOpenImage(true);
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newSlides = reorder(
      slides,
      result.source.index,
      result.destination.index
    );

    newSlides.forEach((slide, index) => {
      slide.order = index;
    });
    masSlideEdit(newSlides);
  }

  return (
    <Box px={2}>
      <Grid container direction={"column"} spacing={2}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <SliderList
                  linkOptions={linkOptions}
                  slides={slides}
                  handleNewImage={handleNewImage}
                  handleChange={handleChange}
                  handleDelete={handleDelete}
                  handleChangeStyles={handleChangeStyles}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Grid item>
          <Button variant={"outlined"} color={"primary"} onClick={addSlide}>
            Add Slide
          </Button>
        </Grid>
        <DropDialog
          open={openImage}
          handleClose={() => setOpenImage(false)}
          setImage={acceptImage}
          autoAspect
          title={"Crop Image"}
        />
      </Grid>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      slider: state.PageBuilder.slider,
      Page: state.Page,
    },
  };
};
export default connect(mapStateToProps, {
  addSlide,
  deleteSlide,
  editSlide,
  masSlideEdit,
  editSlideStyles,
})(SliderSettings);

import {Collapse} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";

function AlertMessage(props) {
  const [text, setText] = useState("");
  const { message, severity } = props;

  useEffect(() => setText(message), [message]);

  return (
    <Collapse in={!!text}>
      <Alert
        severity={severity}
        onClose={() => setText("")}
        style={{ marginBottom: 32 }}
      >
        {message}
      </Alert>
    </Collapse>
  );
}

export default AlertMessage;

AlertMessage.propTypes = {
  severity: PropTypes.oneOf(["error", "info", "warning", "success"]),
  message: PropTypes.string,
};
